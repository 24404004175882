import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../base/base.component';
import { FormGroup, FormControl, Validators, AsyncValidatorFn } from '@angular/forms';
import { distinctUntilChanged, debounceTime, switchMap, map, first, takeUntil } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { WizardComponent } from 'angular-archwizard';
import { zoomInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [
    zoomInOnEnterAnimation({ duration: 100 })
  ]
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  @ViewChild(WizardComponent, { static: false }) wizard;
  forgotPasswordForm: FormGroup;
  userExistanceStatus: number;
  existingPhone = {
    exists: false,
    value: null,
  };
  fpErrors = [];
  formLoading = false;
  forgotPasswordOTP = {
    Config: {
      length: 6,
      allowNumbersOnly: true,
    },
    value: "",
    isValid: false,
    resendDisabled: false,
    resendOTPTimer: 30,
  };

  showPassword = {
    password: false,
    confirmPassword: false
  }
  EmailId: string;

  constructor(public activeModal: NgbActiveModal, private authService: AuthService) {
    super();
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "^([\\w-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$"
          ),
        ])
      ),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      VerificationChannel: new FormControl({ value: true, disabled: true }),
    }, {
      validators: this.passwordMatch.bind(this)
    });
    this.forgotPasswordF.email.setAsyncValidators(
      this.emailExistsValidator()
    );
  }

  ngOnInit(): void {
    this.forgotPasswordF.email.valueChanges.pipe(
      takeUntil(this.unsubscribe), 
      distinctUntilChanged(), 
      debounceTime(300)
    ).subscribe(value => {

      if (this.forgotPasswordF.email.errors && this.forgotPasswordF.VerificationChannel.enabled) {
        this.forgotPasswordF.VerificationChannel.disable();
      }

    })
  }

  passwordMatch(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  get forgotPasswordF() {
    return this.forgotPasswordForm.controls;
  }

  emailExistsValidator(): AsyncValidatorFn {
    return (control) =>
      control.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(600),
        switchMap((value) => {         
          return this.authService.checkIfEmailExists(value);
        }),
        map((emailExistence) => {     
          this.fpErrors = [];
          switch (emailExistence.UserExists) {
            case 0: {
              this.userExistanceStatus = 0;
              this.forgotPasswordF.VerificationChannel.disable();
              this.existingPhone = {
                exists: false,
                value: null,
              };
              this.fpErrors.push('You do not have a registered account.')
              return { emailExists: false };
            }
            case 1: {
              this.userExistanceStatus = 1;
              this.fpErrors = [];
              this.EmailId = emailExistence.EmailAddress;
              if (emailExistence.PhoneNumber !== null) {
                this.forgotPasswordF.VerificationChannel.enable();
                this.existingPhone = {
                  exists: true,
                  value: emailExistence.PhoneNumber,
                };
              } else {
                this.forgotPasswordF.VerificationChannel.disable();
                this.existingPhone = {
                  exists: false,
                  value: null,
                };
              }
              return null;
            }
            case 2: {
              this.userExistanceStatus = 2;
              this.fpErrors = [];
              this.EmailId = emailExistence.EmailAddress;
              if (emailExistence.PhoneNumber !== null) {
                this.forgotPasswordF.VerificationChannel.enable();
                this.existingPhone = {
                  exists: true,
                  value: emailExistence.PhoneNumber,
                };
              } else {
                this.forgotPasswordF.VerificationChannel.disable();
                this.existingPhone = {
                  exists: false,
                  value: null,
                };
              }
              return null;
            }
          }
        }),
        first()
      );
  }

  goToResetPasswordScreen(skipNextPage: boolean, verificationChannel) {
    if (this.forgotPasswordF.email.errors === null) {
      this.formLoading = true;
      if (verificationChannel !== null) {
        this.forgotPasswordF.VerificationChannel.setValue(verificationChannel);
      }
      const forgotPasswordBody = {
        UserName: this.forgotPasswordF.email.value,
        UrlforPasswordReset: window.location.href,
        OneTimePasswordYN: true,
        VerificationChannel: Number(this.forgotPasswordF.VerificationChannel.value)
      };

      this.authService.forgotPassword(forgotPasswordBody).subscribe(
        (response) => {
          if (response.State) {
            if (skipNextPage === false) {
              this.wizard.goToNextStep();
            }
            this.formLoading = false;
          } else {
            this.formLoading = false;
            this.fpErrors = response.Messages;
          }
        },
        (error) => {
          this.formLoading = false;
          this.fpErrors.push(error.message);
        }
      )
    } else {
      this.fpErrors.push('Email is invalid. Please enter a valid email.')
    }
  }

  onOtpChange(event) {
    this.forgotPasswordOTP.value = event;
  }

  forgotPasswordOTPSubmit() {
    this.formLoading = true;
    this.authService
      .forgotPasswordOTPValidate(
        this.forgotPasswordF.email.value,
        this.forgotPasswordOTP.value
      )
      .subscribe(
        (response) => {

          if (response.State) {
            this.formLoading = false;
            this.wizard.goToNextStep();
          } else {
            this.formLoading = false;
            this.fpErrors.push(response.Messages[0]);
          }
        },
        (error) => {
          this.formLoading = false;
          this.fpErrors.push(error.message);
        }
      );
  }

  resendOTP(verificationChannel) {
    if (!this.forgotPasswordOTP.resendDisabled) {
      this.formLoading = true;
      this.fpErrors = [];
      this.forgotPasswordOTP.resendDisabled = true;
      const OTPTimer = setInterval(() => {
        if (this.forgotPasswordOTP.resendOTPTimer > 1) {
          this.forgotPasswordOTP.resendOTPTimer--;
        } else {
          this.forgotPasswordOTP.resendDisabled = false;
          clearInterval(OTPTimer);
          this.forgotPasswordOTP.resendOTPTimer = 30;
        }
      }, 1000);
      this.goToResetPasswordScreen(true, verificationChannel);
    }
  }

  forgotPasswordSaveNew() {
    this.forgotPasswordF.password.updateValueAndValidity();
    this.forgotPasswordF.confirmPassword.updateValueAndValidity();
    const passwordValid = this.forgotPasswordF.password.valid;
    const confirmPasswordValid = this.forgotPasswordF.confirmPassword.valid;
    if (passwordValid && confirmPasswordValid) {
      this.formLoading = true;
      const newPasswordBody = {
        Email: this.forgotPasswordF.email.value,
        Password: this.forgotPasswordF.password.value,
        OTP: this.forgotPasswordOTP.value,
        ValidatePassword: false,
      };

      this.authService.saveNewPassword(newPasswordBody).subscribe(
        (response) => {
          this.formLoading = false;
          if (response.State) {
            this.wizard.goToNextStep();
          } else {
            this.fpErrors = response.Messages;
          }
        },
        (error) => {
          this.formLoading = false;
          this.fpErrors.push(error.message);
        }
      );
    }
  }

  backToLogin() {
    this.activeModal.close('open-login')
  }

  closeError(error) {
    this.fpErrors = this.fpErrors.filter(formError => formError !== error);
  }


}
