<div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">Sign In</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="login()" *ngIf="!showPhoneLogin">
      <div class="modal-body">
        <div class="form-group mb-4">
          <label>
            <span>Email Address</span>
            <span type="link" class="btn-link ml-1" (click)="showPhoneLogin = !showPhoneLogin">Login using mobile number instead</span>
          </label>
          <input class="form-control" placeholder="Enter your email address" formControlName="Email"
            [ngClass]="{ 'is-invalid': formSubmitted && loginF.Email.errors }">
          <div class="invalid-feedback" *ngIf="formSubmitted && loginF.Email.errors">Please enter the registered
            email address</div>
        </div>
        <div class="form-group">
          <label>Password</label>
          <input type="password" class="form-control" formControlName="Password" placeholder="Enter your password"
            aria-describedby="password-show" [ngClass]="{ 'is-invalid': formSubmitted && loginF.Password.errors }">
          <div class="invalid-feedback" *ngIf="formSubmitted && loginF.Password.errors">Please enter your password
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end sign-in-btn-container">
        <button type="submit" class="btn btn-primary mr-2">Sign In</button>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div class="d-flex flex-row text-left align-items-center flex-column">
          <a class="theme-link" (click)="openForgotPassword()">Forgot Password?</a>
        </div>
        <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="openSignup()"
          *ngIf="(coreService.subscriptionActive$ | async)">Join</button> -->
        <div>
          <a class="JoinButton theme-link" (click)="openSignUp()">New user? Join</a>
        </div>
      </div>
    </form>
  
    <form [formGroup]="phoneLoginForm" (ngSubmit)="phoneLogin(false)" *ngIf="showPhoneLogin">
      <div class="modal-body new-height" style="overflow: visible !important;">
        <div class="form-group mb-4">
          <label>
            <span>Mobile Number</span>
            <span type="link" class="btn-link ml-1" (click)="showPhoneLogin = !showPhoneLogin">Login using email instead</span>
          </label>
          <!-- <div class="d-flex flex-column flex-md-row justify-content-between"> -->
          <ngx-intl-tel-input [cssClass]="'form-control'" style="width: 100% !important;" [separateDialCode]="true"
            [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [phoneValidation]="true" name="phone"
            formControlName="Phone" [ngClass]="{ 'is-invalid': phoneLoginF.Phone.touched && phoneLoginF.Phone.errors }"
            phonePlaceHolder>
          </ngx-intl-tel-input>
  
          
  
          <!-- <button type="submit" class="btn btn-brand" (click)="sendOTP()" [disabled]="!sendOtp">Send OTP</button> -->
          <!-- </div> -->
          <div class="invalid-feedback" *ngIf="phoneLoginF.Phone.errors">
            <span *ngIf="phoneLoginF.Phone.errors.required">Mobile number is required</span>
            <span
              *ngIf="phoneLoginF.Phone.errors.validatePhoneNumber?.valid === false || phoneLoginF.Phone.errors.pattern">Please
              enter a valid mobile number</span>
            <span *ngIf="phoneLoginF.Phone.errors.numberInvalid">Please
              enter a valid mobile number</span>
          </div>
        </div>
        <div class="form-group mb-2" *ngIf="showPhoneLoginOTP">
          <span class="mb-2">Verification Code</span>
          <ng-otp-input #otpInputField (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true}">
          </ng-otp-input>
        </div>
      </div>
      <div class="modal-footer d-flex flex-row justify-content-between align-items-center">
        <div>
          <a class="theme-link" (click)="resendOTP()" *ngIf="!loginOTP.resendDisabled && !showOtp">Resend Code
          </a>
          <a class="theme-link disabled" *ngIf="loginOTP.resendDisabled && !showOtp">Resend Code
            <span>({{loginOTP.resendOTPTimer}})</span>
        </a>
        </div>
        <div class="d-flex justify-content-between w-100 align-items-center">
          <a class="JoinButton" (click)="openSignUp()">New user? Join</a>   
  
          <!-- <button type="button" class="btn btn-outline-secondary mr-2" (click)="openSignup()"
            *ngIf="(coreService.subscriptionActive$ | async) && !showPhoneLoginOTP">Join</button> -->
          <button type="button" class="btn btn-primary mr-2" (click)="sendOTP()" *ngIf="showOtp" [disabled]="!sendOtp">Send OTP</button>
          <button type="submit" class="btn btn-primary" *ngIf="!showOtp">Login</button>
        </div>
      </div>
    </form>
    <div class="modal-footer d-flex flex-column">
      <div class="or-divider w-100 d-flex justify-content-center" *ngIf="facebookEnabled || googleEnabled">OR</div>
      <!-- <p type="link" class="btn-link" *ngIf="showPhoneLogin" (click)="showPhoneLogin = !showPhoneLogin">Login using email
        address instead
      <p> -->
      <!-- <p type="link" class="btn-link" *ngIf="!showPhoneLogin" (click)="showPhoneLogin = !showPhoneLogin">Login using
        mobile
        number instead
      <p> -->
      <div class="d-flex flex-column flex-md-row social-btn-container" *ngIf="facebookEnabled || googleEnabled">
        <button type="button" class="social-btn facebook-btn" (click)="openFacebookLogin()" *ngIf="facebookEnabled">
          Login with Facebook
        </button>
        <button class="social-btn google-btn" id="btnLoginGoogle" *ngIf="googleEnabled">
          Login with Google
        </button>
      </div>
      <ngb-alert style="margin: 0 auto;" type="danger" *ngFor="let error of formErrors; let i = index"
        (close)="closeError(error)">
        {{error}}</ngb-alert>
    </div>
    <app-loader [message]="'Logging In'" [toggleTrigger]="formLoading"></app-loader>
  </div>