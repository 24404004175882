<div class="modal-container">
  <div class="modal-body">
    <div>
      <h4 class="company-name">{{data.CategoryName}} - {{data.SubCategoryName}} for {{data.Company.CompanyName}}</h4>     
    </div>
    <div class="image-container">
      <div class="image-loader" *ngIf="loading">
        <img class="loader-image" src="../../assets/icons/Bns-Loader.gif"/>
      </div>
      <img src="{{data.Attachments[showIndex].Path}}" (load)="onLoad($event)" />
    </div>

    <span *ngIf="data.Attachments.length > 1" class="left carousel-control" style="cursor: pointer;" (click)="showPreviousImage()">
      <span class="icon-container icon-left">
        <i class="fas fa-chevron-left"></i>
      </span>
    </span>
    <span *ngIf="data.Attachments.length > 1" class="right carousel-control" style="cursor: pointer;" (click)="showNextImage()">
      <span class="icon-container icon-right">
        <i class="fas fa-chevron-right"></i>
      </span>
    </span>
  </div>


</div>
