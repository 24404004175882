<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">Terms and Conditions</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="section">
      <h1 class="head">1. AGREEMENT/LICENSE</h1>
      <p>
        A. These terms and conditions ("Terms", "Agreement") are an agreement between Fraileys Industrial ("Fraileys Industrial", "us", "we"
        or "our") and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use
        of the Fraileys Industrial website and any of its products or services (collectively, "Website" or "Services"). By accessing
        or otherwise interacting with our servers, services, websites, mobile app ("App"), or any associated
        content/postings (together, "Fraileys Industrial"), you agree to these Terms of Use ("TOU") (last updated November 2019). You
        acknowledge and agree Fraileys Industrial is a private site owned and operated by Fraileys Industrial, LLC. If you are accessing or
        using Fraileys Industrial on behalf of a business, you represent and warrant to Fraileys Industrial that you have authority to accept
        the TOU on behalf of that business and that that business agrees to the TOU. If you do not agree to the TOU, you are
        not authorized to use Fraileys Industrial or download the App. We may modify the TOU at any time in our sole discretion. You
        are responsible for periodically checking for changes and are bound by them if you continue to use Fraileys Industrial<br>
      </p>
      <h3 class="sub-head">Acceptance of this policy </h3>
      <p>
        You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or
        its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are
        not authorized to use or access the Website and its Services.
        <br>
        <br> If you agree to the TOU and (1) are of sufficient age and capacity to use Fraileys Industrial and be bound by the TOU,
        or (2) use Fraileys Industrial on behalf of a business, thereby binding that business to the TOU, we grant you a limited,
        revocable, non-exclusive, non-assignable license to use Fraileys Industrial in compliance with the TOU; unlicensed use is
        unauthorized. You agree not to display, "frame," make derivative works, distribute, license, or sell, content from
        Fraileys Industrial, excluding postings you create. You grant us a perpetual, irrevocable, unlimited, worldwide, fully
        paid/sublicensable license to use, copy, display, distribute, and make derivative works from content you post.
      </p>
    </div>

    <div class="section">
      <h1 class="head">
        2. TERMS AND CONDITIONS
      </h1>
      <ol type="A" class="orderlist">
        <li>

          <b>User's Representations</b>

          You must be at least 18 years of age to use this Website. By using this Website and by agreeing to this Agreement
          you warrant and represent that you are at least 18 years of age. If you create an account on the Website, you are
          responsible for maintaining the security of your account and you are fully responsible for all activities that
          occur under the account and any other actions taken in connection with it. We may monitor and review new accounts
          before you may sign in and use our Services. Providing false contact information of any kind may result in the
          termination of your account. You must immediately notify us of any unauthorized uses of your account or any other
          breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind
          incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part
          thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content
          would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not
          re-register for our Services. We may block your email address and Internet protocol address to prevent further
          registration.
        </li>
        <li><b>User Content</b><br>We do not own any data, information or material ("Content") that you submit on the
          Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality,
          integrity, legality, reliability, appropriateness, and intellectual property o ownership or right to use of all
          submitted Content. We may, but have no obligation to, monitor and review Content on the Website submitted or
          created using our Services by you. Unless specifically permitted by you, your use of the Website does not grant us
          the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your
          user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy,
          distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for
          the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have
          the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our
          reasonable opinion, violates any of our policies or is in any way harmful or objectionable.
        </li>
        <li><b>Billing and Payments</b><br> You shall pay all fees or charges to your account in accordance with the fees,
          charges, and billing terms in effect at the time a or charge is due and payable. If auto-renewal is enabled for
          the Services you have subscribed for, you will be charged automatically in accordance with the term you selected.
          If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a
          copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the
          credit or debit card used for the purchase. We reserve the right to change products and product pricing at any
          time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or
          cancel quantities purchased per person, per household or per order. These restrictions may include orders placed
          by or under the same customer account, the same credit card, and/or orders that use the same billing and/or
          shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by
          contacting the e-mail and/or billing address/phone number provided at the time the order was made. In case of
          services requiring payment, we request credit card or other payment account information, which will be used solely
          for processing payments. Your purchase transaction data is stored only as long as is necessary to complete your
          purchase transaction. After that is complete, your purchase transaction information is deleted. Where necessary
          for processing future payments and subject to your prior consent, your financial information will be stored in
          encrypted from on secure servers of our reputed payment gateway service provider who is beholden to treating your
          Personal Information in accordance with this Privacy Policy. All direct payment gateways adhere to the latest
          security standards as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa,
          MasterCard, American Express and Discover. Sensitive and private data exchange happens over a SSL secured
          communication channel and is encrypted and protected with digital signatures, and our Website is also in
          compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users.
        </li>
        <li><b>Accuracy of Information</b><br> Occasionally there may be information on the Website that contains
          typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability,
          promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or
          update information or cancel orders if any information on the Website or on any related Service is inaccurate at
          any time without prior notice (including after you have submitted your order). We undertake no obligation to
          update, amend or clarify information on the Website including, without limitation, pricing inf01mation, except as
          required by law. No specified update or refresh date applied on the Website should be taken to indicate that all
          information on the Website or on any related Service has been modified or updated.
        </li>
        <li><b>Third-Party Service</b><br>If you decide to enable, access or use third-party services, be advised that your
          access and use of such other services are governed solely by the terms and conditions of such other services, and
          we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other
          services, including, without limitation, their content or the manner in which they handle data (including your
          data) or any interaction between you and the provider of such other services. You irrevocably waive any claim
          against Fraileys Industrial with respect to such other services. Fraileys Industrial is not liable for any damage or loss caused or
          alleged to be caused by or in connection with your enablement, access or use of any such other services, or your
          reliance on the privacy practices, data security processes or other policies of such other services. You may be
          required to register for or log into such other services on their respective websites. By enabling any other
          services, you are expressly permitting Fraileys Industrial to disclose your data as necessary to facilitate the use or
          enablement of such other service.
        </li>
        <li><b>Backups</b><br> We perform regular backups of the Website and Content, however, these backups are for our own
          administrative purposes only and are in no way guaranteed. You are responsible for maintaining your own backups of
          your data. We do not provide any sort of compensation for lost or incomplete data in the event that backups do not
          function properly. We will do our best to ensure complete and accurate backups, but assume no responsibility for
          this duty.
        </li>
        <li><b>Advertisements</b><br> During use of the Website, you may enter into correspondence with or participate in
          promotions of advertisers or sponsors showing their goods or services through the Website. Any such activity, and
          any terms, conditions, warranties or representations associated with such activity, is solely between you and the
          applicable third-party. We shall have no liability, obligation or responsibility for any such correspondence,
          purchase or promotion between you and any such third-party.
        </li>
        <li><b>Links to Other websites</b><br>Although this Website may link to other websites, we are not, directly or
          indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website,
          unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the
          offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility
          or liability for the actions, products, services, and content of any other third-parties. You should carefully
          review the legal statements and other conditions of use of any website which you access through a link from this
          Website. Your linking to any other websites is at your own risk
        </li>
        <li><b>Intellectual property rights</b><br>This Agreement does not transfer to you any intellectual property owned
          by Fraileys Industrial or third-parties, and all rights, titles, and interests in and to such property will remain (as
          between the parties) solely with Fraileys Industrial. All trademarks, service marks, graphics and logos used in connection
          with our Website or Services, are trademarks or registered trademarks of Fraileys Industrial or Fraileys Industrial licensors. Other
          trademarks, service marks, graphics and logos used in connection with our Website or Services may be the
          trademarks of other third-parties. Your use of our Website and Services grants you no right or license to
          reproduce or otherwise use any Fraileys Industrial or third-party trademarks.
        </li>
        <li><b>Limitation of Liability</b><br>To the fullest extent permitted by applicable law, in no event will Fraileys Industrial
          360, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for
          any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation,
          damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption,
          loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability,
          including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even
          if Fraileys Industrial has been advised as to the possibility of such damages or could have foreseen such damages. To the
          maximum extent permitted by applicable law, the aggregate liability of Fraileys Industrial and its affiliates, officers,
          employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one
          dollar or any amounts actually paid in cash by you to Fraileys Industrial for the prior one month period prior to the first
          event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does
          not fully compensate you for any losses or fails of its essential purpose.
        </li>
        <li><b>Indemnification</b><br>You agree to indemnify and hold Fraileys Industrial and its affiliates, directors, officers,
          employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable
          attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions,
          disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the
          Website or Services or any wilful misconduct on your part.
        </li>
        <li><b>Severability</b><br>All rights and restrictions contained in this Agreement may be exercised and shall be
          applicable and binding only to the extent that they do not violate any applicable laws and are intended to be
          limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If
          any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable
          by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions
          thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining
          provisions or portions thereof shall remain in full force and effect.
        </li>
        <li><b>Prohibited activities and uses</b><br>You may not use the Services to publish content or engage in activity
          that is illegal under applicable law, that is harmful to others, or that would subject us to liability, including,
          without limitation, in connection with any of the following, each of which is prohibited under this
          <ul class="listbullets">
            <li>Distributing malware or other malicious code.</li>
            <li>Disclosing sensitive personal information about others.</li>
            <li>Collecting, or attempting to collect, personal information about third parties without their knowledge or
              consent.</li>
            <li>Distributing pornography or adult related content.</li>
            <li>Promoting or facilitating prostitution or any escort services.</li>
            <li>Hosting, distributing or linking to child pornography or content that is harmful to minors.</li>
            <li>Promoting or facilitating gambling, violence, terrorist activities or selling weapons or ammunition.</li>
            <li>Engaging in the unlawful distribution of controlled substances, drug contraband or prescription medications.
            </li>
            <li>Managing payment aggregators or facilitators such as processing payments on behalf of other businesses or
              charities.</li>
            <li>Facilitating pyramid schemes or other models intended to seek payments from public actors.</li>
            <li>Threatening harm to persons or property or otherwise harassing behavior.</li>
            <li>Manual or automatic credit card or other available payment methods testing using bots or scripts.</li>
            <li>Purchasing any of the Services on someone else's behalf.</li>
            <li>Misrepresenting or fraudulently representing products or services.</li>
            <li>Infringing the intellectual property or other proprietary rights of others. Facilitating, aiding, or
              encouraging any of the above activities through our Services.</li>
            <li>Use or provide software (except our App and general purpose web browsers and email clients) or services that
              interact or interoperate with Fraileys Industrial, e.g. for downloading, uploading, creating/accessing/using an
              account, posting,
              flagging, emailing, searching, or mobile use, to copy/collect Fraileys Industrial content via robots, spiders,
              scripts, scrapers, crawlers, or any automated or manual equivalent (e.g., by hand). Misleading, unsolicited,
              and/or unlawful<br> postings/communications/accounts are prohibited, as is buying or selling accounts, to post
              content that is prohibited by any of Fraileys Industrial's policies or rules referenced above ("Prohibited Content"),
              to abuse Fraileys Industrial's flagging
              or reporting processes, to collect Fraileys Industrial user information or interfere with Fraileys Industrial, to use
              Fraileys Industrial or the API in any manner or for any purpose that infringes, misappropriates, or otherwise
              violates any intellectual
              property right or other right of any person, or that violates any applicable law.
            </li>
            <li>Unless licensed by us in a separate written or electronic agreement, you agree not to use or provide
              software (except our App and general purpose web browsers and email clients) or services that interact or
              interoperate with Fraileys Industrial, e.g. for downloading, uploading, creating/accessing/using an account, posting,
              flagging, emailing, searching, or mobile use. You agree not to copy/collect Fraileys Industrial content via robots,
              spiders, scripts, scrapers, crawlers, or any automated or manual equivalent (e.g., by hand). Misleading,
              unsolicited, and/or unlawful postings/communications/accounts are prohibited, as is buying or selling
              accounts. You agree not to post content that is prohibited by any of Fraileys Industrial's policies or rules referenced
              above ("Prohibited Content").</li>
            <li>You agree not to abuse Fraileys Industrial's flagging or reporting processes. You agree not to collect Fraileys Industrial
              user information or interfere with Fraileys Industrial. You agree we may moderate Fraileys Industrial access/use in our sole
              discretion, e.g., by blocking, filtering, re-categorizing, reranking, deleting, delaying, holding, omitting,
              verifying, or terminating your access/license/account. You agree (l) not to bypass said moderation, (2) we are
              not liable for moderating or not moderating, and (3) nothing we say or do waives our right to moderate, or
              not. Unless licensed by us in a separate written or electronic agreement, you agree not to (i) rent, lease,
              sell, publish, distribute, license, sublicense, assign, transfer, or otherwise make available Fraileys Industrial or
              our application programming interface API"), (ii) copy, adapt, create derivative works of, decompile, reverse
              engineer, translate, localize, port or modify the App, the API, any website code, or any software used to
              provide Fraileys Industrial, (iii) combine or integrate Fraileys Industrial or the API with any software, technology, services,
              or materials not authorized by us, (iv) circumvent any functionality that controls access to or otherwise
              protects Fraileys Industrial or the API, or (v) remove or alter any copyright, trademark or other proprietary rights
              notices. You agree not to use Fraileys Industrial or the API in any manner or for any purpose that infringes,
              misappropriates, or otherwise violates any intellectual property right or other right of any person, or that
              violates any applicable law.</li>

          </ul>
        </li>
        <li><b>Copyrighted Content</b><br>Copyrighted material must not be published via our Services without the explicit
          permission of the copyright owner or a person explicitly authorized to give such permission by the copyright
          owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we will immediately run
          till investigation and, upon confirmation, will promptly remove the infringing material from the Services. We may
          terminate the Service of Users with repeated copyright infringements. Further procedures may be carried out if
          necessary. We will assume no liability to any User of the Services •for the removal of any such material. If you
          believe your copyright is being infringed by a person or persons using our Services, please send a report of the
          copyright infringement to the contact details listed at the end of this Policy. Your notice must include the
          following:<br>
          <ul class="listbullets">
            <li>Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted words at a
              single site are covered by a single notification, a representative list of such works at that site.</li>
            <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity
              and that is to be removed or access to which is to be disabled, and information reasonably sufficient to
              permit us to locate
              the material.</li>
            <li>Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if
              available, an e-mail address.</li>
            <li>A statement that you have a good faith belief that use of the material in the manner complained of is not
              authorized by the copyright owner, the copyright owner's agent, or the law.</li>
            <li>A statement that the införmation in the notification is accurate, and under penalty of perjury that you are
              authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.<br>A physical or
              electronic signature
              of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
          </ul>
        </li>
        <li><b>Dispute Resolution</b><br>The formation, interpretation, and performance of this Agreement and any disputes
          arising out of it shall be governed by the substantive and procedural laws of Oklahoma, United States without
          regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of United States. The
          exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the state and federal
          courts located in Oklahoma, United States, submit to the personal jurisdiction of such courts. You hereby waive
          any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations
          Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
        </li>
        <li><b>Liquidated Damages</b>You further agree that if you violate the TOU, or you encourage, support, benefit from,
          or induce others to do so, you will be jointly and severally liable to us for liquidated damages as follows for:
          (A) collecting/harvesting Fraileys Industrial users' information, including personal or identifying information - $1 per
          violation; (B) publishing/misusing personal or identifying information of a third party in connection with your
          use of Fraileys Industrial without that party's express written consent - $1,000 per violation; (C) misrepresenting your
          identity or affiliation to anyone in connection with your use of Fraileys Industrial - $1,000 per violation; (D) posting or
          attempting to post Prohibited Content - $4 per violation; (E) posting or attempting to post Prohibited Content in
          any paid section of Fraileys Industrial - the price per post applicable to that section of Fraileys Industrial; (F) sending an
          unauthorized/unsolicited email to an email address obtained from Fraileys Industrial - $25 per violation; (G) using Fraileys Industrial
          360 user information to make/send an unauthorized/unsolicited text message, call, or communication to a Fraileys Industrial
          user - $500 per text/call/communication; (H) creating a misleading or unlawful Fraileys Industrial account or
          buying/selling a Fraileys Industrial account - $4 per violation; (I) abusing or attempting to abuse Fraileys Industrial's flagging
          or reporting processes - $1 per violation; (J) distributing any software to facilitate violations of the USE
          Section - $1,000 per violation; (K) aggregating, displaying, framing, copying, duplicating, reproducing, making
          derivative works from, distributing, licensing, selling, or exploiting Fraileys Industrial content for any purpose without
          our express written consent $3,000 for each day you engage in such violations; (L) requesting, viewing, or
          accessing more than 1 ,000 pages of Fraileys Industrial in any 24-hour period - $0.25 per page during the 24 hour period
          after the first 1,000 pages; (M) bypassing or attempting to bypass our moderation efforts - $4 per violation. You
          agree that these amounts are (1) a reasonable estimate of our damages (as actual damages are often difficult to
          calculate), (2) not a penalty, and (3) not otherwise limiting on our ability to recover under any legal theory or
          claim, including statutory damages and other equitable relief (e.g., for spam, we can elect between the above
          liquidated damages or statutory damages under the anti-spam statute). You further agree that repeated violations
          of the USE section will irreparably harm and entitle us to injunctive or equitable relief, in addition to monetary
          damages.
        </li>
        <li><b>Enforcement by Fraileys Industrial</b><br>Fraileys Industrial reserves the right to be the sole arbiter in determining the
          seriousness of each infringement and to immediately take corrective actions, including but not limited to:
          <ul class="listbullets">
            <li>Suspending or terminating your Service with or without notice upon any violation of this Policy. Any
              violations may also result in the immediate suspension or termination of your account.</li>
            <li>Disabling or removing any content which is prohibited by this Policy, including to prevent harm to others or
              to us or our Services, as determined by us in our sole discretion.</li>
            <li>Reporting violations to law enforcement as determined by us in our sole discretion.</li>
            <li>A failure to respond to an email from our abuse team within 2 days, or as otherwise specified in the
              communication to you, may result in the suspension or termination of your Services. Suspended and terminated
              User accounts due to violations will not be reactivated.
              Nothing contained in this Policy shall be construed to limit our actions or remedies in any way with respect
              to any of the prohibited activities. We reserve the right to take any and all additional actions we may deem
              appropriate with respect to such activities, including without limitation taking action to recover the costs
              and expenses of identifying offenders and removing them from our Services, and levying cancellation charges to
              cover our costs. In addition, we reserve at all times all rights and remedies available to us with respect to
              such activities at law or in equity.


            </li>
          </ul>
        </li>
        <li><b>Reporting Violations</b><br>If you have discovered and would like to report a violation of this Policy,
          please contact us immediately. We will investigate the situation and provide you with frill assistance.
        </li>
        <li><b>Assignment</b><br>You may not assign, resell, sub-license or otherwise transfer or delegate any of your
          rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at
          our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are
          free to assign any of its rights or obligations hereunder, in whole or in part, to any third-party as part of the
          sale of all or substantially all of its assets or stock or as part of a merger.
        </li>
        <li><b>Changes and Amendments</b><br>We reserve the right to modify this Agreement or its policies relating to the
          Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website.
          When we do, we will post a notification on the main page of our Website. Continued use of the Website after any
          such changes shall constitute your consent to such changes.
        </li>
        <li><b>Contacting Us</b><br>If you would like to contact us to understand more about this Agreement or wish to
          contact us concerning any matter relating to it, you may do so via the contact form or send an email to
          shamilton9131@gmail.com.
        </li>
        <li><b>System Abuse</b><br>Any User in violation of our Services security is subject to criminal and civil
          liability, as well as immediate account termination. Examples include, but are not limited to the following:<br>
          <ul class="listbullets">
            <li>Use or distribution of tools designed for compromising security of the Services.</li>
            <li>Intentionally or negligently transmitting files containing a computer virus or corrupted data.</li>
            <li>Accessing another network without permission, including to probe or scan for vulnerabilities or breach
              security or authentication measures</li>
            <li>Unauthorized scanning or monitoring of data on any network or system without proper authorization of the
              owner of the system or network.</li>
          </ul>
        </li>
        <li><b>Service Resources</b><br>You may not consume excessive amounts of the Services or use the Services in any way
          which results in performance issues or which interrupts the services for other Users. Prohibited activities that
          contribute to excessive use, include without limitation:<br>
          <ul class="listbullets">
            <li>Deliberate attempts to overload the Services and broadcast attacks (i.e. denial of service attacks).</li>
            <li>Engaging in any other activities that degrade the usability and performance of our Services.</li>
          </ul>
        </li>
        <li><b>No Spam Policy</b><br>You may not use our Services to send spam or bulk unsolicited messages. We maintain a
          zero tolerance policy for use of our Services in any manner associated with the transmission, distribution or
          delivery of any bulk e-mail, including unsolicited bulk or unsolicited commercial e-mail, or the sending,
          assisting, or commissioning the transmission of commercial email that does not comply with the U.S. CAN-SPAM Act
          of 2003 ("SPAM").
          Your products or services advertised via SPAM (i.e. Spamvertised) may not be used in conjunction with our
          Services. This provision includes, but is not limited to, SPAM sent via fax, phone, postal mail, email, instant
          messaging, or newsgroups.
          Sending emails through our Services to purchase email lists ("safe lists") will be treated as SPAM.

        </li>
        <li><b>Defamation and objectionable content</b><br>We value the freedom of expression and encourages Users to be
          respectful with the content they post. We are not a publisher of User content and are not in a position to
          investigate the veracity of individual defamation claims or to determine whether certain material, which we may
          find objectionable. should be censored. However, we reserve the right to moderate, disable or remove any content
          to prevent harm to others or to us or our Services, as determined in our sole discretion.
        </li>
        <li><b>Security</b><br>You take full responsibility for maintaining reasonable security precautions for your
          account. You are responsible for protecting and updating any login account provided to you for our Services. You
          must protect the confidentiality of your login details, and you should change your password periodically.
        </li>
        <li><b>Changes and Amendments</b><br>We reserve the right to modify this Policy or its terms relating to the Website
          or Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do,
          we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes
          shall constitute your consent to such changes.
        </li>
        <li><b>Acceptance of this Policy</b><br>You acknowledge that you have read this Policy and agree to all its terms
          and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to
          abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.
        </li>
      </ol>
    </div>
    <div>
      <h1 class="head">
        3. DISCLAIMER
      </h1>
      <ol type="A" class="orderlist">
        <li>This disclaimer ("Disclaimer", "Agreement") is an agreement between Fraileys Industrial, LLC ("Fraileys Industrial, LLC", "us",
          "we" or "our") and you ("User", "you" or "your"). This Disclaimer sets forth the general guidelines, terms and
          conditions of your use of the pheobuslisl.com website and any of its products or services (collectively, "Website"
          or "Services").</li>
        <li><b>Disclaimer of warranty</b><br>You agree that your use of our Website or Services is solely at your own risk.
          You agree that such Service is provided on an "as is" and "as available" basis. We expressly disclaim all
          warranties of any kind, whether express or implied, including but not limited to the implied warranties of
          merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will
          meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make
          any warranty as to the results that may be obtained :from the use of the Service or as to the accuracy or
          reliability of any information obtained through the Service or that defects in the Service will be corrected. You
          understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is
          done at your own discretion and risk and that you will be solely responsible for any damage to your computer
          system or loss of data that results from the download of such material and/or data. We make no warranty regarding
          any goods or services purchased or obtained through the Service or any transactions entered into through the
          Service. No advice or information, whether oral or written, obtained by you from us or through the Service shall
          create any warranty not expressly made herein.</li>
        <li><b>Representation</b><br>Any views or opinions represented in this Website belong solely to the Content creators
          and do not represent those of people, institutions or organizations that the Fraileys Industrial, LLC or creators may or
          may not be associated with in professional or personal capacity, unless explicitly stated. Any views or opinions
          are not intended to malign any religion, ethnic group, club, organization, company, or individual.</li>
        <li><b>Content and Postings</b><br>You may not modify, print or copy any part of the Website. Inclusion of any part
          of this Website in another work, whether in printed or electronic or another form or inclusion of any part of the
          Website in another website by embedding, framing or otherwise without the express permission of Fraileys Industrial, LLC is
          prohibited. You may submit comments for the Content available on the Website. By uploading or otherwise making
          available any information to Fraileys Industrial, LLC, you grant Fraileys Industrial, LLC the unlimited, perpetual right to
          distribute, display, publish, reproduce, reuse and copy the information contained therein. You may not impersonate
          any other person through the Website. You may not post content that is defamatory, fraudulent, obscene,
          threatening, invasive of another person's privacy rights or that is otherwise unlawful. You may not post content
          that infringes on the intellectual property rights of any other person or entity. You may not post any content
          that includes any computer virus or other code designed to disrupt, damage, or limit the functioning of any
          computer software or hardware.</li>
        <li><b>Compensation</b><br>This Website accepts forms of advertising, sponsorship, paid insertions or other forms of
          compensation. Fraileys Industrial, LLC is compensated to provide opinion on products, services. websites and various other
          topics. The compensation received may influence the advertising content, topics or posts made on the Website.
          Sponsored content, advertising space or post will always be identified as such.</li>
        <li><b>Indemnification and Warranties</b><br>While we have made every' attempt to ensure that the information
          contained on the Website is correct, Fraileys Industrial, LLC is not responsible for any errors or omissions, or for the
          results obtained from the use of this information. All information on the Website is provided "as is", with no
          guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and
          without warranty of any kind, express or implied. In no event will Fraileys Industrial, LLC. or its partners, employees or
          agents, be liable to you or anyone else for any decision Made or action taken in reliance on the information on
          the Website or for any consequential, special or similar damages, even if advised of the possibility of such
          damages. Furthermore, information contained on the Website and any pages linked to and from it are subject to
          change at any time and without warning.
          We reserve the right to modify this Disclaimer relating to the Website or Services at any time, effective upon
          posting of an updated version of this Disclaimer on the Website. When we do, we will revise the updated date at
          the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such
          changes.</li>
        <li><b>Acceptance of this Disclaimer</b><br>You acknowledge that you have read this Disclaimer and agree to all its
          terms and conditions. By accessing the Website, you agree to be bound by this Disclaimer. If you do not agree to
          abide by the terms of this Disclaimer, you are not authorized to use or access the Website.</li>
        <li><b>Contacting Us</b><br>If you would like to contact us to understand more about this Disclaimer or wish to
          contact us concerning any matter relating to it, you may do so via the contact from or send an email to
          shamilton9131@gmail.com</li>
        <li><b>Privacy Policy</b><br>This privacy policy ("Policy") describes how Fraileys Industrial, LLC ("Fraileys Industrial, LLC", "we",
          'us" or "our") collects, protects and uses the personally identifiable information ("Personal Information") you
          ("User", "you" or "your") may provide on the heobuslist.com website and any of its products or services
          (collectively, " Website" or "Services"). It also describes the choices available to you regarding our use of your
          Personal Information and how you can access and update this information. This Policy does not apply to the
          practices of companies that we do not own or control, or to individuals that we do not employ or manage.</li>
        <li><b>Automatic Collection of Information</b><br>When you visit the Website our servers automatically record
          information that your browser sends. "This data may include information such as your device's IP address, browser
          type and version, operating system type and version, language preferences or the webpage you were visiting before
          you came to our Website, pages of our Website that you visit, the time spent on those pages, information you
          search for on our Website, access times and dates, and other statistics.</li>
        <li><b>Collection of Personal Information</b><br>You can visit the Website without telling us who you are or
          revealing any information by which someone could identify you as a specific, identifiable individual. If, however,
          you wish to use some of the Websites features, you will be asked to provide certain Personal Information (for
          example, your name and e-mail address). We receive and store any information you knowingly provide to us when you
          create an account, publish content, make a purchase, or fill any online forms on the Website. When required, this
          information may include your email address, name, phone number, address, credit card information, bank
          information, or other Personal Information. You can choose not to provide us with your Personal Information, but
          then you may not be able to take advantage of some of the Website's features. Users who are uncertain about what
          information is mandatory are welcome to contact us.</li>
        <li><b>Managing Personal Information</b><br>You are able to access, add to, update and delete certain Personal
          Information about you. The information you can view, update, and delete may change as the Website or Services
          change. When you update information, however, we may maintain a copy of the unrevised information in our records.
          Some information may remain in our private records after your deletion of such information from your account. We
          will retain and use your Personal Information for the period necessary to comply with our legal obligations,
          resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We
          may use any aggregated data derived from or incorporating your Personal Information after you update or delete it,
          but not in a manner that would identify you personally. Once the retention period expires, Personal Information
          shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right
          to data portability cannot be enforced after the expiration of the retention period.</li>
        <li><b>Use and Processing of Collected Information</b><br>Any of the information we collect from you may be used to
          personalize your experience; improve our Website; improve customer service and respond to queries and emails of
          our customers; process transactions; send notification emails such as password reminders, updates, etc; run and
          operate our Website and Services. Information collected automatically is used only to identify potential cases of
          abuse and establish statistical information regarding Website usage. This statistical information is not otherwise
          aggregated in such a way that would identity any particular user of the system.
          We may process Personal Information related to you if one of the following applies: (i) You have given your
          consent for one or more specific purposes. Note that under some legislations we may be allowed to process
          information until you object to such processing (by opting out), without having to rely on consent or any other of
          the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is
          subject to European data protection law; (ii) Provision of information is necessary for the performance of an
          agreement with you and/or for any pre-contractual obligations thereof: (iii) Processing is necessary for
          compliance with a legal obligation to which you are subject; (iv) Processing is related to a task that is carried
          out in the public interest or in the exercise of official authority vested in us; (v) Processing is necessary for
          the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to
          clarify the specific legal basis that applies to the processing, and in particular whether the provision of
          Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a
          contract.
        </li>
        <li><b>Information Transfer and Storage</b><br>Depending on your location, data transfers may involve transferring
          and storing your information in a county other than your own. You are entitled to learn about the legal basis of
          information transfers to a county outside the European Union or to any international organization governed by
          public international law or set up by two or more countries, such as the UN, and about the security measures taken
          by us to safeguard your information. If any such transfer takes place, you can find out more by checking the
          relevant sections of this document or inquire with us using the information provided in the contact section.</li>
        <li><b>The Rights of Users</b><br>You may exercise certain rights regarding your införmation processed by us. In
          particular, you have the right to do the föllowing:
          <ol type="i">
            <li>you have the right to withdraw consent where you have previously given your consent to the processing of
              your information</li>
            <li>you have the right to object to the processing of your information if the processing is carried out on a
              legal basis other than consent</li>
            <li>you have the right to learn if information is being processed by us, obtain disclosure regarding certain
              aspects of the processing and obtain a copy of the information undergoing processing:</li>
            <li>you have the right to verify the accuracy of your information and ask for it to be updated or corrected
            </li>
            <li>you have the right, unclear certain circumstances, to restrict the processing of your information, in which
              case, we will not process your information for any purpose other than storing it.</li>
            <li>you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us;
            </li>
            <li>you have the right to receive your information in a structured, commonly used and machine readable format
              and, if technically feasible, to have it transmitted to another controller without any hindrance. This
              provision is applicable
              provided that your information is processed by automated means and that the processing is based on your
              consent, on a contract which you are part of or on pre-contractual obligations thereof.</li>
          </ol>
        </li>
        <li><b>The Right to Object to Processing</b><br>Where Personal Information is processed for the public interest, in
          the exercise of an official authority vested in us or for the purposes of the legitimate interests pursued by us,
          you may object to such processing by providing a ground related to your particular situation to justify the
          objection. You must know that, however, should your Personal Information be processed for direct marketing
          purposes, you can object to that processing at any time without providing any justification. To learn, whether we
          are processing Personal Information for direct marketing purposes, you may refer to the relevant sections of this
          document.</li>
        <li><b>How to Exercise These Rights</b><br>Any requests to exercise User rights can be directed to the Owner through
          the contact details provided in this document. These requests can be exercised free of charge and will be
          addressed by the Owner
          as early as possible.</li>
        <li><b>California Privacy Rights</b><br>In addition to the rights as explained in this Privacy Policy, California
          residents who provide Personal Information (as defined in the statute) to obtain products or services for
          personal, family, or household use are entitled to request and obtain from us, once a calendar year, information
          about the Personal Information we shared, if any, with other businesses for marketing uses. If applicable, this
          information would include the categories of Personal Information and the names and addresses of those businesses
          with which we shared such per immediately prior calendar year (e.g., requests made in the current year will
          receive information about the prior year). To obtain this information please contact us.</li>
        <li><b>Product and Service Providers</b><br>We may contract with other companies to provide certain products and
          services. These service providers are not authorized to use or disclose the information except as necessary to
          perform services on our behalf or comply with legal requirements. We may share Personal Information for these
          purposes only with third-parties whose privacy policies are consistent with ours or who agree to abide by our
          policies with respect to Personal Information Our service providers are given the information they need to perform
          their designated functions, and we do not authorize them to use or disclose Personal Information for their own
          marketing or other purposes.</li>
        <li><b>Privacy of Children</b><br>We do not knowingly collect any Personal Information from children under the age
          of 13. If you are under the age of 13, please do not submit any Personal Information through our Website or
          Service. We encourage parents and legal guardians to monitor their children's Internet usage and. to help enforce
          this Policy by instructing their children never to provide Personal Information through our Website or Service
          without their permission. If you have reason to believe that a child under the age of 13 has provided Personal
          Information to us through our Website or Service, please contact us. You must also be at least 16 years of age to
          consent to the processing of your Personal Information in your country (in some countries we may allow your parent
          or guardian to do so on your behalf).</li>
      </ol>
    </div>
    <div class="section">
      <h1 class="head">
        4.AVOIDING SCAMS
      </h1>
      <ol type="A">
        <li><b>Avoiding Scams</b><br>Deal locally, face-to-face follow this one rule and avoid 99% of scam attempts.
          <ul class="listbullets">
            <li>Do not provide payment to anyone you have not met in person.</li>
            <li>Beware offers involving shipping - deal with locals you can meet in person.</li>
            <li>Never wire funds (e.g. Western Union) - anyone who asks you to is a scammer</li>
            <li>Don't accept cashier/certified checks or money orders - banks cash fakes, then hold you responsible.</li>
            <li>Transactions are between users only, no third party provides a ' guarantee".</li>
            <li>Never give out financial info (bank account, social security, paypal account, etc).</li>
            <li>Do not rent or purchase sight-unseen—that amazing "deal" may not exist. Refuse background/credit checks
              until you have met landlord/employer m person.</li>
            <li>"Fraileys Industrial voicemails" - Any message asking you to access or check "Fraileys Industrial voicemails" or "Fraileys Industrial
              voice messages" is fraudulent<br> - no such service exists.</li>
          </ul>
        </li>
        <li><b>Who should I notify about fraud or scam attempts? <br> United States</b><br>
          <ul class="listbullets">
            <li>Internet Fraud Complaint Center</li>
            <li>FTC complaint form and hotline: 877-FTC-HELP (877-382-4357)</li>
            <li>Consumer Sentinel/Military (for armed service members and families)</li>
            <li>SIIA Software and Content Piracy reporting</li>
            <li>Ohio Attorney General Consumer Complaints </li>
            <li>New York Attorney General, Avoid Online Investment Fraud</li>
          </ul>
          <br> <b>Canada</b>
          <br>
          <ul class="listbullets">
            <li>Canadian Anti-Fraud Centre or 888-495-8501 (toll-free) If you are defrauded by someone you met in person,
              contact your local police department. If you suspect that a Fraileys Industrial post may be connected to a scam,
              please send us the
              details.
            </li>
          </ul>
        </li>
        <li><b>Recognizing scams</b><br>Most scams attempts involve one or more of the following:<br>
          <ul class="listbullets">
            <li>Email or text from someone that is not local to your area</li>
            <li>Vague initial inquiry, e.g. asking about "the item." Poor grammar/spelling.</li>
            <li>Western Union, Money Gram, cashier check, money order, Paypal, Zella, shipping, escrow service, or a
              "guarantee."</li>
            <li>Inability or refusal to meet face-to-face to complete the transaction.</li>
          </ul><br>
          <b>Examples of Scams</b>
          <ol type="1">
            <li>Someone claims your transaction is guaranteed, that a buyer/seller is officially certified, OR that a third
              party of any kind will handle or provide protection för a payment:</li>
            <ul class="listbullets">
              <li>These claims are fraudulent, as transactions are between users only.</li>
              <li>The scammer will often send an official looking (but fake) email that appears to come from Fraileys Industrial or
                another third party, offering a guarantee, certifying a seller, or pretending to handle payments.</li>
            </ul>
            <li>Distant person offers a genuine-looking (but fake) cashier's check: You receive an email or text (examples
              below) offering to buy your item, pay for your services in advance, or rent your apartment, sight unseen and
              without meeting
              you in person.
              <ul class="listbullets">
                <li>A cashier's check is offered for your sale item as a deposit for an apartment or for your services.</li>
                <li>Value of cashier's check often far exceeds your item scammer offers to "trust" you, and asks you to wire
                  the balance via money transfer service.</li>
                <li>Banks will cash fake checks AND THEN HOLD YOU RESPONSIBLE WHEN THE CHECK FAILS TO CLEAR, sometimes
                  including criminal prosecution.</li>
                <li>Scams often pretend to involve a 3rd party (shipping agent, business associate, etc.)</li>
              </ul>
            </li>
            <li>Someone requests wire service payment via Western Union or MoneyGram:
              <ul class="listbullets">
                <li>Deal often seems too good to be true, price is too low, or rent is below market, etc.</li>
                <li>Scam "bait" items include apartments, laptops, TVs, cell phones, tickets, other high value items.</li>
                <li>Scammer may (falsely) claim a confnmation code from you is needed before he can withdraw your money.
                </li>
                <li>Common countries cm-rently include: Nigeria, Romania, UK, Netherlands—but could be anywhere.</li>
                <li>Rental may be local, but owner is "travelling" or "relocating" and needs you to wire money abroad.</li>
                <li>Scammer may pretend to be unable to speak by phone (scammers prefer to operate by text/email). </li>
              </ul>
            </li>
            <li>Distant person offers to send you a cashier's check or money order and then have you wire money:
              <ul class="listbullets">
                <li>This is ALWAYS a scam in our experience—the cashier's check is FAKE.</li>
                <li>Sometimes accompanies an offer of merchandise, sometimes not</li>
                <li>Scammer often asks for your name, address, etc. for printing on the fake check</li>
                <li>Deal often seems too good to be true.</li>
              </ul>
            </li>
            <li>Distant seller suggests use of an online escrow service:
              <ul class="listbullets">
                <li>Most online escrow sites are FRAUDULENT and operated by scammers. For Inore info, do a google search on
                  "fake escrow" or "escrow fraud."</li>
              </ul>
            </li>
            <li>Distant seller asks for a partial payment upfront, after which they will ship goods:
              <ul class="listbullets">
                <li>He says he trusts you with the partial payment.</li>
                <li>He may say he has already shipped the goods.</li>
                <li>Deal often sounds too good to be true.</li>
              </ul>
            </li>
            <li>Foreign company offers you a job receiving payments from customers, then wiring funds:
              <ul>
                <li>Foreign company may claim it is unable to receive payments from its customers directly.</li>
                <li>You are typically offered a percentage of payments received.</li>
                <li>This kind of "position" may be posted as a job, or offered to you via email. Personal Safety</li>
              </ul>
            </li>
          </ol>
          <ol type="a">
            <li>The overwhelming majority of Fraileys Industrial users are trustworthy and well-meaning.</li>
            <li>With billions of human interactions, the incidence of violent crime related to Fraileys Industrial is extremely
              low.</li>
            <li>Millions of violent crimes occur in the U.S. each year: 10,000+ homicides, 600,000+ robberies, 5 million
              assaults.</li>
            <li>Vanishingly few of these are Fraileys Industrial-related.</li>
            <li>Nevertheless, please take the same common sense precautions online as you would offline. When meeting
              someone for the first time, please remember to:
              <ul class="listbullets">
                <li>Insist on a public meeting place like a cafe, bank, or shopping center.</li>
                <li>Do not meet in a secluded place, or invite strangers into your home</li>
                <li>Be especially careful buying/selling high value items.</li>
                <li>Tell a friend or family member where you're going.</li>
                <li>Take your cell phone along if you have one.</li>
                <li>Consider having a friend accompany you.</li>
                <li>Trust your instincts.<br> Taking these simple precautions helps make Fraileys Industrial safer for
                  everyone.<br> For more information about personal safety online, check out these resources:<br></li>
                <li>http://www.staysafeonline.org/<br> http://www.onguardonline.gov/

                  <br> http://getsafeonline.org <br>http://wiredsafety.org<br></li>
                <li>Please review our avoiding scams page as well.</li>
              </ul>
            </li>
          </ol>

        </li>

      </ol>
    </div>
    <div class="section">
      <h1 class="head">5.COOKIE POLICY</h1>
      <ol type="A">
        <li>Cookie Policy
          <ul class="listbullets">
            <li>This cookie policy ("Policy") describes what cookies are and how Fraileys Industrial, LLC ("Fraileys Industrial, LLC",
              "we", us" or "our") uses them on the heobuslist.com website and any of its products or services (collectively,
              "Website" or "Services").</li>
            <li>You should read this Policy so you can understand what type of cookies we use, the information we collect
              using cookies and how that information is used. It also describes the choices available to you regarding
              accepting or declining
              the use of cookies. For further information on how we use, store and keep your personal data secure, see our
              Privacy Policv.</li>
          </ul>
        </li>
        <li>What are cookies?
          <ol type="1">
            <li>Cookies are small pieces of data stored in text files that are saved on your computer or other devices when
              websites are loaded in a browser. They are widely used to remember you and your preferences, either for a
              single visit (through
              a "session cookie") or for multiple repeat visits (using a "persistent cookie")</li>
            <li>Session cookies are temporary cookies that are used during the course of your visit to the Website, and they
              expire when you close the web browser.</li>
            <li>Persistent cookies are used to remember your preferences within our Website and remain on your desktop or
              mobile device even after you close your browser or restart your computer. They ensure a consistent and
              efficient experience for you while visiting our Website or using any of our Services.</li>
            <li>Cookies may be set by the Website ("first-party cookies"), or by third parties, such as those who serve
              content or provide advertising or analytics services on the website ("third party cookies"). These third
              parties can recognize
              you when you visit our website and also when you visit certain other websites.</li>
          </ol>
        </li>
        <li>
          What type of cookies do we use?
          <ol type="1">
            <li>Necessaty cookies<br> Necessary cookies allow us to offer you the best possible experience when accessing
              and navigating through our Website and using its features. For example, these cookies let us recognize that
              you have created an account and have logged into that account to access the content.
            </li>
            <li>Functionality cookies<br>a. Functionality cookies let us operate the Website and our Services in accordance
              with the choices you make. For example, we will recognize your username and remember how you customized the
              Website and Services
              during future visits</li>
            <li>Analytical cookies<br>a. These cookies enable us and third-party services to collect aggregated data för
              statistical purposes on how our visitors use the Website. These cookies do not contain personal information
              such as names and
              email addresses and are used to help us improve your user experience of the Website.</li>
            <li>Advertising cookies<br>a. Advertising cookies allow us and third parties serve relevant ads to you more
              effectively and help us collect aggregated audit data, research, and performance reporting tor advertisers.
              They also enable us
              to understand and improve the delivery of ads to you and know when certain ads have been shown to you.<br>b.
              Your web browser may request advertisements directly from ad network servers, these networks can view, edit,
              or set their
              own cookies, just as if you had requested a web page from their website.<br>c. Although we do not use cookies
              to create a profile of your browsing behavior on third-party sites, we do use aggregate data from third
              parties to show
              you relevant, interest-based advertising. We do not provide any personal information that we collect to
              adveffisers.</li>
          </ol>
        </li>
        <li>What are your cookie options?<br> If you don't like the idea of cookies or certain types of cookies, you can
          change your browser's settings to delete cookies that have already been set and to not accept new cookies. To
          learn more about how to do this or to learn more about cookies, visit internet cookie settings Please note,
          however, that if you delete cookies or do not accept them, you might not be able to use all of the features our
          Website and Services offer.
        </li>
        <li><b>Cookies</b><br>The Website uses "cookies" to help personalize your online experience. A cookie is a text file
          that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses
          to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that
          issued the cookie to you. We may use cookies to collect, store, and track information for statistical purposes to
          operate our Website and Services. You have the ability to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If
          you choose to decline cookies, you may not be able to fully experience the features of the Website and Services.
          To learn more about cookies and how to manage them, visit internet and search for cookies.
          In addition to using cookies and related technologies as described above, we also may permit certain third-party
          companies to help us tailor advertising that we think may be of interest to users and to collect and use other
          data about user activities on the Website. These companies may deliver ads that might also place cookies and
          otherwise track user behavior.

        </li>
        <li><b>Do Not Track Signals</b><br>Some browsers incorporate a Do Not Track feature that signals to websites you
          visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting
          information in connection with a website. For these purposes, tracking refers to collecting personally
          identifiable information from consumers who use or visit a website or online service as they move across different
          websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, this Website
          is not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as
          described in more detail throughout this Policy, we limit our use and collection of your personal information.
        </li>
        <li><b>Advertisement</b><br>We may display online advertisements and we may share aggregated and nonidentifying
          information about our customers that we collect through the registration process or through online surveys and
          promotions with certain
          advertisers. We do not share personally identifiable information about individual customers with advertisers. In
          some instances, we may use this aggregated and non-identifying information to deliver tailored advertisements to
          the intended
          audience.
        </li>
        <li><b>Links to Other Websites</b><br>Our Website contains links to other websites that are not owned or controlled
          by us. Please be aware that we are not responsible for the privacy practices of such other websites or
          thircl-parties. We encourage
          you to be aware when you leave our Website and to read the privacy statements of each and every website that may
          collect Personal Information.</li>
        <li><b>Information Security</b><br>We secure information you provide on computer servers in a controlled, secure
          environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative,
          technical, and physical
          safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal
          Information in its control and custody. However, no data transmission over the Internet or wireless network can be
          guaranteed.
          Therefore, while we strive to protect your Personal Information, you acknowledge that:
          <ol type="i">
            <li>there are security and privacy limitations of the Internet which are beyond our control;</li>
            <li>the security, integrity, and privacy of any and all införmation and data exchanged between you and our
              Website cannot be guaranteed; and</li>'
            <li>any such information and data may be viewed or tampered with in transit by a third-party, despite best
              efforts.</li>
          </ol>
        </li>
        <li><b>Data Breach</b><br>In the event we become aware that the security of the Website has been compromised or
          users Personal Information has been disclosed to unrelated third parties as a result of external activity,
          including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate
          measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation
          with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify
          affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach
          or if notice is otherwise required by law. When we do, we will post a notice on the Website, send you an email.
        </li>
        <li><b>Legal Disclosuret</b><br>We will disclose any information we collect, use or receive if required or permitted
          by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that
          disclosure is necessary
          to protect our rights, protect your safuy or the safety of others, investigate fraud, or respond to a government
          request. In the event we go through a business transition, such as a merger or acquisition by another company, or
          sale of
          all or a portion of its assets, your user account, and Personal Information will likely be among the assets
          transferred.</li>
        <li><b>Changes and Amendments</b><br>We may update this Privacy Policy from time to time in our discretion and will
          notify you of any material changes to the way in which we treat Personal Information. When changes are made, we
          will revise the updated date at the bottom of this page. We may also provide notice to you in other ways in our
          discretion, such as through contact information you have provided. Any updated version of this Privacy Policy will
          be effective immediately upon the posting of the revised Privacy Policy unless otherwise specified. Your continued
          use of the Website or Services after the effective date of the revised Privacy Policy (or such other act specified
          at that time) will constitute your consent to those changes. However, we will not, without your consent, use your
          Personal Data in a manner materially different than what was stated at the time your Personal Data was collected.
        </li>
        <li><b>Contacting Us</b><br>If you would like to contact us to understand more about this Policy or wish to contact
          us concerning any matter relating to individual rights and your Personal Information, you may do so via the
          contact form or send
          an email to shamilton9131 agmail.com</li>
        <li><b>Changes and Amendments</b><br>We reserve the right to modify this Policy relating to the Website or Services
          at any time, effective upon posting of an updated version of this Policy on the Website. When we do we will revise
          the updated
          date at the bottom of this page. Continued use ofthe Website after any such changes shall constitute your consent
          to such changes.</li>
        <li><b>Acceptance of this Policy</b><br>You acknovvledge that you have read this Policy and agree to all its terms
          and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to
          abide by the terrns
          of this Policy, you are not authorized to use or access the Website and its Services</li>
        <li><b>Contacting Us</b><br>If you would like to contact us to understand more about this Policy or wish to contact
          us concerning any matter relating to our use of cookies, you may do so via the contact form, send an email to
          customerservice@Fraileys Industrial.com or call us on 918-800-4580</li>
      </ol>
    </div>
    <diV class="">
      <p>This document was last updated on November 2019</p>
    </diV>
  </div>
</div>
