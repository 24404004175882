import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DTResult } from 'src/app/models/dt-result';
import { BehaviorSubject } from 'rxjs';
import { Chat } from 'src/app/models/chat';
import { UnreadNotifications } from 'src/app/models/notification';



@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  baseURL: string = environment.apiUrl;

  ChatSubscriptionSubject$ = new BehaviorSubject<Chat>(null);
  UnreadMessageEvntSubject$ = new BehaviorSubject<number>(null);
  UnreadNotificationsSubject$ = new BehaviorSubject<UnreadNotifications>({
    TotalUnReadMyAdsMessages: 0,
    TotalUnReadMyInterestsMessages: 0,
    TotalUnReadNotifications: 0
  });

  constructor(private http: HttpClient) { }

  getMyAds() {
    const url = this.baseURL + "messages/my-ads";
    return this.http.get<DTResult>(url);
  }

  getMyInterests() {
    const url = this.baseURL + "messages/my-interests";
    return this.http.get<DTResult>(url);
  }

  getChatList(adId, withUnReadMessageCount, withAverageRating, pageNumber, pageSize) {
    const url = this.baseURL + 'listings/' + adId + '/subscriptions';
    const params = new HttpParams()
      .set('withUnReadMessageCount', withUnReadMessageCount)
      .set('withAverageRating', withAverageRating)
      .set('page', pageNumber)
      .set('pageSize', pageSize);

    return this.http.get<DTResult>(url, { params });
  }

  getChatItem(listingId, subscriptionId, withUnReadMessageCount, withAverageRating) {
    const url = this.baseURL + 'listings/' + listingId + '/subscription/' + subscriptionId;
    const params = new HttpParams()
      .set('listingId', listingId)
      .set('subscriptionId', subscriptionId)
      .set('withUnReadMessageCount', withUnReadMessageCount)
      .set('withAverageRating', withAverageRating);
    return this.http.get<DTResult>(url, { params });
  }

  getMessagingSuggestions(subCategoryId) {
    const url = this.baseURL + 'subcategories/' + subCategoryId + '/suggestions';
    const headers = new HttpHeaders({}).set('skip', 'true');
    const params = new HttpParams().set('id', subCategoryId);
    return this.http.get<any>(url, { headers, params });
  }

  getChatMessages(listingId, subscriptionId, pageNumber, pageSize) {
    const url = this.baseURL + 'listings/' + listingId + '/subscriptions/' + subscriptionId + '/messages';
    const params = new HttpParams().set('page', pageNumber).set('pageSize', pageSize);

    return this.http.get<any>(url, { params });
  }

  postNewMessage(ListingId, Message, ListingSubscriptionId?) {
    const url = this.baseURL + 'messages';
    let httpOptions = {};

    if (ListingSubscriptionId !== undefined) {
      httpOptions = {
        params: new HttpParams({})
        .set('ListingId', ListingId)
        .set('Message', Message)
        .set('ListingSubscriptionId', ListingSubscriptionId)
      }
    } else {
      httpOptions = {
        params: new HttpParams({})
        .set('ListingId', ListingId)
        .set('Message', Message)
      }
    }

    return this.http.post<any>(url, null, httpOptions);
  }

  readMessages(listingSubscriptionId) {
    const url = this.baseURL + 'messages';
    const params = new HttpParams().set('listingSubscriptionId', listingSubscriptionId);
    return this.http.put<any>(url, null, { params });
  }

  deleteChatItem(id) {
    const url = this.baseURL + 'messages/all/' + id;
    return this.http.delete<DTResult>(url);
  }

  getNotificationsList(page, pageSize) {
    const url = this.baseURL + 'messages/notifications';
    const params = new HttpParams().set('page', page).set('pageSize', pageSize);

    return this.http.get<any>(url, { params });
  }

  markNotificationAsRead(notificationIdArr) {
    const url = this.baseURL + 'messages/notifications';
    return this.http.put<any>(url, notificationIdArr);
  }

  markAllNotificationsAdRead() {
    const url = this.baseURL + 'messages/notifications/all';
    return this.http.put<any>(url, null);
  }

  getUnreadMessageCount() {
    const url = this.baseURL + 'messages';
    return this.http.get<any>(url);
  }

  extendAd(listingId) {
    const url = this.baseURL + 'listings/' + listingId + '/extend-expiry';
    const params = new HttpParams({}).set('listingId', listingId);
    return this.http.post<any>(url, { params });
  }

}
