import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoGrowDirective } from './input-autogrow.directive';
import { ScrollDirective } from './scroll-assistant.directive';

@NgModule({
  declarations: [AutoGrowDirective, ScrollDirective],
  imports: [CommonModule],
  exports: [AutoGrowDirective, ScrollDirective]
})
export class SharedModule {}
