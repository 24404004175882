<div class="modal-container">
  <!-- <app-loader [message]="" [toggleTrigger]="formLoading"></app-loader> -->
  <div class="modal-header">
    <h4 class="modal-title">Join Frailey's Industrial</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <aw-wizard navBarLocation="">
    <aw-wizard-step awOptionalStep>
      <div class="modal-body">
        <form [formGroup]="signupForm" autocomplete="off">
          
          <div class="form-group" *ngIf="signupf.Phone.enabled" [@zoomInOnEnter]>
            <label>Mobile Number *</label>
            <ngx-intl-tel-input [cssClass]="'form-control'" [separateDialCode]="true" [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" name="phone" formControlName="Phone" [ngClass]="{ 'is-invalid': signupf.Phone.touched && signupf.Phone.errors }">
            </ngx-intl-tel-input>
            <div class="invalid-feedback" *ngIf="signupf.Phone.errors">
              <span *ngIf="signupf.Phone.errors.required">Mobile number is required</span>

              <span *ngIf="signupf.Phone.errors.validatePhoneNumber?.valid === false || signupf.Phone.errors.pattern">Please
                enter a valid mobile number</span>
              <span *ngIf="signupf.Phone.errors.numberInvalid">Please
                enter a valid mobile number</span>
            </div>
          </div>

          <div class="form-group">
            <label>Email Address *</label>
            <input (change)="trimEmailField($event)" formControlName="Email" class="form-control" placeholder="Email your email address" [ngClass]="[signupf.Email.touched && signupf.Email.errors ? 'is-invalid' : '', signupf.Email.touched && signupf.Email.status === 'VALID' ? 'is-valid' : '']">
            <div class="invalid-feedback" *ngIf="signupf.Email.errors">
              <span *ngIf="signupf.Email.errors.required">Email address is required</span>
              <span *ngIf="signupf.Email.errors.pattern">Please enter a valid email address</span>
            </div>
            <div class="valid-feedback" *ngIf="signupf.Email.status === 'VALID'">Email is available</div>
            <ngb-progressbar type="success" [value]="100" [striped]="true" [animated]="true" height="10px" *ngIf="signupf.Email.status === 'PENDING'"></ngb-progressbar>

          </div>

          <div class="form-group" *ngIf="signupf.Nickname.enabled">
            <label>Full Name *</label>
            <input autocomplete="nope" formControlName="Nickname" class="form-control" placeholder="Enter full name" [ngClass]="[signupf.Nickname.touched && signupf.Nickname.errors ? 'is-invalid' : '', signupf.Nickname.touched && signupf.Nickname.status === 'VALID' ? 'is-valid' : '']">
            <div class="invalid-feedback" *ngIf="signupf.Nickname.errors">
              <span *ngIf="signupf.Nickname.errors.required">Full name is required</span>
              <span *ngIf="signupf.Nickname.errors.minlength">Full name should be atleast 4 char</span>
              <span *ngIf="signupf.Nickname.errors.maxlength">Full name should be less than 100 characters</span>
              <!-- <span *ngIf="signupf.Nickname.errors.minlength">Username should be atleast 8
                characters</span>  -->
              <!-- <span *ngIf="signupf.Nickname.errors.pattern">Please enter a valid Full Name</span> 
               <span *ngIf="signupf.Nickname.errors.nicknameExists">Username already in use</span>  -->
            </div>

          </div>

          <!-- <div class="form-group">
            <label for="typeahead-http">Company Name *</label>
            <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" formControlName="CompanyName" [ngbTypeahead]="search" placeholder="Search company" [resultFormatter]="formatter" [inputFormatter]="formatter" (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)" />

            <div class="invalid-feedback" *ngIf="signupf.CompanyName.errors">
              <span *ngIf="signupf.CompanyName.errors.required">Company name is required</span>
              <span *ngIf="signupf.CompanyName.errors.maxlength">Company name should be less than 200 characters</span>
            </div>
         
          </div> -->
          <!-- 
          <div class="form-group">
            <label>Company Name *</label>
            <app-dropdown-component (getNextPage)="getCompanyList('', $event, 20, false)" (searchEvent)="getCompanyList($event, 1, 20, true)" [dropdownList]="companies" [pagination]="companyPagination" (itemSelectEvent)="companySelectEvent($event)" [selectedItem]="selectedCompany"></app-dropdown-component>
          </div> -->

          <!-- <div class="form-group">
            <label>Company Name *</label>
            <input autocomplete="nope" formControlName="CompanyName" class="form-control" placeholder="Enter company name" [ngClass]="[signupf.CompanyName.touched && signupf.CompanyName.errors ? 'is-invalid' : '', signupf.CompanyName.touched && signupf.CompanyName.status === 'VALID' ? 'is-valid' : '']">
            <div class="invalid-feedback" *ngIf="signupf.CompanyName.errors">
              <span *ngIf="signupf.CompanyName.errors.required">Company name is required</span>
              <span *ngIf="signupf.CompanyName.errors.maxlength">Company name should be less than 200 characters</span>
            </div>
          </div> -->


          <div class="form-group" *ngIf="signupf.Phone.disabled" [@zoomInOnEnter]>
            <label>Mobile Number</label>
            <p>{{existingPhone.isdcode}}{{existingPhone.value}}</p>
          </div>


          <div class="form-group d-flex" *ngIf="signupf.VerificationChannel.enabled" [@zoomInOnEnter]>
            <label class="mr-3">Send verification code to:</label>
            <label class="radio-label"><span class="mr-3">SMS</span>
              <input type="radio" name="VerificationChannel" value="2" formControlName="VerificationChannel" />
              <span class="radiomark"></span>
            </label>
            <label class="radio-label"><span class="p-16">Email</span>
              <input type="radio" name="VerificationChannel" value="1" formControlName="VerificationChannel" />
              <span class="radiomark"></span>
            </label>
          </div>

          <!-- <div class="form-group" *ngIf="existingPhone.exists" [@zoomInOnEnter]>
            <span class="p-16">Verification code will be sent <ng-container *ngIf="existingPhone.exists">to
                {{existingPhone.value}}</ng-container></span>
            <div class="text-muted small">(Standard rates apply)</div>            
         </div> -->


          <div class="form-group" *ngIf="signupf.Password.enabled" [@zoomInOnEnter] [@zoomOutOnLeave]>
            <label>Password *</label>
            <div class="input-group">
              <input [type]="showPassword.password ? 'text' : 'password'" formControlName="Password" class="form-control" placeholder="Enter a password" autocomplete="new-password" [ngClass]="{ 'is-invalid': signupf.Password.touched && signupf.Password.errors }">

              <div class="input-group-append" (click)="showPassword.password = !showPassword.password">
                <span class="input-group-text show-pwd">
                  <img [src]="showPassword.password ? '../../assets/icons/eye-closed-1.svg' : '../../assets/icons/eye-open-1.svg'">
                </span>
              </div>
              <div class="invalid-feedback" *ngIf="signupf.Password.errors">
                <span *ngIf="signupf.Password.errors.required">Please choose a password</span>
                <span *ngIf="signupf.Password.errors.minlength">Password must be atleast 8 characters
                  long</span>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="signupf.ConfirmPassword.enabled" [@zoomInOnEnter] [@zoomOutOnLeave]>
            <label>Repeat Password *</label>
            <div class="input-group">
              <input [type]="showPassword.confirmPassword ? 'text' : 'password'" formControlName="ConfirmPassword" class="form-control" placeholder="Repeat the password" autocomplete="new-password" [ngClass]="{ 'is-invalid': signupf.ConfirmPassword.touched && signupForm.errors }">
              <div class="input-group-append" (click)="showPassword.confirmPassword = !showPassword.confirmPassword">
                <span class="input-group-text show-pwd">
                  <img src="../../assets/icons/eye-open-1.svg">
                </span>
              </div>
            </div>
          </div>
          <div class="password-match-error" *ngIf="signupf.Password.touched && signupf.ConfirmPassword.touched && signupForm.errors !== null">
            Passwords should match</div>

          <!-- <div class="form-group account-type">
            <label>Account Type *</label>
            <div class="d-flex">
              <label class="radio-label"><span class="p-16">Individual</span>
                <input type="radio" name="BusinessYN" formControlName="BusinessYN" [value]="false">
                <span class="radiomark"></span>
              </label>
              <label class="radio-label ml-4"><span class="p-16">Company</span>
                <input type="radio" name="BusinessYN" formControlName="BusinessYN" [value]="true">
                <span class="radiomark"></span>
              </label>
            </div>
          </div> -->

          <label class="checkbox-label">
            <input type="checkbox" formControlName="Terms">
            <span class="checkmark"></span>
          </label>
          <span class="p-16 terms">I agree to the <span class="link" (click)="openTermsandConditions()">Terms
              and Conditions</span></span>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="goToLogin(true, null)">Back to Login</button>
        <button type="button" class="btn btn-primary" [disabled]="signupForm.invalid" (click)="signup(false, null)">Submit</button>
      </div>

      <div class="modal-footer d-flex flex-column">
        <!-- <div class="or-divider w-100 d-flex justify-content-center">OR</div>
        <div class="d-flex flex-column flex-md-row social-btn-container">
          <button type="button" class="social-btn facebook-btn" (click)="openFacebookLogin()" *ngIf="facebookEnabled">
            Sign Up with Facebook
          </button>
          <button type="button" id="btnLoginGoogle" class="social-btn google-btn" *ngIf="googleEnabled">
            Sign Up with Google
          </button>
        </div> -->
        <ngb-alert type="danger" *ngFor="let error of formErrors; let i = index" (close)="closeError(error)">
          {{error}}</ngb-alert>
      </div>
    </aw-wizard-step>

    <aw-wizard-step awOptionalStep>
      <div class="modal-body otp-body d-flex flex-column jusify-content-start align-items-center">
        <span *ngIf="signupf.VerificationChannel.value === '1' ">Please enter the verification code we have sent on your
          email - {{ signupf.Email.value }}</span>
        <span *ngIf="!existingPhone.exists && signupf.VerificationChannel.value === '2'">Please enter the code we have
          sent to - {{ getMaskedPhoneNumber() }}</span>
        <span *ngIf="existingPhone.exists && signupf.VerificationChannel.value === '2'">Please enter the code
          we have sent to {{ existingPhone.value }}</span>
        <div class="opt-container d-flex jusify-content-center align-items-center my-3">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true}">
          </ng-otp-input>
        </div>
        <ngb-alert type="danger" *ngFor="let error of formErrors; let i = index" (close)="closeError(error)">
          {{error}}</ngb-alert>
      </div>
      <div class="modal-footer d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-column justify-content-start resend-code-container">

          <a class="theme-link" [ngClass]="{'disabled': signUpOTP.resendDisabled }" (click)="resendOTP(null)">Resend Code
            <span *ngIf="signUpOTP.resendOTPTimer !== 30">({{signUpOTP.resendOTPTimer}})</span></a>

          <a class="resend-link text-small" (click)="resendOTP('2')" *ngIf="!signUpOTP.resendDisabled && signupf.VerificationChannel.value === '1' && !existingPhone.exists">Resend Code to {{
            getMaskedPhoneNumber() }}?</a>

          <a class="resend-link text-small" (click)="resendOTP('2')" *ngIf="!signUpOTP.resendDisabled && signupf.VerificationChannel.value === '1' && existingPhone.exists">Resend Code to {{
            existingPhone.value }}?</a>

          <a class="resend-link text-small" (click)="resendOTP('1')" *ngIf="!signUpOTP.resendDisabled && signupf.VerificationChannel.value === '2' && signupf.Email.value !== ''">Resend Code to {{
            signupf.Email.value }}?</a>

        </div>
        <div>
          <button type="button" class="btn btn-outline-dark mr-2" [awGoToStep]="{ stepIndex: 0 }">Back</button>
          <button type="button" class="btn btn-primary" [disabled]="signUpOTP.value.length !== 6" (click)="submitOTP()">Submit</button>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step awOptionalStep>
      <div class="modal-body">
        <p *ngIf="actionsRequiredModal.EmailVerificationRequired">Thank you for registering with Frailey's Industrial.</p>
        <p *ngIf="actionsRequiredModal.EmailVerificationRequired">Following account information requires attention.</p>
        <p *ngIf="actionsRequiredModal.EmailRequired">Please enter below information to complete registration.</p>
        <div class="property-container d-flex flex-column">
          <!-- <span class="my-1" *ngIf="actionsRequiredModal.EmailRequired">&#8226; Email Address is missing</span> -->
          <!-- <span class="my-1" *ngIf="actionsRequiredModal.PhoneRequired">&#8226; Mobile Number is missing</span> -->
          <form [formGroup]="socialSignUpForm">
            <div class="form-group" *ngIf="actionsRequiredModal.EmailRequired">
              <label>Email Address *</label>
              <input class="form-control" type="text" placeholder="Enter your email address" formControlName="Email" [ngClass]="[socialSignUpF.Email.touched && socialSignUpF.Email.errors ? 'is-invalid' : '', socialSignUpF.Email.touched && socialSignUpF.Email.status === 'VALID' ? 'is-valid' : '']">
              <div class="invalid-feedback" *ngIf="socialSignUpF.Email.errors">
                <span *ngIf="socialSignUpF.Email.errors.required">Email address is required</span>
                <span *ngIf="socialSignUpF.Email.errors.pattern">Please enter a valid email address</span>
                <span *ngIf="socialSignUpF.Email.errors.emailExistsBoth">Email address is already in use by another
                  account</span>
              </div>
              <div class="valid-feedback" *ngIf="socialSignUpF.Email.status === 'VALID'">Email is available</div>
              <ngb-progressbar type="success" [value]="100" [striped]="true" [animated]="true" height="10px" *ngIf="socialSignUpF.Email.status === 'PENDING'"></ngb-progressbar>
            </div>

            <div class="form-group" *ngIf="actionsRequiredModal.PhoneRequired">
              <label>Mobile Number</label>
              <ngx-intl-tel-input [cssClass]="'form-control w-100'" [separateDialCode]="true" [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" name="phone" formControlName="Phone" [ngClass]="{ 'is-invalid': socialSignUpF.Phone.touched && socialSignUpF.Phone.errors }">
              </ngx-intl-tel-input>
              <div class="invalid-feedback" *ngIf="socialSignUpF.Phone.errors">
                <span *ngIf="socialSignUpF.Phone.errors.required">Mobile number is required</span>
                <span *ngIf="socialSignUpF.Phone.errors.validatePhoneNumber?.valid === false || socialSignUpF.Phone.errors.pattern">Please
                  enter a valid mobile number</span>
                <span *ngIf="socialSignUpF.Phone.errors.numberInvalid">Please
                  enter a valid mobile number</span>
              </div>
            </div>
          </form>
          <span class="my-1" *ngIf="actionsRequiredModal.EmailVerificationRequired">&#8226; Email Address needs to be
            verified</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="loginWithFacebook()" [disabled]="socialSignUpForm.invalid" *ngIf="actionsRequiredModal.EmailRequired">Submit</button>
      </div>
    </aw-wizard-step>
  </aw-wizard>

</div>
