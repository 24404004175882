import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignupComponent } from '../signup/signup.component';
import { LoginComponent } from '../login/login.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { Router } from '@angular/router';
import { ToastService } from '../toast/toast.service';
import { CoreService } from '../services/core/core.service';
import { Observable } from 'rxjs';
import { AppProperty } from '../models/app-property';
import { TermsComponent } from '../terms/terms.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']

})
export class FooterComponent implements OnInit {

  contactDetails = {
    contactPhone: null,
    contactEmail: null,
    facebookUrl: null,
    instaUrl: null,
    youtubeUrl: null,
    copyrightYear: null
  }

  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private toast: ToastService,
    private coreService: CoreService
  ) {

  }


  ngOnInit(): void {
    this.coreService.appProperties$.subscribe((value) => {
      if (value !== null) {
        value.forEach((property: AppProperty) => {
          switch (property.Property) {
            case ('Phone'): {
              this.contactDetails.contactPhone = property.Value;
            }
            case ('Email'): {
              this.contactDetails.contactEmail = property.Value;
            }
            case ('FacebookPageURL'): {
              this.contactDetails.facebookUrl = property.Value;
            }
            case ('InstaPageURL'): {
              this.contactDetails.instaUrl = property.Value;
            }
            case ('YoutubePageURL'): {
              this.contactDetails.youtubeUrl = property.Value;
            }
            case ('CopyrightYear'): {
              this.contactDetails.copyrightYear = property.Value;
            }
          }
        })
      }
    });
  }

  openSignUp() {
    if (JSON.parse(localStorage.getItem("LOGGED_IN_USER"))) {
      this.toast.show(
        "You are already logged-in",
        {
          classname: "bg-success text-light",
          delay: 2000,
          autohide: true,
          headertext: "alert"
        }
      );
    } else {
      const modal = this.modalService.open(SignupComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
      modal.result.then((res) => {
        if (res.emailFieldValue !== null) {
          this.openLogin(res.emailFieldValue);
        }
      })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  openLogin(existingEmail: string) {
    const modal = this.modalService.open(LoginComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
    if (existingEmail !== null) {
      modal.componentInstance.loginF.Email.setValue(existingEmail)
    }
    modal.result.then((res) => {
      if (res === 'open-signup') {
        this.openSignUp();
      }
      if (res === 'open-forgotpassword') {
        this.openForgotPassword();
      }
    })
      .catch((err) => {
        console.log(err);
      })
  }

  openForgotPassword() {
    const modal = this.modalService.open(ForgotPasswordComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
    modal.result.then((res) => {
      if (res === 'open-login') {
        this.openLogin(null);
      }
    })
      .catch((err) => {
        console.log(err);
      })
  }

  navigateToHome() {
    if (this._router.url === "/home") {
      this.coreService.scrollToTop();
    }
    else {
      this._router.navigate(['/home'])
    }
  }

  navigateToPlaceAd() {
    if (this._router.url === "/new") {
      this.coreService.scrollToTop();
    }
    else {
      this._router.navigate(['/new'])
    }
  }

  openTermsandConditions() {
    const modal = this.modalService.open(TermsComponent, { size: 'xl', centered: true, windowClass: 'animated-modal', scrollable: false });
    modal.result.then((res) => {
    })
      .catch((err) => {
      })
  }

  goToContactUs() {
    this._router.navigate(['contact-us']);
  }

  openGuarantees(content) {
    const modal = this.modalService.open(content, { size: 'xl', centered: true, windowClass: 'animated-modal', scrollable: false });
    modal.result.then((res) => {

    })
      .catch((err) => {

      })
  }

  openOurStory(content) {
    const modal = this.modalService.open(content, { size: 'xl', centered: true, windowClass: 'animated-modal', scrollable: false });
    modal.result.then((res) => {

    })
      .catch((err) => {

      })
  }
}