import {
  AfterViewInit,
  Component,
  Injectable,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgbActiveModal, NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AsyncValidatorFn,
} from "@angular/forms";
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  first,
  map,
  take,
  tap,
  takeUntil,
  skip,
  filter,
} from "rxjs/operators";
import { AuthService } from "../services/auth/auth.service";
import {
  zoomInOnEnterAnimation,
  zoomOutOnLeaveAnimation,
} from "angular-animations";
import { WizardComponent } from "angular-archwizard";
import {
  SearchCountryField,
  CountryISO,
} from "ngx-intl-tel-input";
import { BaseComponent } from "../base/base.component";
import { DTResult } from "../models/dt-result";
import { CoreService } from "../services/core/core.service";
import { merge, Observable, of, OperatorFunction, Subject, Subscription } from "rxjs";
import { SocialMediaEnum } from "../models/social-media";
import { SocketService } from "../services/socket/socket.service";
import { ActionRequiredEnum } from "../models/authData";
import { Pagination } from "../models/pagination";
import { ToastService } from "../toast/toast.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginComponent } from "../login/login.component";
import { TermsComponent } from "../terms/terms.component";
declare const gapi: any;

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
  animations: [
    zoomInOnEnterAnimation({ duration: 200 }),
    zoomOutOnLeaveAnimation({ duration: 200 }),
  ],
})
export class SignupComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(WizardComponent, { static: false }) wizard: WizardComponent;
  subscriptions: Subscription[] = [];
  signupForm: FormGroup;
  phoneNumberStaticValidators = [Validators.required];
  userExistanceStatus = 0;
  existingPhone = {
    exists: false,
    value: null,
    isdcode: null,
  };
  formLoading = false;
  formErrors = [];

  signUpOTP = {
    Config: {
      length: 6,
      allowNumbersOnly: true,
    },
    value: "",
    isValid: false,
    resendDisabled: false,
    resendOTPTimer: 30,
  };

  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  TooltipLabel: any = { Name: "" };
  showPassword = {
    password: false,
    confirmPassword: false,
  };
  GoogleClientId: string;
  public auth2: any;
  //private clientId: string = '302711752090-8ec8sgdl233u4a9ntlb0f2388dfjage9.apps.googleusercontent.com';
  private scope = ["profile", "email"].join(" ");
  facebookEnabled = true;
  googleEnabled = true;
  socialSignUpForm: FormGroup;
  public actionsRequiredModal = {
    show: false,
    dismisable: true,
    EmailRequired: false,
    EmailVerificationRequired: false,
    PhoneRequired: false,
  };

  SSOFirstName = null;
  SSOLastName = null;

  submitPlaceAdForm = false;

  public socialLoginBody = null as any;

  companies = new Array<any>();
  companyPagination: Pagination = {
    CurrentPage: 1,
    FirstRowOnPage: 0,
    LastRowOnPage: 0,
    PageCount: 0,
    PageSize: 10,
    RowCount: 0,
  };
  selectedCompany: any = null;

  model: any;
  searching = false;
  searchFailed = false;

  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.authService
          .getCompanies(term, 1, 50, "CompanyName ASC")
      ),
      tap(() => this.searching = false),
      map(response => response.Data.Results)
    )

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private coreService: CoreService,
    private socketService: SocketService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
    this.signupForm = this.formBuilder.group(
      {
        Nickname: new FormControl("", {
          validators: Validators.compose([
            // Validators.pattern("^[a-zA-Z0-9 ._-]+$"),
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(100)
          ]),

          // updateOn: 'blur'
        }),
        // CompanyName: new FormControl("", [Validators.required, Validators.maxLength(200)]),

        Email: new FormControl("", {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(
              "^([\\w-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$"
            ),
          ]),
          updateOn: "blur",
        }),
        Password: new FormControl(
          { value: "", disabled: true },
          Validators.compose([Validators.required, Validators.minLength(8)])
        ),
        ConfirmPassword: new FormControl(
          { value: "", disabled: true },
          Validators.compose([Validators.required, Validators.minLength(6)])
        ),
        PhoneInput: new FormControl(""),
        Phone: new FormControl("", this.phoneNumberStaticValidators),
        // BusinessYN: new FormControl(
        //   false,
        //   Validators.compose([Validators.required])
        // ),
        Terms: new FormControl(
          false,
          Validators.compose([Validators.requiredTrue])
        ),
        VerificationChannel: new FormControl({ value: "2", disabled: true }),
      },
      {
        validators: this.passwordMatch.bind(this),
      }
    );

    this.socialSignUpForm = this.formBuilder.group({
      Email: new FormControl("", {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(
            "^([\\w-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$"
          ),
        ]),
        updateOn: "blur",
      }),
      Phone: new FormControl(""),
    });
    this.socialSignUpF.Email.setAsyncValidators(
      this.socialEmailExistsValidator()
    );

    this.signupf.Email.setAsyncValidators(this.emailExistsValidator());
    // this.signupf.Nickname.setAsyncValidators(this.nicknameExistsValidator());
    this.signupf.Phone.setAsyncValidators(this.phoneNumberValidator());
  }

  formatter = (result: any) => result.DisplayText;

  socialEmailExistsValidator(): AsyncValidatorFn {
    return (control) =>
      control.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((value) => {
          return this.authService.checkIfEmailExists(value);
        }),
        map((emailExistence) => {
          switch (emailExistence.UserExists) {
            case 0: {
              return null;
            }
            case 1: {
              this.signupf.Email.setValue(this.socialSignUpF.Email.value);
              this.wizard.goToStep(0);
              return null;
            }
            case 2: {
              this.goToLogin(true, this.socialSignUpF.Email.value);
              return null;
              // return { emailExistsBoth: true };
            }
          }
        }),
        first()
      );
  }

  get socialSignUpF() {
    return this.socialSignUpForm.controls;
  }

  passwordMatch(formGroup: FormGroup) {
    const { value: password } = formGroup.get("Password");
    const { value: confirmPassword } = formGroup.get("ConfirmPassword");
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.getCompanyList("", 1, 20, true);

    this.subscriptions.push(
      this.coreService.enabledSocialMedias$.subscribe((value) => {
        if (value !== null) {
          let FacebookApiUrl = null;
          let FacebookAppId = null;
          let FacebookAppVersion = null;
          this.subscriptions.push(
            this.coreService.appProperties$.subscribe(async (value: any) => {
              if (value !== null) {
                await value.map((property) => {
                  switch (property.Property) {
                    case "FacebookAppId": {
                      FacebookAppId = property.Value;
                      break;
                    }
                    case "FacebookAppVersion": {
                      FacebookAppVersion = property.Value;
                      break;
                    }
                    case "FacebookApiURL": {
                      FacebookApiUrl = property.Value;
                      break;
                    }
                    case "GoogleClientId": {
                      this.GoogleClientId = property.Value;
                      break;
                    }
                  }
                });
              }
            })
          );
          value.forEach((socialMedia) => {
            switch (socialMedia.SocialMediaTypeEnumId) {
              case SocialMediaEnum.FACEBOOK: {
                setTimeout(() => {
                  this.facebookEnabled = true;
                });

                this.initialiseFbLibrary(
                  FacebookApiUrl,
                  FacebookAppId,
                  FacebookAppVersion
                );
                break;
              }
              case SocialMediaEnum.GOOGLE: {
                setTimeout(() => {
                  this.googleEnabled = true;
                });
                this.googleInit();
                break;
              }
            }
          });
        }
      })
    );
    if (this.socialLoginBody !== null) {
      setTimeout(() => {
        this.wizard.goToStep(2);
      }, 1);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  initialiseFbLibrary(FacebookApiURL, FacebookAppId, FacebookAppVersion) {
    (window as any).fbAsyncInit = function () {
      window["FB"].init({
        appId: FacebookAppId,
        cookie: true,
        xfbml: true,
        version: FacebookAppVersion,
      });
      window["FB"].AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = FacebookApiURL;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = FacebookApiURL;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  openFacebookLogin() {
    window["FB"].login(
      (response) => {
        if (response.authResponse) {
          this.socialLoginBody = {
            provider: "facebook",
            accessToken: response.authResponse.accessToken,
            email: "",
            iSDCode: "",
            phoneNumber: "",
          };
          this.loginWithFacebook();
        } else {
          console.log("User login failed");
          this.formErrors.push("Facebook sign in failed. Please try again.");
        }
      },
      { scope: "email" }
    );
  }

  public googleInit() {
    const that = this;
    gapi.load("auth2", (f) => {
      that.auth2 = gapi.auth2.init({
        client_id: that.GoogleClientId,
        cookiepolicy: "single_host_origin",
        scope: that.scope,
        prompt: "select_account consent",
      });
      that.attachSignin(document.getElementById("btnLoginGoogle"));
    });
  }

  public attachSignin(element) {
    const that = this;
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        this.socialLoginBody = {
          provider: "google",
          accessToken: googleUser.getAuthResponse().id_token,
          email: "",
          iSDCode: "",
          phoneNumber: "",
        };
        this.loginWithFacebook();
      },
      (error) => {
        console.log(JSON.stringify(error, undefined, 2));
      }
    );
  }

  loginWithFacebook() {
    this.formLoading = true;
    this.formErrors = [];
    if (this.socialSignUpForm.valid) {
      this.socialLoginBody.email = this.socialSignUpF.Email.value;
      this.socialLoginBody.phoneNumber =
        this.socialSignUpF.Phone.value?.number.replace(/\s/g, "");
      this.socialLoginBody.iSDCode = this.socialSignUpF.Phone.value?.dialCode;
    }
    this.subscriptions.push(
      this.authService.loginWithFacebook(this.socialLoginBody).subscribe(
        (response: DTResult) => {
          if (response.State) {
            this.formLoading = false;
            if (response.Data.AuthResult.accessToken == null) {
              if (response.Data.AuthResult.actionsRequired.length === 0) {
                this.actionsRequiredModal = {
                  show: false,
                  dismisable: true,
                  EmailRequired: false,
                  EmailVerificationRequired: false,
                  PhoneRequired: false,
                };
              } else {
                response.Data.AuthResult.actionsRequired.map((action) => {
                  if (
                    action.ActionRequiredEnumId ===
                    ActionRequiredEnum.VerifyEmail
                  ) {
                    // this.actionsRequiredModal.show = false;
                    this.actionsRequiredModal.EmailVerificationRequired = true;
                  }

                  if (
                    action.ActionRequiredEnumId ===
                    ActionRequiredEnum.PhoneRequired
                  ) {
                    // this.actionsRequiredModal.show = false;
                    this.actionsRequiredModal.PhoneRequired = true;
                  }

                  if (
                    action.ActionRequiredEnumId ===
                    ActionRequiredEnum.EmailRequired
                  ) {
                    // this.actionsRequiredModal.show = true;
                    this.actionsRequiredModal.dismisable = false;
                    this.actionsRequiredModal.EmailRequired = true;
                  }
                });
                if (
                  response.Data.AuthResult.actionsRequired.find(
                    (action) =>
                      action.ActionRequiredEnumId ===
                      ActionRequiredEnum.EmailRequired
                  ) !== undefined
                ) {
                  this.actionsRequiredModal.show = true;
                } else {
                  this.actionsRequiredModal.show = false;
                }
                // if (response.Data.AuthResult.actionsRequired.contain(action => action.ActionRequiredEnumId === ActionRequiredEnum.EmailRequired)) {
                //   this.actionsRequiredModal.show = true;
                // } else {
                //   this.actionsRequiredModal.show = false;
                // }
              }
              if (this.actionsRequiredModal.show) {
                this.zone.run(() => {
                  this.wizard.goToStep(2);
                });
              }
            } else {
              localStorage.setItem("LOGGED_IN_USER", JSON.stringify(response));
              this.authService.authObject$.next(response);
              this.socketService.ConnectToServer();

              if (this.submitPlaceAdForm) {
                this.activeModal.close("submit-ad");
                // return;
              } else {
                this.activeModal.close("");
              }
            }
          } else {
            this.formErrors = response.Messages;
          }
        },
        (error) => {
          this.formErrors.push(error);
          this.formLoading = false;
        }
      )
    );
  }

  trimEmailField(event) {
    this.signupf.Email.setValue(event.target.value.trim());
  }

  get signupf() {
    return this.signupForm.controls;
  }

  emailExistsValidator(): AsyncValidatorFn {
    return (control) =>
      control.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((value) => {
          return this.authService.checkIfEmailExists(value);
        }),
        map((emailExistence) => {
          switch (emailExistence.UserExists) {
            case 0: {
              this.userExistanceStatus = 0;
              if (this.signupf.Phone.disabled) {
                this.signupf.Phone.enable();
                this.signupf.Phone.updateValueAndValidity();
              }
              this.signupf.Nickname.patchValue(null)
              this.signupf.Password.enable();
              this.signupf.ConfirmPassword.enable();
              this.signupf.VerificationChannel.enable();
              this.existingPhone = {
                exists: false,
                value: null,
                isdcode: null,
              };
              return null;
            }
            case 1: {
              this.userExistanceStatus = 1;
              this.signupf.Password.disable();
              this.signupf.Nickname.patchValue(`${emailExistence.FirstName} ${emailExistence.LastName}`);
              this.SSOFirstName = emailExistence.FirstName;
              this.SSOLastName = emailExistence.LastName;
              this.signupf.ConfirmPassword.disable();
              if (
                emailExistence.PhoneNumber !== null &&
                emailExistence.PhoneNumber !== ""
              ) {
                this.signupf.VerificationChannel.enable();
                this.signupf.Phone.disable();
                this.existingPhone = {
                  exists: true,
                  value: emailExistence.PhoneNumber,
                  isdcode: emailExistence.ISDCode,
                };
              } else {
                this.signupf.Phone.enable();
                this.signupf.Phone.updateValueAndValidity();
                this.signupf.VerificationChannel.disable();
                this.existingPhone = {
                  exists: false,
                  value: null,
                  isdcode: null,
                };
              }
              return null;
            }
            case 2: {
              this.userExistanceStatus = 2;
              this.goToLogin(true, this.signupf.Email.value);
              this.existingPhone = {
                exists: false,
                value: null,
                isdcode: null,
              };
              return { emailExistsBoth: true };
            }
            case 3: {
              this.userExistanceStatus = 3;
              this.signupf.Nickname.disable();
              // this.signupf.CompanyName.disable();
              this.signupf.Password.disable();
              this.signupf.ConfirmPassword.disable();
              if (emailExistence.PhoneNumber !== null) {
                this.signupf.VerificationChannel.enable();
                this.signupf.Phone.disable();
                this.existingPhone = {
                  exists: true,
                  value: emailExistence.PhoneNumber,
                  isdcode: emailExistence.ISDCode,
                };
              }
              return null;
            }
          }
        }),
        first()
      );
  }


  phoneNumberValidator(): AsyncValidatorFn {
    return (control) =>
      control.valueChanges.pipe(
        distinctUntilChanged(),
        switchMap((value) => {
          return this.authService.validatePhoneNumber(
            this.signupf.Phone.value?.dialCode,
            this.signupf.Phone.value?.number.replaceAll('-', '')
          );
        }),
        take(1),
        tap(() => control.markAsTouched()),
        map((response) => {
          if (this.signupf.Phone.value !== null) {
            if (response.Data && response.Data.IsMobile) {
              this.signupf.VerificationChannel.enable();
              console.log(response);
              this.existingPhone = {
                exists: true,
                value: control.value.number,
                isdcode: control.value.dialCode,
              };
              return null;
            } else {
              this.existingPhone = {
                exists: false,
                value: null,
                isdcode: null,
              };
              this.signupf.VerificationChannel.disable();
              return { numberInvalid: true };
            }
          } else {
            return null;
          }
        }),
        first()
      );
  }

  goToLogin(emailExists: boolean, emailValue: string) {
    // this.router.navigate(["home"])
    // this.modalService.open(LoginComponent);
    // this.activeModal.close('open-login')
    if (emailExists) {
      this.activeModal.close({
        emailFieldValue: emailValue,

      });
    } else {
      this.activeModal.close({
        emailFieldValue: null,
      });
    }

  }

  openTermsandConditions() {
    const modal = this.modalService.open(TermsComponent, {
      size: "xl",
      centered: true,
      windowClass: "animated-modal",
      scrollable: false,
    });
    modal.result.then((res) => { }).catch((err) => { });
  }

  signup(resend: boolean, verificationChannel) {
    // this.signupForm.controls['CompanyName'].setValue()
    let company = '';
    // if (this.signupForm.controls['CompanyName'].value.DisplayText !== undefined) {

    //   company = this.signupForm.controls['CompanyName'].value.DisplayText
    // } else {
    //   company = this.signupForm.controls['CompanyName'].value;
    // }
    // this.signupForm.controls['CompanyName'].setValue(this.signupForm.get('CompanyName').value.trim());
    this.signupForm.controls['Nickname'].setValue(this.signupForm.get('Nickname').value.trim());
    this.formErrors = [];
    this.formLoading = true;
    let registrationData = {};
    if (this.signupForm.invalid) {
      return;
    }

    if (this.signupf.Email.value === "" || this.signupf.Email.value === null) {
      verificationChannel = "2";
    }

    if (verificationChannel !== null) {
      this.signupf.VerificationChannel.setValue(verificationChannel);
    }

    const form = this.signupForm.getRawValue();
    switch (this.userExistanceStatus) {
      case 0: {
        registrationData = {
          Email: this.signupForm.value.Email,
          NickName: this.signupForm.value.Nickname.trim(),
          // CompanyName: company,
          Password: this.signupForm.value.Password,
          PhoneNumber: this.signupf.Phone.value.number.replaceAll(" ", "").replaceAll('-', ''),
          ISDCode: this.signupf.Phone.value.dialCode,
          // BusinessYN: this.signupForm.value.BusinessYN === "true" ? true : false,           
          postActivateURI: "",
          // VerificationChannel: 2,
          VerificationChannel: Number(form.VerificationChannel),
        };
        break;
      }
      case 1: {
        registrationData = {
          Email: this.signupForm.value.Email,
          NickName: this.signupForm.value.Nickname.trim(),
          FirstName: this.SSOFirstName ? this.SSOFirstName : (this.signupForm.value.Nickname.trim().split(' ')[0] || ''),
          LastName: this.SSOLastName ? this.SSOLastName : (this.signupForm.value.Nickname.trim().split(' ').slice(1).join(' ') || ''),
          // CompanyName: company,
          PhoneNumber: this.existingPhone.exists
            ? this.existingPhone.value
            : this.signupf.Phone.value.number.replaceAll(' ', '').replaceAll('-', ''),
          ISDCode: this.existingPhone.exists
            ? this.existingPhone.isdcode
            : this.signupf.Phone.value.dialCode,
          // BusinessYN: this.signupForm.value.BusinessYN === "true" ? true : false,
          postActivateURI: "",
          // VerificationChannel: 2,
          VerificationChannel: Number(form.VerificationChannel),
        };
        break;
      }
      case 3: {
        registrationData = {
          Email: this.signupForm.value.Email,
          NickName: this.signupForm.value.Nickname.trim(),
          // CompanyName: company,
          PhoneNumber: this.existingPhone.exists
            ? this.existingPhone.value
            : this.signupf.Phone.value.number.replaceAll(' ', '').replaceAll('-', ''),
          ISDCode: this.existingPhone.exists
            ? this.existingPhone.isdcode
            : this.signupf.Phone.value.dialCode,
          // BusinessYN:this.signupForm.value.BusinessYN === "true" ? true : false,
          postActivateURI: "",
          VerificationChannel: Number(form.VerificationChannel),
        };
        break;
      }
    }
    this.authService
      .registerUser(registrationData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.formLoading = false;
        if (response.State) {
          if (!resend) {
            setTimeout(() => {
              this.wizard.goToNextStep();
            }, 200);
          }
        } else {
          this.formErrors = response.Messages;
        }
      });
  }

  getMaskedPhoneNumber() {
    let new_string =
      this.signupf.Phone.value?.dialCode +
      "******" +
      this.signupf.Phone.value?.number?.substring(
        this.signupf.Phone.value?.number?.length - 2,
        this.signupf.Phone.value?.number?.length
      );
    return new_string;
  }

  onOtpChange(event) {
    this.signUpOTP.value = event;
  }

  closeError(error) {
    this.formErrors = this.formErrors.filter(
      (formError) => formError !== error
    );
  }

  submitOTP() {
    this.formLoading = true;
    this.formErrors = [];
    this.authService
      .activateAccountWithOTP(this.signupf.Email.value, this.signUpOTP.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        // console.log(response)
        this.formLoading = false;
        if (response) {
          // localStorage.setItem("LOGGED_IN_USER", JSON.stringify(response));
          // this.authService.authObject$.next(response);
          this.activeModal.close("");
          this.toastService.show('Awaiting further verification from admin', {
            classname: 'bg-success text-light',
            delay: 2000,
            autohide: true,
            headertext: 'Otp verified'
          });
        } else {
          this.toastService.show('Enter valid otp', {
            classname: 'bg-danger text-light',
            delay: 2000,
            autohide: true,
            headertext: 'Invalid otp'
          });
        }
      });
  }

  resendOTP(verificationChannel) {
    if (!this.signUpOTP.resendDisabled) {
      this.formLoading = true;
      this.formErrors = [];
      this.signUpOTP.resendDisabled = true;
      const OTPTimer = setInterval(() => {
        if (this.signUpOTP.resendOTPTimer > 1) {
          this.signUpOTP.resendOTPTimer--;
        } else {
          this.signUpOTP.resendDisabled = false;
          clearInterval(OTPTimer);
          this.signUpOTP.resendOTPTimer = 30;
        }
      }, 1000);
      this.signup(true, verificationChannel);
    }
  }

  completeSocialRegistration() {
    if (this.socialSignUpForm.invalid) {
      return;
    } else {
      this.formLoading = true;
      this.authService
        .loginWithFacebook(this.authService.socialLoginBody)
        .subscribe(
          (response: DTResult) => {
            if (response.State) {
              localStorage.setItem("LOGGED_IN_USER", JSON.stringify(response));
              this.authService.authObject$.next(response);
              this.socketService.ConnectToServer();
            }
            this.formLoading = true;
            if (this.submitPlaceAdForm) {
              this.activeModal.close("submit-ad");
              return;
            }
            this.activeModal.close("logged in");
          },
          (error) => {
            console.log(error);
            this.formLoading = false;
          }
        );
    }
  }

  companySelectEvent(company) {
    this.selectedCompany = company;
  }

  getCompanyList(searchText, pageNumber, pageSize, reset) {

    // return new Promise((resolve, reject) => {
    this.authService
      .getCompanies(searchText, pageNumber, pageSize, "CompanyName ASC")
      .subscribe((res: DTResult) => {
        if (res.State) {
          this.companies = reset
            ? res.Data.Results
            : [...this.companies, ...res.Data.Results];

          this.companyPagination = {
            CurrentPage: res.Data.CurrentPage,
            FirstRowOnPage: res.Data.FirstRowOnPage,
            LastRowOnPage: res.Data.LastRowOnPage,
            PageCount: res.Data.PageCount,
            PageSize: res.Data.PageSize,
            RowCount: res.Data.RowCount,
          };

          // resolve(true);
        } else {
          // resolve(false);
        }
      }, (error) => {
        console.log('Error', error);
      });
    // });
  }
}
