import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthData } from 'src/app/models/authData';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

  AuthUser: AuthData;

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getLoginState()
      .pipe(
        map(value => {          
          this.AuthUser = value;
          if (this.AuthUser !== null) {
            return true;
          } else {
            if (next.routeConfig.path.includes('message-center')) {              
              this.router.navigate(['login'], { queryParams: { redirectUrlPath: next.routeConfig.path, redirectUrlParams: JSON.stringify(next.queryParams) } });
            } else if (next.routeConfig.path.includes('details')) {            
              this.router.navigate(['stayonbrowse'], { queryParams: { redirectUrlPath: next.routeConfig.path, redirectUrlParams: JSON.stringify(next.queryParams) } })
            } else {
              this.router.navigate(['home'])
            }
            return false;
          }
        })
      );
  }
}