<div class="main w-100">
  <div ngbDropdown class="d-inline-block w-100">
    <button class="form-control btn customer-select-text w-100" id="dropdownForm1" ngbDropdownToggle>
      <h3 *ngIf="selectedItem !== null">{{selectedItem?.DisplayText}}</h3>
      <h3 *ngIf="selectedItem === null">Select Company</h3>
      <div class="down_icon_new">
        <i class="fas fa-chevron-down"></i>
      </div>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownForm1">

      <input class="form-control" type="text" placeholder="Search.." [(ngModel)]="search" (ngModelChange)="this.searchInputUpdate.next($event)" />

      <div class="list-container" infinite-scroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="300" (scrolled)="onScroll()" (scrolledUp)="onScroll()" [scrollWindow]="false">
        <button ngbDropdownItem *ngFor="let item of dropdownList" (click)="selectItem(item)" class="w-100 customer_detail_container">
          <h3>{{item.DisplayText}}</h3>
        </button>
        <div class="load-more" [ngSwitch]="pagination.CurrentPage < pagination.PageCount">
          <span *ngSwitchCase="true">...loading items...</span>
          <span *ngSwitchCase="false">...all items loaded...</span>
        </div>
      </div>
    </div>
  </div>
</div>
