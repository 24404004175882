<div class="modal-container">
  <div class="modal-header">
      <h4 class="modal-title">Edit Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="img-container">
          <ng-template [ngIf]="!croppedImage">
            <div [style.visibility]="loading ? 'hidden' : 'visible'" [style.background]="canvasFillColor" class="img-container">
              <img #previewimg [src]="previewImageURL" />
            </div>
          </ng-template>
          <ng-template [ngIf]="croppedImage && !loading">
            <div class="cropped-image">
              <img #croppedImg [ngStyle]="{ transform: 'scale(' + zoomIn + ')' }" [src]="croppedImage" />
            </div>
          </ng-template>
        </div>
  </div>
  <div class="modal-footer">
      <div class="cropper-actions">
          <button class="btn btn-primary btn-with-icon" (click)="cropper.rotate(90)"><img class="rotate-right" src="../../assets/icons/rotate-left.svg"></button>
          <button class="btn btn-primary btn-with-icon" (click)="cropper.rotate(-90)"><img src="../../assets/icons/rotate-left.svg"></button>
          <button class="btn btn-primary btn-with-icon" (click)="cropper.zoom(0.1)"><img src="../../assets/icons/magnify-plus.svg"></button>
          <button class="btn btn-danger btn-with-icon" (click)="cropper.zoom(-0.1)"><img src="../../assets/icons/magnify-minus.svg"></button>
          <button class="btn btn-outline-secondary" (click)="cropper.reset()">Reset</button>
          <button class="btn btn-primary" (click)="confirmImage()">Confirm</button>
        </div>
  </div>
</div>



