export enum SocketEnumerations {
  SocketGuid = 1,
  Registration = 2,
  NewMessage = 3,
  MessageRead = 4,
  AuthUserConnected = 5,
  AuthUserDisconnected = 6,
  AllConnectedAuthUsers = 7,
  AuthUserTyping = 8,
  AuthUserStoppedTyping = 9,
  HeartBeat = 10,
  Notification = 11,
  Error = 99
}
