import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DTResult } from 'src/app/models/dt-result';
import { BehaviorSubject } from 'rxjs';
import { ActionRequired, AuthData, SocialLoginBody } from 'src/app/models/authData';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseURL: string = environment.apiUrl;
  authObject$ = new BehaviorSubject<AuthData>(null);
  public socialLoginBody = null as SocialLoginBody;
  authActionsRequired$ = new BehaviorSubject<ActionRequired[]>(null);

  constructor(private http: HttpClient, private router: Router) { }

  loginWithFacebook(body) {
    const url = this.baseURL + 'login/authenticate/user-social-media';
    const headers = new HttpHeaders().set('skip', 'true');
    return this.http.post<DTResult>(url, body, { headers });
  }

  linkSocialMediaAccount(body) {
    const url = this.baseURL + 'myProfile/link-social-media';
    return this.http.post<DTResult>(url, body);
  }

  
  loginWithPhone(phoneNumber, iSDCode) {
    const url =
      this.baseURL + "login/authenticate/user/instance/request-phone-otp";
    const headers = new HttpHeaders().set("skip", "true");
    const params = {
      phoneNumber: phoneNumber,
      iSDCode: iSDCode,
    };
    return this.http.post<DTResult>(url, null, { headers, params });
  }

  loginWithPhoneVerifyOTP(iSDCode, phoneNumber, otp) {
    const url =
      this.baseURL + "login/authenticate/user/instance/validate-phone-otp";
    const headers = new HttpHeaders().set("skip", "true");
    const params = {
      phoneNumber: phoneNumber,
      iSDCode: iSDCode,
      otp: otp,
    };
    return this.http.post<DTResult>(url, null, { headers, params });
  }

  login(loginForm) {
    const url = this.baseURL + 'login/authenticate';
    const headers = new HttpHeaders().set('skip', 'true');
    const body = {
      username: loginForm.Email,
      password: loginForm.Password
    }
    return this.http.post<DTResult>(url, body, { headers });
  }

  logout() {
    localStorage.removeItem('LOGGED_IN_USER');
    localStorage.removeItem('CategoryList');
    this.authObject$.next(null);
    this.router.navigate(['home']);
    // const currentRouteConfig = this.router.config.find((f) => {
    //   let s = this.router.url.substr(1);
    //   let n = s.indexOf('/');
    //   n = s.indexOf('?');
    //   s = s.substring(0, n !== -1 ? n : s.length);
    //   if (f.path.includes(s)) {
    //     return true;
    //   }
    // });
    // if (currentRouteConfig?.canActivate) {
    //   console.log(currentRouteConfig);

    //   this.router.navigate(['browse']);
    // }
  }

  getLoginState() {
    return this.authObject$.asObservable();
  }

  checkIfEmailExists(email: string) {
    const url = this.baseURL + 'instance/validate';
    const httpOptions = {
      headers: new HttpHeaders({}).set('skip', 'true'),
      params: new HttpParams().set('userName', email)
    };
    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(value => {
          let emailExists: any;
          emailExists = value.Data;
          return emailExists;
        })
      );
  }

  checkIfNicknameExists(nickname: string) {
    const url = this.baseURL + 'instance/validate/nickname';
    const httpOptions = {
      headers: new HttpHeaders({}).set('skip', 'true'),
      params: new HttpParams().set('nickname', nickname)
    };
    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(value => {
          let nicknameExists: any;
          nicknameExists = value.Data;
          return nicknameExists;
        })
      );
  }

  validatePhoneNumber(isdcode, phonenumber) {
    const url = this.baseURL + 'phone/validate';
    const httpOptions = {
      headers: new HttpHeaders({}).set('skip', 'true'),
      params: new HttpParams().set('isdcode', isdcode).set('phonenumber', phonenumber)
    };
    return this.http.get<any>(url, httpOptions);
  }

  registerUser(userdata: any) {
    const url = this.baseURL + 'instance/register/otp';
    const headers = new HttpHeaders().set('skip', 'true');
    return this.http.post<DTResult>(url, userdata, { headers });
  }

  activateAccountWithOTP(username: string, otp: string) {
    const url = this.baseURL + 'instance/activate/otp';
    const httpOptions = {
      headers: new HttpHeaders().set('skip', 'true'),
      params: new HttpParams().set('username', username).set('otp', otp)
    };
    return this.http.post<any>(url, null, httpOptions);
  }

  forgotPassword(fpBody) {
    const url = this.baseURL + 'password/forgot';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      skip: 'true'
    });
    return this.http.post<any>(url, fpBody, { headers });
  }

  forgotPasswordOTPValidate(email, otp) {
    const url = this.baseURL + 'password/validate/otp';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true'
      }),
      params: new HttpParams()
        .set('email', email)
        .set('otp', otp)
    }

    return this.http.get<any>(url, httpOptions);
  }

  saveNewPassword(newPasswordBody) {
    const url = this.baseURL + 'password/save/otp';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      skip: 'true'
    });
    return this.http.post<any>(url, newPasswordBody, { headers });
  }

  emailChangeSendOTP(email: string) {
    const url = this.baseURL + 'myProfile/email-reset/send-otp';
    const body = {
      NewEmailAddress: email
    }
    return this.http.post<any>(url, body);
  }

  emailChangeValidateOTP(otp, email) {
    const url = this.baseURL + 'myProfile/email-reset/validate-otp';
    const body = {
      OTP: otp,
      NewEmailAddress: email
    }
    return this.http.post<any>(url, body);
  }

  getCompanies(companyName, pageNumber, pagesize, sort = 'CompanyName ASC') {
    const url = this.baseURL + 'autocompletes/company';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'skip': 'true'
      }),
      params: new HttpParams()
        .set("SearchText", companyName)
        .set("Page", pageNumber)
        .set("Pagesize", pagesize)
        .set("SortCriteria", sort)
    };
    return this.http.get<any>(url, httpOptions);
  }
}
