import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ToastService } from '../../toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    accessToken: string;

    constructor(private router: Router, private authService: AuthService, private toastService: ToastService) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('skip')) {
            return next.handle(request)
                .pipe(
                    catchError(err => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                this.authService.logout();
                                location.reload();
                                // this.toastService.show('Your session has expired. Please login again.', {
                                //     classname: 'bg-danger text-light',
                                //     delay: 5000,
                                //     autohide: true,
                                //     headertext: 'Warning!!'
                                // });
                                return;
                            } else {
                                return throwError('Invalid Http Request. Please try again.');
                            }
                        }
                    })
                );
        } else {
            const AuthUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
            const clonedRequest = request.clone({
                setHeaders: {
                    accessToken: AuthUser?.Data?.AuthResult?.accessToken
                }
            });
            return next.handle(clonedRequest)
                .pipe(
                    map(event => {
                        if (event instanceof HttpResponse) {
                            const response = event as HttpResponse<any>;
                            return response;
                        } else {
                            return event;
                        }
                    }),
                    catchError(err => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                this.authService.logout();
                                // this.toastService.show('Your session has expired. Please login again.', {
                                //     classname: 'bg-danger text-light',
                                //     delay: 5000,
                                //     autohide: true,
                                //     headertext: 'Warning!!'
                                // });
                                this.router.navigate(['browse']);
                                location.reload();
                                return;
                            } else {
                                return throwError('Invalid Http Request. Please try again.');
                            }
                        }
                    }));
        }
    }
}
