import { Component, OnInit, Input } from '@angular/core';
import { slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    slideInUpOnEnterAnimation({duration: 300}),
    slideOutDownOnLeaveAnimation({duration: 300}),
    fadeInOnEnterAnimation({duration: 300}),
    fadeOutOnLeaveAnimation({duration: 300})
  ]
})
export class LoaderComponent implements OnInit {

  @Input() message: string = '';
  @Input() toggleTrigger: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
