import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SocketData } from "./socket-data";
import { SocketEnumerations } from "./socket-enumerations";
import { SocketRegistrationModel } from "./socket-registration-model";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SocketService {
  private websocket: WebSocket;
  socketGuid: string;
  private connected = false;
  private retryCount = 0;

  private socketConnected$ = new Subject<any>();
  public onConnected$ = this.socketConnected$.asObservable();

  private socketDisConnected$ = new Subject<any>();
  public onDisconnected$ = this.socketDisConnected$.asObservable();

  private socketData$ = new Subject<SocketData>();
  public onDataReceived$ = this.socketData$.asObservable();

  private socketError$ = new Subject<any>();
  public onError$ = this.socketError$.asObservable();

  public ConnectToServer() {
    // if (this.connected === false) {
    //   console.log("Connecting to server");
    //   this.websocket = new WebSocket(environment.socketUrl);

    //   this.websocket.onopen = event => {
    //     this.onSocketOpen(event);
    //   };

    //   this.websocket.onclose = event => {
    //     this.onSocketClose(event);
    //   };

    //   this.websocket.onmessage = event => {
    //     this.onSocketMessageRecieved(event);
    //   };

    //   this.websocket.onerror = event => {
    //     this.onSocketError(event);
    //   };
    // }
  }

  public Disconnect() {
    if (this.connected === true) {
      this.websocket.close(1000, "User logged out");
    }
  }

  private onSocketOpen(event: any) {
    this.connected = true;
    this.socketConnected$.next(event);
    console.log("Connected to server");
  }

  private onSocketClose(event: CloseEvent) {
    this.connected = false;
    this.socketDisConnected$.next(event);
    console.log("Disconnected from server - Reason : " + event.reason);
    if (event.reason !== 'Closed by the WebSocketManager') {
      this.retryCount++;
      if (this.retryCount <= 2) {
        this.ConnectToServer();
      }
    }
  }

  private onSocketMessageRecieved(event: any) {
    const socketData = JSON.parse(event.data) as SocketData;
    this.ProcessSocketData(socketData);
  }

  private onSocketError(event: any) {
    this.connected = false;
    this.socketError$.next(event);
    console.log("Error on socket connection");
  }

  public SendDataToServer(data: any) {
    if (this.connected) {
      this.websocket.send(JSON.stringify(data));
    }
  }

  private ProcessSocketData(socketData: SocketData) {
    switch (socketData.TransactionCode) {
      case SocketEnumerations.SocketGuid:
        this.ProcessSocketGuid(socketData);
        break;
      case SocketEnumerations.Registration:
        // this.ProcessSocketRegistration(socketData);
        break;
      case SocketEnumerations.HeartBeat:
        this.ProcessHeartBeat(socketData);
        break;
      default:
        this.SendDataToClient(socketData);
    }
  }

  private ProcessSocketGuid(socketData: SocketData) {
    this.socketGuid = socketData.Data;
    const authUser = JSON.parse(localStorage.getItem('LOGGED_IN_USER'));
    socketData.AccessToken = authUser.Data.AuthResult.accessToken;
    const data: SocketRegistrationModel = new SocketRegistrationModel();
    data.AuthUserId = authUser.Data.AuthResult.authUserId;
    data.SocketGuid = socketData.Data;
    socketData.Data = data;
    socketData.TransactionCode = SocketEnumerations.Registration;
    this.SendDataToServer(socketData);
  }

  private ProcessSocketRegistration(socketData: SocketData) {
    socketData.TransactionCode = SocketEnumerations.AllConnectedAuthUsers;
    socketData.SocketGuid = this.socketGuid;
    this.SendDataToServer(socketData);
  }

  private ProcessHeartBeat(socketData: SocketData) {
    socketData.SocketGuid = this.socketGuid;
    this.SendDataToServer(socketData);
  }

  private SendDataToClient(socketData: SocketData) {
    this.socketData$.next(socketData);
  }
}
