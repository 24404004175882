import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Pagination } from "../models/pagination";

@Component({
    selector: 'app-dropdown-component',
    templateUrl: './dropdown-component.component.html',
    styleUrls: ['./dropdown-component.component.scss']
})

export class DropdownComponent {
    search: string;
    searchInputUpdate = new Subject<string>();
    @Input() selectedItem = null;
    @Input() dropdownList: Array<any>;
    @Input() pagination: Pagination = {
        CurrentPage: 1,
        FirstRowOnPage: 0,
        LastRowOnPage: 0,
        PageCount: 0,
        PageSize: 10,
        RowCount: 0
    }

    @Output() getNextPage = new EventEmitter<number>();
    @Output() searchEvent = new EventEmitter<string>();
    @Output() itemSelectEvent = new EventEmitter<any>();

    constructor() {
        this.searchInputUpdate.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(value => {
            this.searchEvent.emit(value);

        })
    }

    onScroll() {
        if (this.pagination.CurrentPage < this.pagination.PageCount)
            this.getNextPage.emit(this.pagination.CurrentPage + 1);
    }

    selectItem(item) {
        this.selectedItem = item;
        this.itemSelectEvent.emit(item);
    }
}