<div class="modal-container">
  <!-- <app-loader [message]="" [toggleTrigger]="formLoading"></app-loader> -->
  <div class="modal-header">
    <h4 class="modal-title">Reset your Account Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <aw-wizard navBarLocation="">
    <aw-wizard-step>
      <div class="modal-body">
        <form [formGroup]="forgotPasswordForm">
          <div class="form-group">
            <label>Registered Email Address</label>
            <input class="form-control" type="text" placeholder="Registered email address" formControlName="email" />
            <ngb-progressbar type="success" [value]="100" [striped]="true" [animated]="true" height="10px" *ngIf="forgotPasswordF.email.status === 'PENDING'"></ngb-progressbar>
          </div>

          <!-- <div class="form-group" *ngIf="forgotPasswordF.VerificationChannel.enabled" [@zoomInOnEnter]>
                        <label class="checkbox-label">
                            <span class="p-16">Text a verification code to {{existingPhone.value}}</span>
                            <div class="text-muted small">(Fraileys Industrial will text a verification code to your mobile.
                                Standard rates apply)</div>
                            <input type="checkbox" formControlName="VerificationChannel">
                            <span class="checkmark"></span>
                        </label>

                        <label class="checkbox-label">
                            <span class="p-16">Text a verification code to {{forgotPasswordF.email.value}}</span>
                            <div class="text-muted small">(Fraileys Industrial will text a verification code to your E-mail)</div>
                            <input type="checkbox" formControlName="VerificationChannel">
                            <span class="checkmark"></span>
                        </label>
                    </div> -->

          <div class="form-group d-flex" *ngIf="forgotPasswordF.VerificationChannel.enabled" [@zoomInOnEnter]>
            <label class="mr-4">Send verification code to:</label>
            <label class="radio-label"><span class="mr-3">SMS</span>
              <input type="radio" name="VerificationChannel" value="2" formControlName="VerificationChannel" />
              <span class="radiomark"></span>
            </label>
            <label class="radio-label"><span class="p-16">Email</span>
              <input type="radio" name="VerificationChannel" value="1" formControlName="VerificationChannel" />
              <span class="radiomark"></span>
            </label>
          </div>
        </form>
        <ngb-alert type="danger" *ngFor="let error of fpErrors; let i = index" (close)="closeError(error)">
          {{ error }}</ngb-alert>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="backToLogin()">
          Back to Login
        </button>
        <button type="submit" class="btn btn-primary" (click)="goToResetPasswordScreen(false, null)" [disabled]="this.forgotPasswordF.email.errors">
          Next
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step>
      <div class="modal-body otp-body d-flex flex-column jusify-content-start align-items-center">
        <span *ngIf="forgotPasswordF.VerificationChannel.value === '1'">Please enter the verification code we have sent on your email -
          {{ EmailId }}</span>
        <span *ngIf="forgotPasswordF.VerificationChannel.value === '2'">Please enter the code we have sent to {{ existingPhone.value }}</span>
        <div class="opt-container d-flex jusify-content-center align-items-center my-3">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6, allowNumbersOnly: true }">
          </ng-otp-input>
        </div>
        <ngb-alert type="danger" *ngFor="let error of fpErrors; let i = index" (close)="closeError(error)">
          {{ error }}</ngb-alert>
      </div>

      <div class="modal-footer d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-column justify-content-start resend-code-container">
          <a class="theme-link" [ngClass]="{ disabled: forgotPasswordOTP.resendDisabled }" (click)="resendOTP(null)">Resend Code
            <span *ngIf="forgotPasswordOTP.resendOTPTimer !== 30">({{ forgotPasswordOTP.resendOTPTimer }})</span></a>

          <a class="resend-link text-small" (click)="resendOTP('2')" *ngIf="
              !forgotPasswordOTP.resendDisabled &&
              forgotPasswordF.VerificationChannel.value === '1'
            ">Resend to {{ existingPhone.value }}?</a>
          <a class="resend-link text-small" (click)="resendOTP('1')" *ngIf="
              !forgotPasswordOTP.resendDisabled &&
              forgotPasswordF.VerificationChannel.value === '2'
            ">Resend to {{ EmailId }}?</a>
        </div>
        <div>
          <button type="button" class="btn btn-outline-dark mr-2" [awGoToStep]="{ stepIndex: 0 }">
            Back
          </button>
          <button type="button" class="btn btn-primary" [disabled]="forgotPasswordOTP.value.length !== 6" (click)="forgotPasswordOTPSubmit()">
            Submit
          </button>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step>
      <div class="modal-body">
        <form [formGroup]="forgotPasswordForm">
          <div class="form-group">
            <label>New Password *</label>
            <div class="input-group">
              <input [type]="showPassword.password ? 'text' : 'password'" formControlName="password" class="form-control" placeholder="Enter a password" [ngClass]="{
                  'is-invalid':
                    forgotPasswordF.password.touched &&
                    forgotPasswordF.password.errors
                }" />
              <div class="input-group-append" (click)="showPassword.password = !showPassword.password">
                <span class="input-group-text show-pwd">
                  <img [src]="
                      showPassword.password
                        ? '../../assets/icons/eye-closed-1.svg'
                        : '../../assets/icons/eye-open-1.svg'
                    " />
                </span>
              </div>
              <div class="invalid-feedback" *ngIf="forgotPasswordF.password.errors">
                <span *ngIf="forgotPasswordF.password.errors.required">Please choose a password</span>
                <span *ngIf="forgotPasswordF.password.errors.minlength">Password must be atleast 8 characters long</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Repeat Password *</label>
            <div class="input-group">
              <input [type]="showPassword.confirmPassword ? 'text' : 'password'" formControlName="confirmPassword" class="form-control" placeholder="Repeat the password" [ngClass]="{
                  'is-invalid':
                    forgotPasswordF.confirmPassword.touched &&
                    forgotPasswordForm.errors
                }" />
              <div class="input-group-append" (click)="
                  showPassword.confirmPassword = !showPassword.confirmPassword
                ">
                <span class="input-group-text show-pwd">
                  <img [src]="
                      showPassword.confirmPassword
                        ? '../../assets/icons/eye-closed-1.svg'
                        : '../../assets/icons/eye-open-1.svg'
                    " />
                </span>
              </div>
            </div>
          </div>
          <div class="password-match-error" *ngIf="
              forgotPasswordF.password.touched &&
              forgotPasswordF.confirmPassword.touched &&
              forgotPasswordForm.errors !== null
            ">
            Passwords should match
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark mr-2" [awGoToStep]="{ stepIndex: 1 }">
          Back
        </button>
        <button type="button" class="btn btn-primary" [disabled]="forgotPasswordForm.invalid" (click)="forgotPasswordSaveNew()">
          Submit
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step>
      <div class="modal-body">
        <span class="success-msg">Congratulations!! Your password has been successfully reset.</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark mr-2" (click)="activeModal.dismiss('Close clicked')">
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="backToLogin()">
          Go to Login
        </button>
      </div>
    </aw-wizard-step>
  </aw-wizard>
</div>
