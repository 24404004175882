import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, startWith, tap, map, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Category } from 'src/app/models/category';
import { DTResult } from 'src/app/models/dt-result';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppProperty } from 'src/app/models/app-property';
import { SocialMedia } from 'src/app/models/social-media';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  baseUrl: string = environment.apiUrl;
  extendedNavBar$ = new BehaviorSubject<boolean>(true);
  showSearchBar$ = new BehaviorSubject<boolean>(false);
  showMobileSearch$ = new BehaviorSubject<boolean>(false);
  showRefineSearch$ = new BehaviorSubject<boolean>(true);
  categoriesData$ = new BehaviorSubject<Category[]>(null);
  preferenceData$ = new BehaviorSubject<any>(null);
  AdType$ = new BehaviorSubject<any>(null);
  loaderModal: NgbModalRef;
  isLoaderOpen = false;
  appProperties$ = new BehaviorSubject<AppProperty[]>(null);
  enabledSocialMedias$ = new BehaviorSubject<SocialMedia[]>(null);


  constructor(private http: HttpClient, private modalService: NgbModal) {}

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  getAppProperties() {
    const url = this.baseUrl + 'app/properties';
    const headers = new HttpHeaders().set('skip', 'true');
    this.http
      .get<DTResult>(url, { headers })
      .subscribe((response: DTResult) => {
        if (response.State) {
          this.appProperties$.next(response.Data);
        }
      });
  }

  getEnabledSocialMedia() {
    const url = this.baseUrl + 'app/social-media-logins';
    const headers = new HttpHeaders().set('skip', 'true');
    this.http
      .get<DTResult>(url, { headers })
      .subscribe((response: DTResult) => {
        if (response.State) {
          this.enabledSocialMedias$.next(response.Data);
        }
      });
  }

  getAllMyCategories() {
    const url = this.baseUrl + 'categories/';

    const headers = new HttpHeaders().set('skip', 'true');
    return this.http.get<DTResult>(url, { headers });
  }

  getAllCategories() {
    const url = this.baseUrl + 'categories/';

    const headers = new HttpHeaders().set('skip', 'true');
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     skip: 'true'
    //   }),
    //   params: new HttpParams()
    //     .set('activeYN', true.toString())
    // };

    this.http
      .get<DTResult>(url, { headers } )
      .subscribe(response =>{
        localStorage.setItem('CategoryList',JSON.stringify(response.Data))
        this.categoriesData$.next(response.Data)
      });
  }

  getAllWatchListCategories():Observable<any> {
    const url = this.baseUrl + 'categories/';

    const headers = new HttpHeaders().set('skip', 'true');
    return this.http.get<DTResult>(url, { headers });
  }

  getUserPreference() {
    const url = this.baseUrl + 'watchlist';
    return this.http.get<DTResult>(url);
  }

  getAllStates() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        skip: 'true'
      })
    };
    const url = this.baseUrl + 'zipcode/states';
    return this.http.get<any>(url, httpOptions);
  }

  getCitiesinState(stateName, page, pageSize) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true'
      }),
      params: new HttpParams()
        .set('stateName', stateName)
        .set('page', page)
        .set('pageSize', pageSize)
    };
    const url = this.baseUrl + 'zipcode/cities';
    return this.http.get<any>(url, httpOptions);
  }

  toggleRefineSearch() {
    if (this.showRefineSearch$.getValue()) {
      this.showRefineSearch$.next(false);
    } else {
      this.showRefineSearch$.next(true);
    }
  }

  submitContactForm(formBody) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true'
      })
    };
    const url = this.baseUrl + 'contactus';
    return this.http.post<any>(url, formBody, httpOptions);
  }

  toggleMobileSearchPanel(bool) {
    if (bool) {
      this.showMobileSearch$.next(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      this.showMobileSearch$.next(false);
    }
  }

  processDynamicFormFields(dynamicFields: any, fields, model, subjectFn , disableYN, presentListing?) {
    
    let parentDDL: any = {};
    let childDDL: any = {};
    dynamicFields.forEach(item => {      
      if (item.ParentItemId === null) {
        parentDDL = item;
      } else if (item.ParentItemId !== null && item.ParentItemId !== item.ItemId) {
        childDDL = item;
      }
      switch (item.FieldType) {
        case 'DDL': {
          fields = [
            ...fields,
            {
              key: item.ItemName,
              type: 'select',
              className: 'col-lg-6 col-md-12 col-sm-12',
              templateOptions: {
                label: item.FieldLabel,
                placeholder: 'Choose',
                options: [],
                valueProp: 'LOVId',
                labelProp: 'DisplayText',
                disabled: disableYN,
                required: item.RequiredYN,
                change: field => {
                  field.templateOptions.options.forEach(option => {
                    const control = field.formControl;
                    if (control._pendingValue === option.LOVId) {
                      model[field.key] = option.Val;
                    }
                  });
                }
              },
              validation: {
                messages: {
                  required: item.Hint
                }
              },
              hooks: {
                onInit: field => {
                  if (item.ParentItemId === null || item.ParentItemId === item.ItemId) {
                    field.templateOptions.options = item.LOV;
                    if (presentListing) {
                      presentListing.ItemData.forEach(existingItem => {
                        if (existingItem.ItemName === field.key) {
                          field.templateOptions.options.filter(option => {
                            if (option.DisplayText === existingItem.SelectedValue) {
                              const control = field.formControl;
                              control.setValue(option.LOVId);
                              model[field.key] = option.Val;
                            }
                          });
                        }
                      });
                    }
                  } else {
                    const lovOptions = item.LOV;
                    const form = field.parent.formControl;
                    form
                      .get(parentDDL.ItemName)
                      .valueChanges.pipe(
                        takeUntil(subjectFn),
                        startWith(form.get(parentDDL.ItemName).value),
                        tap(LovId => {
                          field.formControl.setValue(null);
                          field.templateOptions.options = lovOptions.filter(itemLov => itemLov.ParentLOVId === LovId);
                        })
                      )
                      .subscribe();
                    if (presentListing) {
                      presentListing.ItemData.forEach(existingItem => {
                        if (existingItem.ItemName === field.key) {
                          field.templateOptions.options.filter(option => {
                            if (option.DisplayText === existingItem.SelectedValue) {
                              const control = field.formControl;
                              control.setValue(option.LOVId);
                              model[field.key] = option.Val;
                            }
                          });
                        }
                      });
                    }
                  }
                }
              }
            }
          ];
          break;
        }
        case 'TEXT': {
          fields = [
            ...fields,
            {
              key: item.ItemName,
              type: 'input',
              className: 'col-lg-12 col-md-12 col-sm-12',
              templateOptions: {
                label: item.FieldLabel,
                type: 'text',
                required: item.RequiredYN,
                placeholder: 'Enter ' + item.FieldLabel,
                disabled : disableYN
              },
              validation: {
                messages: {
                  required: item.Hint
                }
              },
              hooks: {
                onInit: field => {
                  if (presentListing) {
                    presentListing.ItemData.forEach(existingItem => {
                      if (existingItem.ItemName === field.key) {
                        const control = field.formControl;
                        control.setValue(existingItem.SelectedValue);
                        model[field.key] = existingItem.SelectedValue;
                      }
                    });
                  }
                }
              }
            }
          ];
          break;
        }
        case 'NUMBER': {
          fields = [
            ...fields,
            {
              key: item.ItemName,
              type: 'input',
              className: 'col-lg-6 col-md-12 col-sm-12',
              templateOptions: {
                label: item.FieldLabel,
                type: 'number',
                required: item.RequiredYN,
                min: item.MinRangeVal !== null || item.MinRangeVal !== undefined || item.MinRangeVal !== 'NULL' ? item.MinRangeVal : '',
                max: item.MaxRangeVal !== null || item.MaxRangeVal !== undefined || item.MaxRangeVal !== 'NULL' ? item.MaxRangeVal : '',
                placeholder: 'Enter ' + item.FieldLabel,
                disabled : disableYN
              },
              validation: {
                messages: {
                  required: item.Hint,
                  min: (error, field) => 'Please enter a value above ' + item.MinRangeVal,
                  max: (error, field) => 'Please enter a value below ' + item.MaxRangeVal
                }
              },
              hooks: {
                onInit: field => {
                  if (presentListing) {
                    presentListing.ItemData.forEach(existingItem => {
                      if (existingItem.ItemName === field.key) {
                        const control = field.formControl;
                        control.setValue(existingItem.SelectedValue);
                        model[field.key] = existingItem.SelectedValue;
                      }
                    });
                  }
                }
              }
            }
          ];
          break;
        }
        case 'TEXTAREA': {
          fields = [
            ...fields,
            {
              key: item.ItemName,
              type: 'textarea',
              className: 'col-lg-12 col-md-12 col-sm-12',
              templateOptions: {
                label: item.FieldLabel,
                required: item.RequiredYN,
                rows: 4,
                placeholder: 'Enter ' + item.FieldLabel,
                disabled : disableYN
              },
              validation: {
                messages: {
                  required: item.Hint
                }
              },
              hooks: {
                onInit: field => {
                  if (presentListing) {
                    presentListing.ItemData.forEach(existingItem => {
                      if (existingItem.ItemName === field.key) {
                        const control = field.formControl;
                        control.setValue(existingItem.SelectedValue);
                        model[field.key] = existingItem.SelectedValue;
                      }
                    });
                  }
                }
              }
            }
          ];
          break;
        }
        case 'DATE': {
          fields = [
            ...fields,
            {
              key: item.ItemName,
              type: 'input',
              className: 'col-lg-6 col-md-12 col-sm-12',
              templateOptions: {
                type: 'date',
                required: item.RequiredYN,
                label: item.FieldLabel,
                disabled : disableYN
              },
              validation: {
                messages: {
                  required: item.Hint
                }
              }
            }
          ];
          break;
        }
      }
    });
    return fields;
  }

  // public showLoader() {
  //     if (!this.isLoaderOpen) {
  //       this.loaderModal = this.modalService.open(NewLoaderComponent, {
  //         size: 'sm',
  //         centered: true,
  //         windowClass: 'animated-modal loader-modal',
  //         backdrop: 'static'
  //       });
  //       this.isLoaderOpen = true;
  //     }
  // }

  // public dismissLoader() {
  //   this.loaderModal.close();
  //   this.isLoaderOpen = false;
  // }

}
