import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { DTResult } from "src/app/models/dt-result";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { Search } from "src/app/models/search";
import { AdStatus } from "src/app/enumerations/AdStatus";
import { Ad } from "src/app/models/ad";
import { AdType } from "src/app/enumerations/AdType";

@Injectable({
  providedIn: "root",
})
export class AdsService {
  baseURL: string = environment.apiUrl;
  searchSubject$ = new BehaviorSubject<Search>({
    TriggerSearch: true,
    SearchText: "",
    CategoryIds: [],
    SubCategoryIds: [],
    Locations: [],
    State: "",
    MinPrice: null,
    MaxPrice: null,
    ListingTypeEnumIds: [AdType.STANDARD],
    PostDateHours: null,
    Page: 1,
    PageSize: 12,
    SortCriteria: "Recent DESC",
  });
  searchRefreshYN$ = new BehaviorSubject<boolean>(false);
  // This is to persists suggested cities. Especially on mobile phones as the props on the search component where
  // getting reset when search overlay was closed when the user searches for anything on mobile and ngIf causing search component to destroy
  selectedSuggestedCitiesSubject$ = new BehaviorSubject<any>([]);

  adToEdit = null as Ad;

  eventEmitter: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) { }

  getAllListingsViaCategory(searchBody: Search) {
    const subCategoryUrl = this.baseURL + "listings/search";
    const httpOptions = {
      headers: new HttpHeaders({}).set("skip", "true"),
    };
    return this.http
      .post<DTResult>(subCategoryUrl, searchBody, httpOptions)
      .pipe(map((resp: any) => resp));
  }

  getDynamicForm(id: string) {
    const url = this.baseURL + "subcategories/" + id;
    const headers = new HttpHeaders().set("skip", "true");
    return this.http.get(url, { headers });
  }

  uploadImage(fileData) {
    const url = this.baseURL + "attachment";
    const headers = new HttpHeaders().set("skip", "true");
    const formData = new FormData();
    formData.append("file", fileData);
    return this.http
      .post(url, formData, {
        headers,
        responseType: "text",
        reportProgress: true,
        observe: "events",
      })
      .pipe(map((resp: any) => resp));
  }

  submitListing(formData, accessToken) {
    const url = this.baseURL + "listings";
    const headers = new HttpHeaders({
      accessToken,
    });
    return this.http
      .post<any>(url, formData, { headers })
      .pipe(map((resp: any) => resp));
  }

  updateListing(listingId, listingNewData) {
    const url = this.baseURL + "listings/" + listingId;
    const httpOptions = {
      params: new HttpParams().set("listingId", listingId),
    };
    return this.http.post<any>(url, listingNewData, httpOptions);
  }

  getAdCompleteData(listingId) {
    const url = this.baseURL + "listings/" + listingId;
    return this.http.get<DTResult>(url);
  }

  getAdPublicData(listingId) {
    const url = this.baseURL + "listings/public/" + listingId;
    const headers = new HttpHeaders({}).set("skip", "true");
    return this.http
      .get<DTResult>(url, { headers })
      .pipe(map((resp: any) => resp));
  }

  getRelatedAds(listingId, pageNumber, pageSize, authUserId) {
    const url = this.baseURL + "listings/" + listingId + "/related";
    const headers = new HttpHeaders({}).set("skip", "true");
    const params = new HttpParams({})
      .set("listingId", listingId)
      .set("page", pageNumber)
      .set("pageSize", pageSize)
      .set("authUserId", authUserId);
    return this.http.post<any>(url, null, { headers, params });
  }

  getMyAdsCount() {
    const url = this.baseURL + "listings/myrequestorder/count";
    return this.http.get<DTResult>(url);
  }

  getPublisherListings(
    pageNumber: number,
    pageSize: number,
    withMessageOnly: boolean,
    sortCriteria: string
  ) {
    const authUserId = JSON.parse(localStorage.getItem("LOGGED_IN_USER")).Data
      .AuthResult.authUserId;
    const url = this.baseURL + "listings/" + authUserId + "/published";
    const params = new HttpParams({})
      .set("page", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      .set("withMessageOnly", withMessageOnly.toString())
      .set("sortCriteria", sortCriteria)
      .set("authUserId", authUserId.toString());
    return this.http.get<DTResult>(url, { params });
  }

  getSubscriberListings(
    pageNumber: number,
    pageSize: number,
    withMessageOnly: boolean,
    sortCriteria: string
  ) {
    const url = this.baseURL + "listings/subscribed";
    const params = new HttpParams({})
      .set("page", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      .set("withMessageOnly", withMessageOnly.toString())
      .set("sortCriteria", sortCriteria);

    return this.http.get<DTResult>(url, { params });
  }

  getArchivedListings(
    pageNumber: number,
    pageSize: number,
    sortCriteria: string
  ) {
    const url = this.baseURL + "listings/archived";
    const params = new HttpParams({})
      .set("page", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      .set("sortCriteria", sortCriteria);
    return this.http.get<DTResult>(url, { params });
  }

  getPendingListings(
    pageNumber: number,
    pageSize: number,
    sortCriteria: string
  ) {
    const url = this.baseURL + "listings/pending";
    const params = new HttpParams({})
      .set("page", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      .set("sortCriteria", sortCriteria);
    return this.http.get<DTResult>(url, { params });
  }

  updateListingStatus(listingId: number, statusId: any) {
    const url = this.baseURL + "listings/" + listingId;
    const params = new HttpParams({})
      .set("listingId", listingId.toString())
      .set("statusid", statusId.toString())
      .set("sendSMSNotification", "false");
    return this.http.put<DTResult>(url, null, { params });
  }

  updateListingExceptionStatus(listingId: number) {
    const url =
      this.baseURL + "watchlist/" + listingId + "/following-exception";
    const params = new HttpParams({}).set("listingId", listingId.toString());
    return this.http.post<DTResult>(url, null, { params });
  }

  unsubscribeAd(listingId: number) {
    const url = this.baseURL + "listings/" + listingId + "/unsubscribe";
    const params = new HttpParams().set("listingId", listingId.toString());
    return this.http.post<DTResult>(url, null, { params });
  }

  saveUserPreference(preferenceBody) {
    const url = this.baseURL + "watchlist";
    return this.http.post<any>(url, preferenceBody);
  }

  toggleWatchlist(bool) {
    const url = this.baseURL + "watchlist";
    const params = new HttpParams().set("activeYN", bool);
    return this.http.put<any>(url, null, { params });
  }

  getFollowingListings(pageNumber, pageSize, sortCriteria) {
    const url = this.baseURL + "listings/following";
    const params = new HttpParams()
      .set("page", pageNumber)
      .set("pageSize", pageSize)
      .set("sortCriteria", sortCriteria);
    return this.http.get<any>(url, { params });
  }

  searchLocationSuggestions(searchCriteria, suggestZipCodeForCityYN) {
    if (searchCriteria === "") {
      return of([]);
    }
    const url = this.baseURL + "zipcode";
    const params = new HttpParams()
      .set("searchCriteria", searchCriteria)
      .set("suggestZipCodeForCityYN", suggestZipCodeForCityYN);
    const headers = new HttpHeaders().append("skip", "true");
    return this.http
      .get<any>(url, { headers, params })
      .pipe(map((response) => response.Data));
  }

  getCityInfoFromZip(value): Observable<any> {
    const searchParams = new HttpParams().set("zipcode", value.toString());
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        skip: "true",
      }),
      params: searchParams,
    };
    const url = this.baseURL + "zipcode/" + value;
    return this.http.get<any>(url, httpOptions);
  }

  getAdTypeEnums() {
    const url = this.baseURL + "enums/listing-types";
    const headers = new HttpHeaders().append("skip", "true");
    return this.http.get<any>(url, { headers });
  }


  getPendingRequests(
    pageNumber: number,
    pageSize: number,
    sortCriteria: string,
    searchText:string = ''
  ) {
    const url = this.baseURL + 'listings/my-request';
    const params = new HttpParams({})
      .set("page", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      .set("sortCriteria", sortCriteria)
      .set("searchText", searchText)
    return this.http.get<DTResult>(url, { params });
  }

  getRejectedRequests(pageNumber: number,
    pageSize: number,
    sortCriteria: string,
    searchText:string = '') {
    const url = this.baseURL + 'listings/my-rejected-request';
    const params = new HttpParams({})
      .set("page", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      .set("sortCriteria", sortCriteria)
      .set("searchText", searchText)
    return this.http.get<DTResult>(url, { params });
  }


  getOrdersRequests(
    pageNumber: number,
    pageSize: number,
    OrderStatusEnumId: any = null,
    SearchText = ''
  ) {
    const url = this.baseURL + 'orders';
    const params = new HttpParams({})
      .set("PageNo", pageNumber.toString())
      .set("PageSize", pageSize.toString())
      .set("OrderStatusEnumId", OrderStatusEnumId)
      .set("SearchText", SearchText)
    return this.http.get<DTResult>(url, { params });
  }

  getUsersList(
    start: number,
    pagesize: number,        
  ) {
    const url = this.baseURL + 'users/list';
    const params = new HttpParams({})
      .set("start", start.toString())
      .set("pagesize", pagesize.toString())        
    return this.http.get<DTResult>(url, { params });
  }

  updateOrder(orderId, orderBody: any) {
    const url = this.baseURL + `orders/${orderId}/update`;
    return this.http.put<DTResult>(url, orderBody);
  }

  getordersresult(orderId){
    const url = this.baseURL + `orders/${orderId}/complete`;
    return this.http.patch<DTResult>(url, null);
  }

  // public checkUserEmail(emailAddress: string) {
  //   const url = this.baseURL + 'users/email/validate';
  //   const searchParams = new HttpParams().set('emailAddress', emailAddress);
  //   return this.http.get<DTResult>(url, { params });
  // }

  checkUserEmail(
    emailAddress: string       
  ) {
    const url = this.baseURL + 'users/email/validate';
    const params = new HttpParams({})
      .set("emailAddress", emailAddress)          
    return this.http.get<DTResult>(url, { params });
  }

  
  // public saveUser(user: any): Observable<any> {
  //   return this.apiService.Post('users', user);
  // }

  
  saveUser(preferenceBody) {
    const url = this.baseURL + "users";
    return this.http.post<any>(url, preferenceBody);
  }

  patchUserDetails(ActiveYN, authUserId) {
    const url = this.baseURL + `users/${authUserId}/active`;
    const params = new HttpParams({})
    .set("ActiveYN", ActiveYN) 
    return this.http.patch<any>(url, null, {params});
  }

  deleteUser(preferenceBody) {
    const url = this.baseURL + "users";
    return this.http.delete<any>(url, preferenceBody);
  }

  raiseRequest(listingId, body){
    const url = this.baseURL + `listings/${listingId}/create-listing-product`;
    return this.http.post<any>(url,body)
  }

}
