import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { DropdownComponent } from "./dropdown-component.component";

@NgModule({
    declarations: [DropdownComponent],
    imports: [
        CommonModule,
        InfiniteScrollModule,
        FormsModule,
        NgbDropdownModule
    ],
    exports: [DropdownComponent]
})

export class DropdownModule { }