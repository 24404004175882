import { DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NgbAlertModule,
  NgbModule,
  NgbProgressbarModule,
} from "@ng-bootstrap/ng-bootstrap";
// import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
// import { FormlyModule } from '@ngx-formly/core';
import { ArchwizardModule } from "angular-archwizard";
import { NgOtpInputModule } from "ng-otp-input";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
// import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import { SwiperModule } from 'ngx-swiper-wrapper';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoaderModule } from "./loader/loader.module";
import { LoginComponent } from "./login/login.component";
import { NavbarModule } from "./navbar/navbar.module";
import { NewLoaderComponent } from "./new-loader/new-loader.component";
import { SharedModule } from "./services/directives/shared.module";
import { TokenInterceptor } from "./services/guards/auth-interceptor.service";
import { AuthguardService } from "./services/guards/authguard.service";
import { SignupComponent } from "./signup/signup.component";

import { ToastModule } from "./toast/toast.module";
import { ToastService } from "./toast/toast.service";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
} from "ngx-ui-loader";
import { DropdownModule } from "./dropdown-component/dropdown-component.module";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { ImageEditorModule } from "./image-editor/image-editor.module";
import { TermsComponent } from "./terms/terms.component";

const LoaderConfig: NgxUiLoaderConfig = {
  logoUrl: "../../assets/icons/Bns-Loader.gif",
  fgsSize: 0,
  pbColor: "#0DAD26",
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    TermsComponent,
    NewLoaderComponent,
    ImageViewerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    LoaderModule,
    ToastModule,
    ArchwizardModule.forRoot(),
    NgOtpInputModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    SharedModule,
    NavbarModule,
    NgbProgressbarModule,
    NgbAlertModule,
    NgxUiLoaderModule.forRoot(LoaderConfig),
    // NgxUiLoaderRouterModule,
    ImageEditorModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude: [
        "http://fraileyindustrial.api.uat.decisiont.com/v1/zipcode",
        "http://fraileyindustrial.api.uat.decisiont.com/v1/messages",
        "http://fraileyindustrial.api.uat.decisiont.com/v1/instance/validate",
        // "http://fraileyindustrial.api.uat.decisiont.com/v1/phone/validate",
        "http://fraileyindustrial.api.uat.decisiont.com/v1/attachment",
        "http://fraileyindustrial.api.uat.decisiont.com/v1/autocompletes/company",
        "http://fraileyindustrial.api.uat.decisiont.com/v1/orders",
        "http://fraileyindustrial.api.uat.decisiont.com/v1/listings/my-request",
        "https://api.bnsglobal.io/v1/zipcode",
        "https://api.bnsglobal.io/v1/messages",
        "https://api.bnsglobal.io/v1/instance/validate",
        "https://api.bnsglobal.io/v1/phone/validate",
        "https://api.bnsglobal.io/v1/attachment",
      ],
    }),
    DropdownModule,
    NgbModule
  ],
  providers: [
    DatePipe,
    AuthguardService,
    ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  exports: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    TermsComponent,
    NewLoaderComponent,
    ToastModule,
    ImageViewerComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
