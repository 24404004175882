import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbActiveModal, NgbCollapseModule, NgbDropdownModule, NgbModalModule, NgbPopoverModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbPopoverModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgxIntlTelInputModule,
    NgbProgressbarModule,
    LoaderModule,
  ],
  exports: [NavbarComponent],
  providers: [NgbActiveModal]
})
export class NavbarModule { }
