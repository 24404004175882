import { Component, Input, OnInit } from '@angular/core';
// import { MAT_DIALOG_DATA } from '@angular/material';
// import { DialogBoxData } from '../dialog-box/dialog-box.component';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  @Input() public data;

  showIndex = 0;
  loading = true;

  constructor() { }

  ngOnInit() {
    // console.log(this.data)
  }

  showPreviousImage() {
    this.loading = true;
    if (this.showIndex == 0) {
      this.showIndex = this.data.Attachments.length - 1
    } else {
      this.showIndex = this.showIndex - 1
    }
    console.log(this.showIndex)
  }

  showNextImage() {
    this.loading = true;
    if (this.showIndex < this.data.Attachments.length - 1) {
      this.showIndex = this.showIndex + 1
    } else {
      this.showIndex = 0
    }  
  }

  onLoad(event) {
    this.loading = false;
  }

}
