import { Component } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { SocketService } from "./services/socket/socket.service";

// declare let gtag: Function;
// declare let fbq: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "MyGig";
  constructor() {
    if (localStorage.getItem("LOGGED_IN_USER") !== null) {
      // this.socketService.ConnectToServer();
    }

    // Analytics tracking code - Should be enabled for Prod deployment
    // router.events.subscribe((navigation: NavigationEnd) => {
    //   if (navigation instanceof NavigationEnd) {
    //     gtag('config', 'UA-{ID}', { 'page_path': navigation.url });
    //     fbq('track', 'PageView');
    //   }
    // });
  }
}
