import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { AuthService } from "../services/auth/auth.service";
import { SocketService } from "../services/socket/socket.service";
import { CoreService } from "../services/core/core.service";
import { Subscription } from "rxjs";
import { DTResult } from "../models/dt-result";
import { SocialMedia, SocialMediaEnum } from "../models/social-media";
import { ActionRequiredEnum, AuthData } from "../models/authData";
import { SignupComponent } from "../signup/signup.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "../toast/toast.service";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { AdsService } from "../services/ads/ads.service";
import { NgOtpInputComponent } from "ng-otp-input/lib/components/ng-otp-input/ng-otp-input.component";
declare var FB: any;
declare const gapi: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  loginForm: FormGroup;
  phoneLoginForm: FormGroup;
  showPhoneLogin = true;
  showPhoneLoginOTP = false;
  formLoading = false;
  formSubmitted = false;
  formErrors = [];
  facebookEnabled = false;
  googleEnabled = false;
  GoogleClientId: string;
  public auth2: any;
  private scope = ["profile", "email"].join(" ");
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  redirectPath: string = null;
  showOtp = true;
  loginOTP = {
    Config: {
      length: 6,
      allowNumbersOnly: true,
    },
    value: "",
    isValid: false,
    resendDisabled: false,
    resendOTPTimer: 30,
  };

  public submitPlaceAdForm = false;
  sendOtp: boolean = false;

  @ViewChild('otpInputField') otpInputField: NgOtpInputComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private socketService: SocketService,
    public coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toast: ToastService,
    private adsService: AdsService
  ) {
    this.loginForm = this.formBuilder.group({
      Email: new FormControl("", Validators.compose([Validators.required])),
      Password: new FormControl("", Validators.compose([Validators.required])),
    });
    this.phoneLoginForm = this.formBuilder.group({
      Phone: new FormControl("", Validators.compose([Validators.required])),
      Password: new FormControl("", Validators.compose([Validators.required])),
    });

    this.subscriptions.push(
      this.loginForm.valueChanges.subscribe((_) => {
        this.formErrors = [];
      })
    );
    this.subscriptions.push(
      this.phoneLoginForm.valueChanges.subscribe((_) => {
        this.formErrors = [];
      })
    );
  }

  ngOnInit(): void {
    this.phoneLoginF.Phone.statusChanges.subscribe((value) => {
      this.showPhoneLoginOTP = false;
      this.formErrors = [];
      if (value === "VALID") {
        // console.log(this.phoneLoginF.Phone.value);
        this.sendOtp = true;
        // const phoneNumber = this.phoneLoginF.Phone.value.number
        //   .replaceAll(" ", "")
        //   .replaceAll("-", "")
        //   .trim();
        // this.authService
        //   .loginWithPhone(s
        //     phoneNumber,
        //     this.phoneLoginF.Phone.value.dialCode.replace(" ", "")
        //   )
        //   .subscribe(
        //     (res) => {
        //       if (res.State) {
        //         this.showPhoneLoginOTP = true;
        //       } else {
        //         this.formErrors = res.Messages;
        //       }
        //     },
        //     (err) => {
        //       this.formErrors.push(err);
        //     }
        //   );
      } else {
        this.sendOtp = false;
        this.showOtp = true;
      }
    });
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.coreService.enabledSocialMedias$.subscribe((socialMedias) => {
        if (socialMedias !== null) {
          let FacebookApiUrl = null;
          let FacebookAppId = null;
          let FacebookAppVersion = null;
          this.subscriptions.push(
            this.coreService.appProperties$.subscribe(async (value: any) => {
              if (value !== null) {
                await value.map((property) => {
                  switch (property.Property) {
                    case "FacebookAppId": {
                      FacebookAppId = property.Value;
                      break;
                    }
                    case "FacebookAppVersion": {
                      FacebookAppVersion = property.Value;
                      break;
                    }
                    case "FacebookApiURL": {
                      FacebookApiUrl = property.Value;
                      break;
                    }
                    case "GoogleClientId": {
                      this.GoogleClientId = property.Value;
                      break;
                    }
                  }
                });
                if (
                  socialMedias.find(
                    (elem) =>
                      elem.SocialMediaTypeEnumId === SocialMediaEnum.FACEBOOK
                  ) !== undefined
                ) {
                  this.facebookEnabled = true;
                  this.initialiseFbLibrary(
                    FacebookApiUrl,
                    FacebookAppId,
                    FacebookAppVersion
                  );
                } else {
                  this.facebookEnabled = false;
                }

                if (
                  socialMedias.find(
                    (elem) =>
                      elem.SocialMediaTypeEnumId === SocialMediaEnum.GOOGLE
                  ) !== undefined
                ) {
                  this.googleEnabled = true;
                  this.googleInit();
                } else {
                  this.googleEnabled = false;
                }
              }
            })
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  initialiseFbLibrary(FacebookApiURL, FacebookAppId, FacebookAppVersion) {
    (window as any).fbAsyncInit = function () {
      window["FB"].init({
        appId: FacebookAppId,
        cookie: true,
        xfbml: true,
        version: FacebookAppVersion,
      });
      window["FB"].AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = FacebookApiURL;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = FacebookApiURL;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  openFacebookLogin() {
    window["FB"].login(
      (response) => {
        if (response.authResponse) {
          console.log("Facebook Response", response);
          const loginBody = {
            provider: "facebook",
            accessToken: response.authResponse.accessToken,
            email: "",
            iSDCode: "",
            phoneNumber: "",
          };
          this.loginWithFacebook(loginBody);
        } else {
          console.log("User login failed");
          this.formErrors.push("Facebook signing failed. Please try again.");
        }
      },
      { scope: "email" }
    );
  }

  public googleInit() {
    const that = this;
    gapi.load("auth2", (f) => {
      that.auth2 = gapi.auth2.init({
        client_id: that.GoogleClientId,
        cookiepolicy: "single_host_origin",
        scope: that.scope,
        prompt: "select_account consent",
      });
      that.attachSignin(document.getElementById("btnLoginGoogle"));
    });
  }

  public attachSignin(element) {
    const that = this;
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        const loginBody = {
          provider: "google",
          accessToken: googleUser.getAuthResponse().id_token,
          email: "",
          iSDCode: "",
          phoneNumber: "",
        };
        this.loginWithFacebook(loginBody);
      },
      (error) => {
        console.log(JSON.stringify(error, undefined, 2));
      }
    );
  }

  get loginF() {
    return this.loginForm.controls;
  }

  get phoneLoginF() {
    return this.phoneLoginForm.controls;
  }

  loginWithFacebook(fbLoginBody) {
    this.formLoading = true;
    this.subscriptions.push(
      this.authService.loginWithFacebook(fbLoginBody).subscribe(
        (response: DTResult) => {
          if (response.State) {
            if (response.Data.AuthResult.accessToken == null) {
              // const modal = this.modalService.open(SignupComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
              // modal.componentInstance.socialLoginBody = fbLoginBody;
              const signUpData = {
                actionsRequiredModal: {
                  show: false,
                  dismisable: true,
                  EmailRequired: false,
                  EmailVerificationRequired: false,
                  PhoneRequired: false,
                  actionsRequired: response.Data.AuthResult.actionsRequired,
                },
                socialLoginBody: fbLoginBody,
                submitPlaceAdForm: this.submitPlaceAdForm,
              };

              response.Data.AuthResult.actionsRequired.map((action) => {
                if (
                  action.ActionRequiredEnumId === ActionRequiredEnum.VerifyEmail
                ) {
                  signUpData.actionsRequiredModal.show = false;
                  signUpData.actionsRequiredModal.EmailVerificationRequired =
                    true;
                }

                if (
                  action.ActionRequiredEnumId ===
                  ActionRequiredEnum.PhoneRequired
                ) {
                  signUpData.actionsRequiredModal.show = false;
                  signUpData.actionsRequiredModal.PhoneRequired = true;
                }

                if (
                  action.ActionRequiredEnumId ===
                  ActionRequiredEnum.EmailRequired
                ) {
                  signUpData.actionsRequiredModal.show = true;
                  signUpData.actionsRequiredModal.dismisable = false;
                  signUpData.actionsRequiredModal.EmailRequired = true;
                }
              });
              this.activeModal.close(signUpData);
              // modal.componentInstance.actionsRequiredModal = actionsRequiredModal;
            } else {
              localStorage.setItem("LOGGED_IN_USER", JSON.stringify(response));
              this.authService.authObject$.next(response);
              this.socketService.ConnectToServer();
            }
            this.formLoading = false;
            if (this.submitPlaceAdForm) {
              this.activeModal.close("submit-ad");
              return;
            }
            this.activeModal.close("logged in");
          } else {
            this.formErrors = response.Messages;
            console.log(response.Messages);
          }
        },
        (error) => {
          this.formErrors.push(error);
          this.formLoading = false;
        }
      )
    );
  }

  sendOTP() {
    const phoneNumber = this.phoneLoginF.Phone.value.number
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .trim();
    // console.log(this.phoneLoginF.Phone.value.dialCode.replace(" ", "").replace("+", ""))           
    this.authService
      .loginWithPhone(
        phoneNumber,
        this.phoneLoginF.Phone.value.dialCode.replace(" ", "").replace("+", "")
      )
      .subscribe(
        (res) => {
          if (res.State) {
            this.showPhoneLoginOTP = true;
            this.showOtp = false;
          } else {
            this.formErrors = res.Messages;
          }
        },
        (err) => {
          this.formErrors.push(err);
        }
      );
  }

  onOtpChange(event) {
    this.loginOTP.value = event;
    if (this.formErrors.length) {
      this.formErrors = [];
    }
  }
  resendOTP() {
    this.otpInputField.otpForm.controls.ctrl_0.setValue(null)
    this.otpInputField.otpForm.controls.ctrl_1.setValue(null)
    this.otpInputField.otpForm.controls.ctrl_2.setValue(null)
    this.otpInputField.otpForm.controls.ctrl_3.setValue(null)
    this.otpInputField.otpForm.controls.ctrl_4.setValue(null)
    this.otpInputField.otpForm.controls.ctrl_5.setValue(null)

    this.sendOTP();
    // this.formLoading = true;
    this.formErrors = [];
    this.loginOTP.resendDisabled = true;
    const OTPTimer = setInterval(() => {
      if (this.loginOTP.resendOTPTimer > 1) {
        this.loginOTP.resendOTPTimer--;
      } else {
        this.loginOTP.resendDisabled = false;
        clearInterval(OTPTimer);
        this.loginOTP.resendOTPTimer = 30;
      }
    }, 1000);
    // this.phoneLogin(true);
  }


  phoneLogin(resend: boolean) {
    // console.log(this.phoneLoginF.Phone.value, this.loginOTP.value);
    this.formErrors = []
    this.formSubmitted = true;
    if (this.phoneLoginForm.invalid && this.loginOTP.value.length < 6) {
      this.formErrors.push("Please enter verification code");
    } else {
      this.authService
        .loginWithPhoneVerifyOTP(
          this.phoneLoginF.Phone.value.dialCode.replaceAll(" ", "").replaceAll("+", ""),
          this.phoneLoginF.Phone.value.number.replaceAll(" ", "").replaceAll("-", ""),
          this.loginOTP.value
        )
        .subscribe(
          (response) => {
            if (response.State) {
              localStorage.setItem("LOGGED_IN_USER", JSON.stringify(response));
              this.authService.authObject$.next(response);
              this.authService.authActionsRequired$.next(
                response.Data.AuthResult.actionsRequired
              );

              this.modalService.dismissAll()

              // this.socketService.ConnectToServer();

              // this.coreService.setAppLocation().then(value => {
              //   if (this.submitPlaceAdForm) {
              //     this.activeModal.close("submit-ad");
              //     return;
              //   }
              //   if (this.route.snapshot.queryParams.redirectURL !== undefined) {
              //     this.router.navigate([
              //       this.route.snapshot.queryParams.redirectURL,
              //     ]);
              //   }

              //   if (
              //     response.Data.InitialSetupStatusEnumId ===
              //       SetupStatus.PRIVATEYN_SETUP ||
              //     response.Data.InitialSetupStatusEnumId ===
              //       SetupStatus.COMMODITIES_SETUP ||
              //     response.Data.InitialSetupStatusEnumId ===
              //       SetupStatus.PAYMENT_SETUP
              //   ) {
              //     this.activeModal.close({
              //       setupStatus: response.Data.InitialSetupStatusEnumId,
              //     });
              //   } else {
              //     this.activeModal.close("logged in");
              //   }
              // })

            } else {
              this.formErrors = response.Messages;
            }
          },
          (err) => {
            this.formErrors.push(err);
          }
        );
    }
  }

  // OpenLocationModal() {
  //   let modal = this.modalService.open(SetLocationComponent, {
  //     size: "md",
  //     centered: true,
  //     windowClass: "animated-modal",
  //     backdrop: "static",
  //   });

  //   modal.result.then((res) => {}).catch((err) => {});
  // }

  login() {
    this.formSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.formLoading = true;
    this.authService.login(this.loginForm.value).subscribe(
      (response: DTResult) => {
        this.formLoading = false;
        if (response.State) {
          // response.Data.isAdmin = this.authService.isUserAdmin(
          //   response.Data.authUserAppInstRoleList
          // );
          this.socketService.ConnectToServer();
          // if (
          //   !this.coreService.subscriptionActive.getValue() &&
          //   response.Data.OwnerYN === false
          // ) {
          //   this.formErrors.push("Access Denied");
          //   return;
          // }
          localStorage.setItem("LOGGED_IN_USER", JSON.stringify(response));
          this.authService.authObject$.next(response);
          this.authService.authActionsRequired$.next(
            response.Data.AuthResult.actionsRequired
          );

          this.modalService.dismissAll()


          // this.coreService.setAppLocation()
          //   .then(value => {
          //     if (this.submitPlaceAdForm) {
          //       this.activeModal.close("submit-ad");
          //       return;
          //     }
          //     if (
          //       this.route.snapshot.queryParams.redirectURL !== undefined
          //     ) {
          //       this.router.navigate([
          //         this.route.snapshot.queryParams.redirectURL,
          //       ]);
          //     }
          //     if (
          //       response.Data.InitialSetupStatusEnumId ===
          //         SetupStatus.PRIVATEYN_SETUP ||
          //       response.Data.InitialSetupStatusEnumId ===
          //         SetupStatus.COMMODITIES_SETUP ||
          //       response.Data.InitialSetupStatusEnumId ===
          //         SetupStatus.PAYMENT_SETUP
          //     ) {
          //       this.activeModal.close({
          //         setupStatus: response.Data.InitialSetupStatusEnumId,
          //       });
          //     } else {
          //       this.activeModal.close("logged in");
          //     }
          //   })
          //   .catch(err => {
          //     this.formLoading = false;
          //     this.formErrors[0] = err;
          //   })
          // this.adsService.getUserPreferredlocation().subscribe(
          //   (res) => {
          //     if (res.State) {
          //       // console.log(res.Data);
          //       if (
          //         res.Data === null &&
          //         JSON.parse(localStorage.getItem("LOGGED_IN_USER")).Data
          //           .AdminYN === false &&
          //         JSON.parse(localStorage.getItem("LOGGED_IN_USER")).Data
          //           .OwnerYN === false
          //       ) {
          //         this.OpenLocationModal();
          //       } else if (res.Data !== null) {
          //         this.coreService.setLocation(res.Data, true);
          //         this.activeModal.close("logged in");
          //       } else {
          //         this.coreService.activeLocations$.subscribe((val) => {
          //           if (val !== null) {
          //             let location = val.filter((loc) => loc.DefaultYN == true);
          //             this.coreService.setLocation(location[0], false);
          //           }
          //         });
          //         if (
          //           JSON.parse(localStorage.getItem("LOGGED_IN_USER")).Data
          //             .AdminYN === false &&
          //           JSON.parse(localStorage.getItem("LOGGED_IN_USER")).Data
          //             .OwnerYN === false
          //         ) {
          //           this.adsService
          //             .getUserPreferredlocation()
          //             .subscribe((res) => {
          //               if (res.State) {
          //                 if (res.Data === null) {
          //                   this.OpenLocationModal();
          //                 } else {
          //                   this.coreService.setLocation(res.Data, true);
          //                 }
          //               }
          //             });
          //         } else {
          //           this.coreService.adminLocations$.subscribe((val) => {
          //             // let location = val.filter(loc => loc.DefaultYN == true);
          //             if (val !== null && val.length > 0)
          //               this.coreService.setLocation(val[0], true);
          //           });
          //         }

          //         if (this.submitPlaceAdForm) {
          //           this.activeModal.close("submit-ad");
          //           return;
          //         }
          //         if (
          //           this.route.snapshot.queryParams.redirectURL !== undefined
          //         ) {
          //           this.router.navigate([
          //             this.route.snapshot.queryParams.redirectURL,
          //           ]);
          //         }
          //         if (
          //           response.Data.InitialSetupStatusEnumId ===
          //             SetupStatus.PRIVATEYN_SETUP ||
          //           response.Data.InitialSetupStatusEnumId ===
          //             SetupStatus.COMMODITIES_SETUP ||
          //           response.Data.InitialSetupStatusEnumId ===
          //             SetupStatus.PAYMENT_SETUP
          //         ) {
          //           this.activeModal.close({
          //             setupStatus: response.Data.InitialSetupStatusEnumId,
          //           });
          //         } else {
          //           this.activeModal.close("logged in");
          //         }
          //       }
          //       this.formErrors = response.Messages;
          //     }
          //   },
          //   (error) => {
          //     this.formLoading = false;
          //     this.formErrors[0] = error;
          //   }
          // );
        } else {
          this.formLoading = false;
          this.formErrors[0] = response.Messages[0];
        }
      },
      (error) => {
        this.formLoading = false;
        this.formErrors[0] = error;
      }
    );
  }

  closeError(error) {
    this.formErrors = this.formErrors.filter(
      (formError) => formError !== error
    );
  }

  // openSignup() {
  //   this.activeModal.close("open-signup");
  // }

  openForgotPassword() {
    this.activeModal.close("open-forgotpassword");
  }

  openSignUp() {
    this.activeModal.close();
    if (JSON.parse(localStorage.getItem("LOGGED_IN_USER"))) {
      this.toast.show("You are already logged-in", {
        classname: "bg-success text-light",
        delay: 2000,
        autohide: true,
        headertext: "Alert!!",
      });
    } else {
      const modal = this.modalService.open(SignupComponent, {
        size: "md",
        centered: true,
        windowClass: "animated-modal",
      });
      modal.result
        .then((res) => {
          console.log(res)
          if (res.emailFieldValue !== null) {
            this.openLogin(res.emailFieldValue);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  openLogin(existingEmail: string) {
    console.log(existingEmail)
    const modal = this.modalService.open(LoginComponent, {
      size: "md",
      centered: true,
      windowClass: "animated-modal",
    });
    if (existingEmail !== null) {
      modal.componentInstance.loginF.Email.setValue(existingEmail);
      // modal.componentInstance.redirectPath.setValue(this.redirectTo);

    }
    modal.result
      .then((res) => {

        // if (res == "logged in") {
        //   // console.log(this.redirectTo);
        //   // this.redirectTo = null;
        //   this.router.navigate([this.coreService.redirectPath]);
        // }

        if (res === "open-signup") {
          this.openSignUp();
        }
        if (res === "open-forgotpassword") {
          this.openForgotPassword();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
