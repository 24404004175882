import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// Why this class? https://www.intertech.com/Blog/angular-best-practice-unsubscribing-rxjs-observables/
export class BaseComponent implements OnDestroy {

  unsubscribe = new Subject<void>();

  constructor() {

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
