<div class="mygig-main-container">
  <div class="footer d-flex flex-column flex-xl-row align-items-center align-items-xl-start justify-content-xl-around">
    <div class="col-12 col-lg-2 d-flex justify-content-center">
      <div class="logo mb-5">
        <a (click)="navigateToHome()">
          <img src="../../assets/imgs/whitelogo.png">
        </a>
      </div>
    </div>
    <div class="col-11 col-xl-6 row d-flex mb-3 flex-wrap justify-content-between">

      <div class="col-6 col-md-2 p-md-0 footer-link-list pl-4 pl-lg-0 order-4 order-lg-2">
        <h5 class="mb-4"></h5>
        <ul>
          <li>
            <a (click)="openSignUp()">Registration</a>
          </li>
          <li>
            <a (click)="navigateToPlaceAd()">Start selling</a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-2 p-md-0 footer-link-list pl-4 pl-lg-0 order-1 order-lg-3">
        <h5>ABOUT US</h5>
        <ul>
          <li (click)="openOurStory(storyModal)">Our story</li>
          <li (click)="openGuarantees(guaranteesModal)">Guarantees</li>
        </ul>
      </div>
      <div class="col-6 col-md-2 p-md-0 footer-link-list pl-4 pl-lg-0 order-3 order-lg-4">
        <h5>LEGAL</h5>
        <ul>
          <li (click)="openTermsandConditions()">Terms of service</li>
          <li (click)="openTermsandConditions()">Privacy / cookies</li>
        </ul>
      </div>
      <div class="col-6 col-md-2 p-md-0 footer-link-list pl-4 pl-lg-0 order-5 order-lg-5">
        <h5 (click)="goToContactUs()">CONTACT US</h5>
        <ul>
          <li *ngIf="contactDetails.contactPhone !== null">{{contactDetails.contactPhone}}</li>
          <li *ngIf="contactDetails.contactEmail !== null">team@fraileysindustrial.io</li>
        </ul>
      </div>

    </div>
    <div class="col-12 col-lg-3  d-flex flex-column justify-content-center justify-content-sm-between mt-5 mt-lg-0 follow-container ">
      <h4 class="text-center  font-weight-bold mb-3">Follow Us</h4>
      <div class="social-icons d-flex flex-row justify-content-center ">
        <div class="icon" *ngIf="contactDetails.facebookUrl !== null">
          <a rel="noreferrer" [href]="" target="_blank">
            <img class="fb-icon" src="../../assets/icons/facebook.svg">
          </a>
        </div>
        <div class="icon" *ngIf="contactDetails.instaUrl !== null">
          <a rel="noreferrer" [href]="" target="_blank">
            <img class="insta-icon" src="../../assets/icons/instagram.svg">
          </a>
        </div>
        <div class="icon" *ngIf="contactDetails.youtubeUrl !== null">
          <a rel="noreferrer" [href]="" target="_blank">
            <img class="youtube-icon" src="../../assets/icons/youtube.svg">
          </a>
        </div>


      </div>
      <div class="copy mt-5">
        <p>Copyright &#169; <span *ngIf="contactDetails.copyrightYear !== null">{{contactDetails.copyrightYear}}</span>
          Frailey Industrial</p>
      </div>
    </div>
  </div>
</div>
<!-- Modal for Guarantees-->
<ng-template #guaranteesModal let-modal>
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">Our Guarantee</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- <h1 class="mb-3">Our Guarantee</h1> -->
      <p>This is our handshake— and to people like us, that goes a long way</p>
      <p>What do we guarantee exactly? The best experience we can provide. Your personal information will <span class="font-weight-bold">never</span> be sold or shared with any outside party; that includes your e-mail, phone number, and identity. Our team will always deliver on exceptional customer service, along with a we-work-for-you attitude.</p>
      <p>We promise to be the best platform for getting things done. That’s why you’ll never have a middleman taking a cut of your profits. You work the gig, you keep the cash. You can also delete your account at any time, and have no obligation to continue using our services.</p>
      <p>Fraileys Industrial was built to be unique, and built for hard working men and women like you. We know your struggle, just as we understand the freedom gained from getting your hands dirty.</p>
    </div>
  </div>
</ng-template>

<!-- Modal for Story-->
<ng-template #storyModal let-modal>
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">Our Story</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Tired by the fact our income relied on other companies and their ability to ship our loads, or complete a project no matter how small— we wanted to create a way people could connect to help each other.</p>
      <p>Neighbors that need a hand, but don’t know who to turn to. Small businesses that don’t have time to run into town for something that’s needed on a job site. A family man that needs some extra cash to keep the lights on.</p>
      <p>This is for you. Men and women that can help when it’s needed most. Our website stands ahead of the pack, because we’re focused on community not greed.</p>
      <p class="font-weight-bold">No middleman eating your profits. No long tedious forms to fill out just to place an ad. No restrictions on who can see your ads.</p>
      <p>We’re betting on you and the fact you’re willing to get your hands dirty.</p>
      <p>We founded <span class="font-italic">Fraileys Industrial</span> for the doers out there like us, who get up every day ready to make life better. For those willing to hold a door open for someone, and that want something different than being held down to a full time job.</p>
      <p>You have a problem that needs fixing? <span class="font-italic">Fraileys Industrial</span> is there to help.</p>
      <div class="mt-2 d-flex flex-column">
        <p class="m-0">Sheldon Hamilton</p>
        <p class="m-0">Jeran Frailey</p>
      </div>
    </div>
  </div>
</ng-template>
