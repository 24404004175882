<div class="nav-container fixed-top" [ngClass]="shortNavBar ? 'h-59' : 'h-80'">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="fold-effect col-1 p-0 d-flex flex-row" *ngIf="(showExtendedNavBar | async)">
      <div class="extension"></div>
      <div class="flat"></div>
    </div>
    <div class="container">
      <div class="mobile-nav-items d-flex d-lg-none w-100 px-1 justify-content-between align-items-center">
        <div class="d-flex">
          <button class="navbar-toggler" type="button" (click)="toggleMenu(true)">
            <img src="../../assets/icons/menu.svg">
          </button>
          <div class="nav-item d-lg-none" *ngIf="(authService.authObject$ | async) !== null">
            <a class="notifications" [ngbPopover]="notificationPopover" placement="bottom-left">
              <div class="notification-bell">
                <img src="../../assets/icons/notification-bell.svg">
              </div>
              <span class="notification-count" *ngIf="getTotalUnreadCount() !== 0">{{getTotalUnreadCount()}}</span>
            </a>
            <ng-template #notificationPopover>
              <div class="empty-list" *ngIf="getTotalUnreadCount() == 0">
                You have no notifications
              </div>
              <ul class="notifications-list" *ngIf="getTotalUnreadCount() !== 0">
                <li (click)="openMyAccountWithTab(2, null)" *ngIf="unreadNotifications.TotalUnReadNotifications !== 0">
                  You have
                  <span>{{unreadNotifications.TotalUnReadNotifications}} new</span> notifications</li>
                <li (click)="goToMessageCenter(1)" *ngIf="unreadNotifications.TotalUnReadMyAdsMessages !== 0">You have
                  <span>{{unreadNotifications.TotalUnReadMyAdsMessages}} unread</span> messages under My Ads</li>
                <li (click)="goToMessageCenter(2)" *ngIf="unreadNotifications.TotalUnReadMyInterestsMessages !== 0">You
                  have <span>{{unreadNotifications.TotalUnReadMyInterestsMessages}} unread</span> messages under My
                  Interests</li>
              </ul>
            </ng-template>
          </div>
        </div>
        <div class="logo margin-right" (click)="goToHome()">
          <!-- <img height="29" src="../../assets/imgs/BNS-logo-white.png"> -->
          <img height="29" src="../../assets/imgs/frailey-industrial-logo-white.png">
        </div>
        <!-- <button class="search-toggler" type="button" (click)="toggleSearch(true)">
          <img src="../../assets/icons/search.svg">
        </button> -->
      </div>

      <a class="navbar-brand d-none d-lg-inline-block" *ngIf="(showExtendedNavBar | async)" (click)="goToHome()">
        <img height="54" src="../../assets/imgs/frailey-industrial-logo-white.png">
      </a>

      <div class=" d-none d-lg-block collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav left-nav mr-auto">
          <li class="nav-item" *ngFor="let page of filteredMenuItems;" (click)="openPage(page.link)" [@fadeInOnEnter]="filteredMenuItems.length">
            <a class="nav-link" [ngClass]="{'active': page.link == activePage}"><img class="mr-2" width="32" *ngIf="page.icon !== null" [src]="'../../assets/icons/' + page.icon + '.svg'">{{page.title}}</a>
          </li>
        </ul>
        <ul class="navbar-nav right-nav ml-auto" *ngIf="(authService.authObject$ | async) === null" [@fadeInOnEnter]>
          <li class="nav-item sign-in-btn" (click)="openLogin(null)">
            <a class="nav-link">Sign In</a>
          </li>
          <li class="nav-item login-btn ml-xl-3 ml-md-0" (click)="openSignUp()">
            <a class="nav-link">Join</a>
          </li>
        </ul>
        <ul class="navbar-nav right-nav ml-auto" *ngIf="(authService.authObject$ | async) !== null" [@fadeInOnEnter]>
          <li class="nav-item">
            <div ngbDropdown class="d-flex">
              <a class="user-nickname" ngbDropdownToggle id="userDropdown" role="button">
                <div class="avatar" *ngIf="(authService.authObject$ | async).Data.AuthResult.avatarURI">
                  <img [src]="(authService.authObject$ | async).Data.AuthResult.avatarURI">
                </div>
                <span>{{(authService.authObject$ | async).Data.NickName}}</span>
              </a>
              <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu">
                <a ngbDropdownItem class="sort-option-text" (click)="openMyAccountWithTab(1, null)">My Account</a>
                <a ngbDropdownItem class="sort-option-text" (click)="openSignOutModal(modalConfirmLogout)">Sign Out</a>
                <!-- signOut() -->
              </div>
            </div>
          </li>
          <li class="nav-item">
            <a class="notifications" [ngbPopover]="notificationPopover" placement="bottom">
              <div class="notification-bell">
                <img src="../../assets/icons/notification-bell.svg">
              </div>
              <span class="notification-count" *ngIf="getTotalUnreadCount() !== 0">{{getTotalUnreadCount()}}</span>
            </a>
            <ng-template #notificationPopover>
              <div class="empty-list" *ngIf="getTotalUnreadCount() == 0">
                You have no notifications
              </div>
              <ul class="notifications-list" *ngIf="getTotalUnreadCount() !== 0">
                <li (click)="openMyAccountWithTab(2, null)" *ngIf="unreadNotifications.TotalUnReadNotifications !== 0">
                  You have
                  <span>{{unreadNotifications.TotalUnReadNotifications}} new</span> notifications</li>
                <li (click)="goToMessageCenter(1)" *ngIf="unreadNotifications.TotalUnReadMyAdsMessages !== 0">You have
                  <span>{{unreadNotifications.TotalUnReadMyAdsMessages}} unread</span> messages under My Ads</li>
                <li (click)="goToMessageCenter(2)" *ngIf="unreadNotifications.TotalUnReadMyInterestsMessages !== 0">You
                  have <span>{{unreadNotifications.TotalUnReadMyInterestsMessages}} unread</span> messages under My
                  Interests</li>
              </ul>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<div class="mobile-nav d-lg-none" *ngIf="isMenuOpen" [@slideInLeftOnEnter] [@slideOutLeftOnLeave]>
  <div class="mobile-nav-header d-flex flex-row justify-content-between align-items-center" [ngStyle]="{'background-color':(authService.authObject$ | async) !== null ? '#000000' : ''}">
    <div class="left d-flex flex-row justify-content-start align-items-stretch" *ngIf="(authService.authObject$ | async) === null" [@fadeInOnEnter]>
      <a class="sign-in-btn" (click)="openLogin(null)">Sign In</a>
      <a class="login-btn" (click)="openSignUp()">Join</a>
    </div>
    <div class="left d-flex flex-row justify-content-start align-items-stretch" *ngIf="(authService.authObject$ | async) !== null" [@fadeInOnEnter]>
      <div class="d-flex">
        <a class="user-nickname" (click)="isMobileUserOptionsCollapsed = !isMobileUserOptionsCollapsed" [attr.aria-expanded]="!isMobileUserOptionsCollapsed" aria-controls="userCollapse" id="userDropdown" role="button">
          <div class="avatar">
            <img *ngIf="(authService.authObject$ | async).Data.AuthResult.avatarURI" [src]="(authService.authObject$ | async).Data.AuthResult.avatarURI">
          </div>
          <span>{{(authService.authObject$ | async).Data.NickName}}</span>
          <span class="chevron"><img src="../../assets/icons/downarrow.svg"></span>
        </a>
        <a class="notifications" [ngbPopover]="notificationPopover" placement="bottom">
          <div class="notification-bell">
            <img src="../../assets/icons/notification-bell.svg">
          </div>
          <span class="notification-count" *ngIf="getTotalUnreadCount() !== 0">{{getTotalUnreadCount()}}</span>
        </a>
        <ng-template #notificationPopover>
          <div class="empty-list" *ngIf="getTotalUnreadCount() == 0">
            You have no notifications
          </div>
          <ul class="notifications-list" *ngIf="getTotalUnreadCount() !== 0">
            <li (click)="openMyAccountWithTab(2, null)" *ngIf="unreadNotifications.TotalUnReadNotifications !== 0">You
              have
              <span>{{unreadNotifications.TotalUnReadNotifications}} new</span> notifications</li>
            <li (click)="goToMessageCenter(1)" *ngIf="unreadNotifications.TotalUnReadMyAdsMessages !== 0">You have
              <span>{{unreadNotifications.TotalUnReadMyAdsMessages}} unread</span> messages under My Ads</li>
            <li (click)="goToMessageCenter(2)" *ngIf="unreadNotifications.TotalUnReadMyInterestsMessages !== 0">You
              have
              <span>{{unreadNotifications.TotalUnReadMyInterestsMessages}} unread</span> messages under My Interests
            </li>
          </ul>
        </ng-template>
      </div>
    </div>
    <div class="right">
      <img (click)="toggleMenu(false)" src="../../assets/icons/close.svg">
    </div>
  </div>

  <div class="mobile-nav-body d-flex flex-column justify-content-start align-items-start flex-grow-1">
    <div class="user-collapse" id="userCollapse" [ngbCollapse]="isMobileUserOptionsCollapsed">
      <ul>
        <li (click)="openMyAccountWithTab(1, null)">Account Info</li>
        <li (click)="openMyAccountWithTab(2, null)">System Notifications</li>
        <!-- <li (click)="openMyAccountWithTab(3, 1)">Reviews Inbox</li>
        <li (click)="openMyAccountWithTab(3, 2)">Reviews Outbox</li> -->
        <li (click)="openSignOutModal(modalConfirmLogout)">Sign Out</li>
      </ul>
    </div>
    <ul class="page-routes">
      <li *ngFor="let page of filteredMenuItems" [ngClass]="page.icon === null ? 'extraPad' : ''" (click)="openPage(page.link)" [routerLinkActive]="['active']" (click)="toggleMenu(false)">
        <img class="mr-2" width="19" *ngIf="page.icon !== null" [src]="'../../assets/icons/' + page.icon + '.svg'">{{page.title}}
      </li>
    </ul>
  </div>
  <div class="mobile-nav-footer">
    <div class="row mobile-nav-footer-links">
      <!-- <div class="col-6">
        <h5>About Us</h5>
        <ul>
          <li>Our story</li>
          <li>Guarantees</li>
        </ul>
      </div> -->

      <!-- <div class="col-6">
        <h5 class="mb-4"></h5>
        <ul>
          <li (click)="openPage('new')">Start selling</li>
          <li (click)="openSignUp()">Registration</li>
        </ul>
      </div> -->
      <!--<div class="col-6 my-3">
        <h5>Legal</h5>
        <ul>
          <li (click)="openTerms()">Terms of service</li>
          <li (click)="openTerms()">Privacy / cookies</li>
        </ul>
      </div>-->
      <!-- <div class="col-6 my-3">
        <h5>Contact Us</h5>
        <ul>
          <li *ngIf="contactDetails.contactPhone !== null">{{contactDetails.contactPhone}}</li>
          <li *ngIf="contactDetails.contactEmail !== null">{{contactDetails.contactEmail}}</li>
        </ul>
      </div> -->
    </div>
    <div class="row mobile-nav-footer-social">
      <div class="col-6">
        <!-- <h5>Follow Us</h5>
        <div class="follow-icons d-flex flex-row">
          <div class="icon" *ngIf="contactDetails.facebookUrl !== null">
            <a target="_blank" [href]="contactDetails.facebookUrl">
              <img class="fb-icon" src="../../assets/icons/facebook.svg">
            </a>
          </div>
          <div class="icon" *ngIf="contactDetails.instaUrl !== null">
            <a target="_blank" [href]="contactDetails.instaUrl">
              <img class="fb-icon" src="../../assets/icons/instagram.svg">
            </a>
          </div>
          <div class="icon" *ngIf="contactDetails.youtubeUrl !== null">
            <a target="_blank" [href]="contactDetails.youtubeUrl">
              <img class="fb-icon" src="../../assets/icons/youtube.svg">
            </a>
          </div>
        </div> -->
        <img class="img-fluid img-logo" src="../../assets/imgs/frailey-industrial-logo-white.png">
      </div>
      <!-- <div class="col-6">
        <img class="img-fluid img-logo" src="../../assets/imgs/BNS-logo-white.png">
      </div> -->
    </div>
  </div>
</div>

<div class="d-none d-lg-block" [ngClass]="shortNavBar ? 'mgt-hm' : 'mgt-nhm'">
  <!-- <app-search *ngIf="(showSearchBar$ | async)"></app-search> -->
</div>

<div class="mobile-search" *ngIf="(isMobileSearchOpen$ | async)" [@slideInRightOnEnter] [@slideOutRightOnLeave]>
  <div class="mobile-search-header d-flex flex-row justify-content-between sticky-top">
    <div class="logo offset-4">
      <img class="img-fluid" src="../../assets/imgs/BNS-logo-white.png">
    </div>
    <div class="right">
      <img (click)="toggleSearch(false)" src="../../assets/icons/close.svg">
    </div>
  </div>
  <!-- <app-search></app-search>  -->
</div>

<ng-template #modalConfirmLogout let-modal>
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">Confirm Sign Out</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h6>Are you sure you want to Sign Out ?
      </h6>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="yesClicked()">Yes</button>
      <button type="button" class="btn btn-outline-secondary" (click)="noClicked()">No</button>
    </div>
  </div>
</ng-template>

<!-- <ng-template #modalAccountReminder let-modal>
  <div class="modal-container">
    <div class="modal-header" *ngIf="actionsRequiredModal.EmailVerificationRequired">
      <h4 class="modal-title">Attention Required!!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
        *ngIf="actionsRequiredModal.dismisable">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p *ngIf="actionsRequiredModal.EmailVerificationRequired">Thank you for registering with Fraileys Industrial.</p>
      <p *ngIf="actionsRequiredModal.EmailVerificationRequired">Following account information requires attention.</p>
      <p *ngIf="actionsRequiredModal.EmailRequired">Please enter below information to complete registration.</p>
      <div class="property-container d-flex flex-column">
        <span class="my-1" *ngIf="actionsRequiredModal.EmailRequired">&#8226; Email Address is missing</span>
        <span class="my-1" *ngIf="actionsRequiredModal.PhoneRequired">&#8226; Mobile Number is missing</span>
        <form [formGroup]="socialSignUpForm">
          <div class="form-group" *ngIf="actionsRequiredModal.EmailRequired">
            <label>Email Address</label>
            <input class="form-control" type="text" placeholder="Enter your email address" formControlName="Email"
              [ngClass]="[socialSignUpF.Email.touched && socialSignUpF.Email.errors ? 'is-invalid' : '', socialSignUpF.Email.touched && socialSignUpF.Email.status === 'VALID' ? 'is-valid' : '']">
            <div class="invalid-feedback" *ngIf="socialSignUpF.Email.errors">
              <span *ngIf="socialSignUpF.Email.errors.required">Email address is required</span>
              <span *ngIf="socialSignUpF.Email.errors.pattern">Please enter a valid email address</span>
              <span *ngIf="socialSignUpF.Email.errors.emailExistsBoth">Email address is already in use by another account</span>
            </div>
            <div class="valid-feedback" *ngIf="socialSignUpF.Email.status === 'VALID'">Email is available</div>
            <ngb-progressbar type="success" [value]="100" [striped]="true" [animated]="true" height="10px"
              *ngIf="socialSignUpF.Email.status === 'PENDING'"></ngb-progressbar>
          </div>

          <div class="form-group" *ngIf="actionsRequiredModal.PhoneRequired">
            <label>Mobile Number</label>
            <ngx-intl-tel-input [cssClass]="'form-control w-100'" [separateDialCode]="true"
              [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
              [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [tooltipField]="TooltipLabel.Name"
              [phoneValidation]="true" name="phone" formControlName="Phone"
              [ngClass]="{ 'is-invalid': socialSignUpF.Phone.touched && socialSignUpF.Phone.errors }">
            </ngx-intl-tel-input>
            <div class="invalid-feedback" *ngIf="socialSignUpF.Phone.errors">
              <span *ngIf="socialSignUpF.Phone.errors.required">Mobile number is required</span>
              <span
                *ngIf="socialSignUpF.Phone.errors.validatePhoneNumber?.valid === false || socialSignUpF.Phone.errors.pattern">Please
                enter a valid mobile number</span>
              <span *ngIf="socialSignUpF.Phone.errors.numberInvalid">Please
                enter a valid mobile number</span>
            </div>
          </div>
        </form>
        <span class="my-1" *ngIf="actionsRequiredModal.EmailVerificationRequired">&#8226; Email Address needs to be
          verified</span>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="completeSocialRegistration()"
        [disabled]="socialSignUpForm.invalid" *ngIf="actionsRequiredModal.EmailRequired">Submit</button>
      <button type="submit" class="btn btn-primary" (click)="openMyAccountWithTab(1, null)"
        *ngIf="actionsRequiredModal.EmailVerificationRequired">Update Now</button>
      <button type="button" class="btn btn-outline-secondary" (click)="cancelReminder()"
        *ngIf="actionsRequiredModal.dismisable">Cancel</button>
    </div>
  </div>
  <app-loader [message]="''" [toggleTrigger]="socialFormLoading"></app-loader>
</ng-template> -->
