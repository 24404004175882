import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { CoreService } from '../services/core/core.service';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import {
  slideInLeftOnEnterAnimation,
  slideOutLeftOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
  slideInDownOnEnterAnimation,
  slideOutUpOnLeaveAnimation,
  fadeInOnEnterAnimation
} from 'angular-animations';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../services/auth/auth.service';
import { SignupComponent } from '../signup/signup.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { SocketService } from '../services/socket/socket.service';
import { MessagingService } from '../services/messaging/messaging.service';
import { DTResult } from '../models/dt-result';
import { SocketEnumerations } from '../services/socket/socket-enumerations';
import { UnreadNotifications } from '../models/notification';
import { AuthData } from '../models/authData';
import { AdsService } from '../services/ads/ads.service';
import { ToastService } from '../toast/toast.service';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { TermsComponent } from '../terms/terms.component';
import { AppProperty } from '../models/app-property';
import { SearchCountryField, CountryISO, } from 'ngx-intl-tel-input';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { AdType } from '../enumerations/AdType';
import { TermsComponent } from '../terms/terms.component';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    slideInLeftOnEnterAnimation({ duration: 400 }),
    slideOutLeftOnLeaveAnimation({ duration: 400 }),
    slideInRightOnEnterAnimation({ duration: 400 }),
    slideOutRightOnLeaveAnimation({ duration: 400 }),
    slideInDownOnEnterAnimation({ duration: 400 }),
    slideOutUpOnLeaveAnimation({ duration: 400 }),
    fadeInOnEnterAnimation({ duration: 300 })
  ]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  // @ViewChild('modalAccountReminder') accountReminderModal: NgbModal;
  // accountReminderModalRef: NgbModalRef;
  public isMenuOpen = false;
  public isMobileSearchOpen$: Observable<boolean>;
  public isMobileUserOptionsCollapsed = true;
  showExtendedNavBar: Observable<boolean> = this.coreService.extendedNavBar$.asObservable();
  showSearchBar$: Observable<boolean> = this.coreService.showSearchBar$.asObservable();
  displayRefineSearch$: Observable<boolean> = this.coreService.showRefineSearch$.asObservable();

  pages = [
    {
      title: 'Home',
      link: 'home',
      icon: 'home',
      authRequired: false
    },
    // {
    //   title: 'Browse All Ads',
    //   link: 'browse',
    //   icon: null,
    //   authRequired: true
    // },
    // {
    //   title: 'New Request',
    //   link: 'new',
    //   icon: null,
    //   authRequired: false
    // },
    {
      title: 'Products',
      link: 'products',
      icon: null,
      authRequired: true
    },
    {
      title: 'Orders',
      link: 'requests',
      icon: null,
      authRequired: true
    },
    {
      title: 'Order Status',
      link: 'proposals',
      icon: null,
      authRequired: true
    },
    {
      title: 'Users',
      link: 'users',
      icon: null,
      authRequired: true
    },

  ];

  activePage = window.location.pathname.replace("/", '');
  filteredMenuItems = [];
  unreadNotifications: UnreadNotifications = {
    TotalUnReadMyAdsMessages: 0,
    TotalUnReadMyInterestsMessages: 0,
    TotalUnReadNotifications: 0
  };

  onHomePage: boolean;
  shortNavBar: boolean;

  authUser: AuthData = null;
  contactDetails = {
    contactPhone: null,
    contactEmail: null,
    facebookUrl: null,
    instaUrl: null,
    youtubeUrl: null,
    copyrightYear: null
  };

  actionsRequiredModal = {
    show: false,
    dismisable: true,
    EmailRequired: false,
    EmailVerificationRequired: false,
    PhoneRequired: false
  }

  socialFormLoading = false;

  redirectLoginToMessaging = {
    path: null as string,
    params: null as Params
  };

  constructor(
    private coreService: CoreService,
    private adsService: AdsService,
    public authService: AuthService,
    private socketService: SocketService,
    private messagingService: MessagingService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal
  ) {
    // coreService.getAllCategories();
    // this.socialSignUpForm = this.formBuilder.group({
    //   Email: new FormControl('', {
    //     validators: Validators.compose([
    //       Validators.required,
    //       Validators.pattern(
    //         '^([\\w-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$'
    //       )
    //     ]),
    //     updateOn: 'blur'
    //   }),
    //   Phone: new FormControl(''),
    // })
    // this.socialSignUpF.Email.setAsyncValidators(this.emailExistsValidator());
  }

  ngAfterViewInit() {

  }

  // emailExistsValidator(): AsyncValidatorFn {
  //   return control =>
  //     control.valueChanges.pipe(
  //       distinctUntilChanged(),
  //       debounceTime(200),
  //       switchMap(value => {
  //         return this.authService.checkIfEmailExists(value);
  //       }),
  //       map(emailExistence => {
  //         switch (emailExistence.UserExists) {
  //           case 0: {
  //             return null;
  //           }
  //           case 1: {
  //             return { emailExistsBoth: true };
  //           }
  //           case 2: {
  //             return { emailExistsBoth: true };
  //           }
  //         }
  //       }),
  //       first()
  //     );
  // }

  // get socialSignUpF() {
  //   return this.socialSignUpForm.controls;
  // }


  // @HostListener('window:blur')
  // protected onBlur() {
  //   if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
  //     this.socketService.Disconnect();
  //   }
  // }

  // @HostListener('window:focus')
  // protected onFocus() {
  //   if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
  //     if (localStorage.getItem('LOGGED_IN_USER') !== null) {
  //       this.socketService.ConnectToServer();
  //       // this.messagingService.getUnreadMessageCount();
  //       if (document.URL.includes('message-center')) {
  //         location.reload();
  //       } else {
  //         // this.getUnreadNotificationsCount();
  //       }
  //     }
  //   }
  // }

  ngOnInit(): void {
    // this.coreService.appProperties$.subscribe(value => {
    //   if (value !== null) {
    //     value.forEach((property: AppProperty) => {
    //       switch (property.Property) {
    //         case 'Phone': {
    //           this.contactDetails.contactPhone = property.Value;
    //         }
    //         case 'Email': {
    //           this.contactDetails.contactEmail = property.Value;
    //         }
    //         case 'FacebookPageURL': {
    //           this.contactDetails.facebookUrl = property.Value;
    //         }
    //         case 'InstaPageURL': {
    //           this.contactDetails.instaUrl = property.Value;
    //         }
    //         case 'YoutubePageURL': {
    //           this.contactDetails.youtubeUrl = property.Value;
    //         }
    //         case 'CopyrightYear': {
    //           this.contactDetails.copyrightYear = property.Value;
    //         }
    //       }
    //     });
    //   }
    // });
    // this.coreService.getAppProperties();
    // this.coreService.getEnabledSocialMedia();
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobileSearchOpen$ = this.coreService.showMobileSearch$.asObservable();
    }
    this.messagingService.UnreadNotificationsSubject$.subscribe((value: UnreadNotifications) => {
      if (value !== null) {
        this.unreadNotifications = value;
      }
    });

    if (localStorage.getItem('LOGGED_IN_USER') !== null) {
      const localUser: AuthData = JSON.parse(localStorage.getItem('LOGGED_IN_USER'))
      this.authService.authObject$.next(localUser);
    }

    this.authService.authObject$.subscribe(value => {
      this.authUser = value;
      if (value !== null) {
        this.filteredMenuItems = this.pages;
        if (value.Data.CustomerYN) {
          this.filteredMenuItems = this.filteredMenuItems.filter(page => page.title !== 'Browse All Ads' && page.title !== 'Home');

        } else {

          this.filteredMenuItems = this.filteredMenuItems.filter(page => page.title !== 'Home');
        }
        // this.getUnreadNotificationsCount();

      } else {
        this.filteredMenuItems = this.pages.filter(page => !page.authRequired);
      }
    });

    this.router.events.subscribe((navigation: NavigationEnd) => {
      if (navigation instanceof NavigationEnd) {
        this.onHomePage =
          navigation.urlAfterRedirects.includes('home') ||
          navigation.urlAfterRedirects.includes('new') ||
          navigation.urlAfterRedirects.includes('edit') ||
          navigation.urlAfterRedirects.includes('contact-us') ||
          navigation.urlAfterRedirects.includes('my-account') ||
          navigation.urlAfterRedirects.includes('sitemap') ||
          navigation.urlAfterRedirects.includes('category') ||
          navigation.urlAfterRedirects.includes('manage-ads') ||
          navigation.urlAfterRedirects.includes('message-center');

        if (navigation.urlAfterRedirects.includes('home')) {
          this.displayNavBar(false);
          this.shortNavBar = true;
        } else {
          this.shortNavBar = false;
          this.displayNavBar(true);
        }

        if (this.onHomePage) {
          this.displaySearchBar(false);
        } else {
          this.displayNavBar(true);
          this.displaySearchBar(true);
        }

        if (navigation.url.includes('terms') || navigation.url.includes('privacy')) {
          this.openTerms();
        }

        if (navigation.url.includes('sign-up')) {
          this.router.navigate(['home']);
          this.openSignUp();
        }



        if (navigation.url.includes('login')) {
          if (this.route.snapshot.queryParams.redirectUrlPath !== undefined) {
            this.redirectLoginToMessaging = {
              path: this.route.snapshot.queryParams.redirectUrlPath,
              params: JSON.parse(this.route.snapshot.queryParams.redirectUrlParams)
            }
          }
          this.router.navigate(['home']);
          this.openLogin(null);
        }

        if (navigation.url.includes('stayonbrowse')) {
          if (this.route.snapshot.queryParams.redirectUrlPath !== undefined) {
            this.redirectLoginToMessaging = {
              path: this.route.snapshot.queryParams.redirectUrlPath,
              params: JSON.parse(this.route.snapshot.queryParams.redirectUrlParams)
            }
          }
          this.router.navigate(['browse']);
          this.openLogin(null);
        }

        try {
          const base64String = atob(navigation.url.split('/')[2]);
          if (/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.test(navigation.url.split('/')[2])) {
            if (Number(base64String)) {
              this.router.navigate(['details'], { queryParams: { id: base64String, e: true } });
            }
          }
        } catch (err) { }
      }
    });

    // this.socketService.onDataReceived$.subscribe(response => {
    //   switch (response.TransactionCode) {
    //     case SocketEnumerations.Notification: {
    //       this.animateAllNotificationBells();
    //       this.unreadNotifications.TotalUnReadNotifications++;
    //       break;
    //     }
    //     case SocketEnumerations.NewMessage: {
    //       if (response.Data.ToAuthUserId === this.authUser.Data.AuthResult.authUserId) {
    //         const selectedChat = this.messagingService.ChatSubscriptionSubject$.getValue();
    //         if (selectedChat !== null && selectedChat.ListingSubscriptionId === response.Data.SubscriptionId) {
    //           return;
    //         } else {
    //           this.animateAllNotificationBells();
    //           if (response.Data.PublisherAuthUserId === this.authUser.Data.AuthResult.authUserId) {
    //             this.messagingService.UnreadNotificationsSubject$.next(
    //               Object.assign(this.messagingService.UnreadNotificationsSubject$.value, {
    //                 TotalUnReadMyAdsMessages: this.messagingService.UnreadNotificationsSubject$.value.TotalUnReadMyAdsMessages + 1
    //               })
    //             );
    //           } else {
    //             this.messagingService.UnreadNotificationsSubject$.next(
    //               Object.assign(this.messagingService.UnreadNotificationsSubject$.value, {
    //                 TotalUnReadMyInterestsMessages:
    //                   this.messagingService.UnreadNotificationsSubject$.value.TotalUnReadMyInterestsMessages + 1
    //               })
    //             );
    //           }
    //         }
    //       }
    //       break;
    //     }
    //   }
    // });
  }

  // cancelReminder() {
  //   this.accountReminderModalRef.close();
  //   this.accountReminderModalRef = null;
  // }

  animateAllNotificationBells() {
    const notificationBell1 = document.getElementsByClassName('notification-bell').item(0);
    const notificationBell2 = document.getElementsByClassName('notification-bell').item(1);
    // const notificationBell3 = document.getElementsByClassName('notification-bell').item(2);

    notificationBell1.classList.add('shake');
    notificationBell2.classList.add('shake');
    // notificationBell3.classList.add('shake');
    notificationBell1.addEventListener('animationend', () => {
      notificationBell1.classList.remove('shake');
    });
    notificationBell2.addEventListener('animationend', () => {
      notificationBell2.classList.remove('shake');
    });
    // notificationBell3.addEventListener('animationend', () => {
    //   notificationBell3.classList.remove('shake');
    // });
    // this.playNotificationAudio();
  }

  playNotificationAudio() {
    let audio = new Audio();
    audio.src = '../../assets/notification.mp3';
    audio.load();
    audio.play();
  }

  openPage(link: string) {
    this.activePage = link;
    this.router.navigate([link]);
    let triggerSearch = false;
    this.adsService.eventEmitter.next()
    if (link === 'browse') {
      triggerSearch = true;
      this.adsService.searchSubject$.next({
        TriggerSearch: triggerSearch,
        SearchText: '',
        CategoryIds: [],
        SubCategoryIds: [],
        Locations: [],
        State: '',
        MinPrice: null,
        MaxPrice: null,
        ListingTypeEnumIds: [AdType.REQUEST, AdType.STANDARD],
        PostDateHours: null,
        Page: 1,
        PageSize: 12,
        SortCriteria: 'Recent DESC'
      });

      this.adsService.selectedSuggestedCitiesSubject$.next([]);
    } else {
      triggerSearch = false;
    }
    this.toggleMenu(false);
  }

  openLogin(existingEmail: string) {
    let modal = this.modalService.open(LoginComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
    if (existingEmail !== null) {
      modal.componentInstance.loginF.Email.setValue(existingEmail);
    }
    modal.result
      .then(res => {
        console.log(res);
        if (res === 'open-signup') {
          // this.activeModal.dismiss(); 
          this.openSignUp();
          return;
        }
        if (res === 'open-forgotpassword') {
          this.openForgotPassword();
          return;
        }

        if (res.actionsRequiredModal !== undefined) {
          this.openSignUpForSocialLogin(res);
          return;
        }

        if (this.redirectLoginToMessaging.path !== null) {
          this.router.navigate([this.redirectLoginToMessaging.path], { queryParams: this.redirectLoginToMessaging.params });
          this.redirectLoginToMessaging = {
            path: null,
            params: null
          }
        }

      })
      .catch(err => {
        console.log(err);
      });
  }

  openSignUpForSocialLogin(data) {
    const modal = this.modalService.open(SignupComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
    modal.componentInstance.actionsRequiredModal = data.actionsRequiredModal;
    modal.componentInstance.socialLoginBody = data.socialLoginBody;
    modal.componentInstance.submitPlaceAdForm = data.submitPlaceAdForm;
    modal.result.then(res => {
      if (res.emailFieldValue !== undefined) {
        this.openLogin(res.emailFieldValue);
      }
    })
      .catch(err => {
        console.log(err);
      })
  }

  openForgotPassword() {
    const modal = this.modalService.open(ForgotPasswordComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
    modal.result
      .then(res => {
        if (res === 'open-login') {
          this.openLogin(null);
          return;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  openSignUp() {
    // this.activeModal.close('open-signup');
    if (this.authUser === null) {
      const modal = this.modalService.open(SignupComponent, { size: 'md', centered: true, windowClass: 'animated-modal' });
      modal.result
        .then(res => {
          if (res.emailFieldValue !== undefined) {
            this.openLogin(res.emailFieldValue);
            return;
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.toastService.show('You are already logged in', {
        classname: 'bg-success text-light',
        delay: 2000,
        autohide: true,
        headertext: 'Alert'
      });
    }
  }

  openPlaceAnAd() {
    this.router.navigate(['new']);
  }

  openSignOutModal(modalName: any) {
    const modal = this.modalService.open(modalName, {
      size: 'md',
      centered: true,
      windowClass: 'animated-modal'
    });
    modal.result
      .then(res => {
        if (res === 'yes') {
          this.yesClicked();
          return;
        }
        if (res === 'no') {
          this.noClicked();
          return;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  yesClicked() {
    this.isMobileUserOptionsCollapsed = true;
    this.adsService.searchSubject$.next(
      Object.assign(this.adsService.searchSubject$.value, {
        TriggerSearch: true
      })
    );
    this.authService.logout();
    this.messagingService.UnreadNotificationsSubject$.next({
      TotalUnReadMyAdsMessages: 0,
      TotalUnReadMyInterestsMessages: 0,
      TotalUnReadNotifications: 0
    });
    this.socketService.Disconnect();
    this.modalService.dismissAll();
    this.actionsRequiredModal = {
      show: false,
      dismisable: true,
      EmailRequired: false,
      EmailVerificationRequired: false,
      PhoneRequired: false
    }
  }

  noClicked() {
    this.modalService.dismissAll();
  }

  getUnreadNotificationsCount() {
    this.messagingService.getUnreadMessageCount().subscribe((response: DTResult) => {
      if (response.State) {
        this.messagingService.UnreadNotificationsSubject$.next({
          TotalUnReadMyAdsMessages: response.Data.TotalUnReadPublishedMessages,
          TotalUnReadMyInterestsMessages: response.Data.TotalUnReadSubscribedMessages,
          TotalUnReadNotifications: response.Data.TotalUnReadNotifications
        });
      }
    });
  }

  getTotalUnreadCount() {
    return (
      this.unreadNotifications.TotalUnReadNotifications +
      this.unreadNotifications.TotalUnReadMyInterestsMessages +
      this.unreadNotifications.TotalUnReadMyAdsMessages
    );
  }

  goToMessageCenter(tab) {
    this.router.navigate(['message-center'], { queryParams: { tab: tab } });
    this.toggleMenu(false);
  }

  openMyAccountWithTab(tab, reviewDDOption) {

    // if (this.accountReminderModalRef) {
    //   this.accountReminderModalRef.close();
    // }
    if (reviewDDOption === null) {
      this.router.navigate(['my-account'], { queryParams: { tab: tab } });
    } else {
      this.router.navigate(['my-account'], { queryParams: { tab: tab, reviewTab: reviewDDOption } });
    }

    this.toggleMenu(false);
    this.adsService.searchSubject$.next({
      TriggerSearch: false,
      SearchText: '',
      CategoryIds: [],
      SubCategoryIds: [],
      Locations: [],
      State: '',
      MinPrice: null,
      MaxPrice: null,
      ListingTypeEnumIds: [AdType.REQUEST, AdType.STANDARD],
      PostDateHours: null,
      Page: 1,
      PageSize: 12,
      SortCriteria: 'Recent DESC'
    });
    this.adsService.selectedSuggestedCitiesSubject$.next([]);
  }

  toggleMenu(bool) {
    this.isMenuOpen = bool;
    var body = document.body;
    if (bool) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }

  toggleSearch(bool) {
    this.coreService.showMobileSearch$.next(bool);
    var body = document.body;
    if (bool) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }

  displayNavBar(bool) {
    this.coreService.extendedNavBar$.next(bool);
  }

  displaySearchBar(bool) {
    this.coreService.showSearchBar$.next(bool);
  }

  goToHome() {
    this.router.navigate(['home']);
    this.adsService.searchSubject$.next({
      TriggerSearch: false,
      SearchText: '',
      CategoryIds: [],
      SubCategoryIds: [],
      Locations: [],
      State: '',
      MinPrice: null,
      MaxPrice: null,
      ListingTypeEnumIds: [AdType.STANDARD, AdType.REQUEST],
      PostDateHours: null,
      Page: 1,
      PageSize: 12,
      SortCriteria: 'Recent DESC'
    });
  }

  openTerms() {
    const modal = this.modalService.open(TermsComponent, { size: 'xl', centered: true, windowClass: 'animated-modal', scrollable: false });
    modal.result.then(res => { }).catch(err => { });
  }
}
