import { DTResult } from './dt-result';

export class AuthData extends DTResult {
    Data: {
        AuthResult: {
            accessToken: string;
            authUserId: number;
            avatarURI: string;
            actionsRequired: ActionRequired[];
            daysCount: number;
            email: string;
            firstName: string;
            lastName: string;
            orgName: string;
            redirectURI: string;
            subscriptionStatus: number;
            tierName: string;
            instanceCount: number
            iSDCode: string;
            phoneNumber: number;
            emailConfirmedYN: boolean;
            instance: {
                IsAdminYN: boolean;
                URI: string;
                externalId: number;
                id: number;
                name: string;
            }
        }
        NickName: string;
        CustomerYN: boolean;
    }
}

export class SocialLoginBody {
    provider: string; 
    accessToken: string;
    email: string;
    iSDCode: string;
    phoneNumber: string;
}

export interface ActionRequired {
    ActionRequiredEnumId: number
    Message: string;
    SeverityEnumId: number
}

export enum ActionRequiredEnum {
    EmailRequired = 2065001,
    VerifyEmail = 2065002,
    PhoneRequired = 2065003,
    VerifyPhone = 2065004,
    PasswordExpiring = 2065005,
    PasswordExpired = 2065006,
    CreditCardExpiring = 2065007,
    CreditCardExpired = 2065008
}

export enum SeverityEnums {
    ERROR = 2064001,
    WARNING = 2064002
}