import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthguardService } from "./services/guards/authguard.service";


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    loadChildren: () => import("./home-new/home-new.module").then((m) => m.HomeNewModule),
  },
  {
    path: "products",
    loadChildren: () =>
      import("./products/products.module").then((m) => m.ProductsModule),
    canActivate: [AuthguardService],
  },
  {
    path: "requests",
    loadChildren: () =>
      import("./manage-ads/manage-ads.module").then((m) => m.ManageAdsModule),
    canActivate: [AuthguardService],
  },
  {
    path: "details",
    loadChildren: () =>
      import("./ad-details/ad-details.module").then((m) => m.AdDetailsModule),
    canActivate: [AuthguardService],
  },
  {
    path: "proposals",
    loadChildren: () =>
      import("./manage-proposals/manage-proposals.module").then((m) => m.ManageProposalsModule),
    canActivate: [AuthguardService],
  },
  {
    path: "users",
    loadChildren: () =>
      import("./users/add-users.module").then((m) => m.AddUsersModule),
    canActivate: [AuthguardService],
  },
  {
    path: "my-account",
    loadChildren: () =>
      import("./my-account/my-account.module").then((m) => m.MyAccountModule),
    canActivate: [AuthguardService],
  },

  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
